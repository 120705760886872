import React, { useState } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';

const Section = (props) => {
  const { iconLeft, iconRight, active = true, title, initialOpen } = props;
  const [open, setOpen] = useState(!!initialOpen);
  return <SectionS
    className={`${open ? 'open' : 'closed'} ${active ? 'active' : 'inactive'}`} >
    <div className="sheader" onClick={() => { active && setOpen(!open) }}><div>{iconLeft && <Icon name={iconLeft} />}<h3>{title}</h3></div>{iconRight ? <Icon name={iconRight} /> : open ? <Icon name="chevron up" /> : <Icon name="chevron down" />}{!active && <Icon name="lock" />}</div>
    {open && <div className={`${open ? 'open' : 'closed'} scontent`}>
      {props.children}
    </div>}
  </SectionS>
}
export default Section;

const SectionS = styled.li`
  display: flex;
  flex-direction: column;
  &.open{
    border-color:transparent !important;
  }
  .scontent{
    &.closed{
      max-height:0;
      overflow: hidden;
      transition:  max-height 0.5s 0.2s linear, opacity 0.5s linear;
      opacity:0;
      padding: 0;
    }
    &.open{
      max-height:auto;
      transition: max-height 0.5s linear, opacity 0.5s 0.5s linear;
      opacity:100;
      padding:1rem 0 0 0;
    }
  }
  .sheader{
      display:flex;
      justify-content:space-between;
      margin:-1rem 0 -1rem 0;
      padding:1rem 0;
      &.open{
        margin:-1rem 0 1rem 0;
      }
      h3{
        font-size:1.1rem;
      }
    }
  &.open{
    background:rgba(0,0,0,0.08);
    
  video{
    max-width:100%;
    min-height:56%;
  }
  .files{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
    > *{
      font-size:1.1rem;
    }
    a{

      text-decoration:none!important;
    }
    > div{
      display: flex;
      flex-direction: row;
      i{margin-right:1rem;}
    }
  }
}
`;