import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { AppUtils } from "../../utils";
import { get } from "lodash";
import { Icon } from "semantic-ui-react";
import Ingredient from "./ingredient";

const Comp = (props) => {
    const { id } = props;
    const [expanded, setExpanded] = useState(false);
    const [receipe, setReceipe] = useState();

    useEffect(() => {
        if (id !== "") {
            AppUtils.getS3Data(`data/kk_recipe_definition/${id}.json`)
                .then((result) => {
                    setReceipe(result);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [id]);

    const title = useMemo(() => get(receipe, 'i18n.de.definition.title', ''), [receipe]);

    return <CompS className={expanded ? 'expanded' : ''}>
        <h3 onClick={() => { setExpanded(!expanded) }}>{title}<Icon name={expanded ? 'chevron up' : 'chevron down'} /></h3>
        {expanded && <div className="details">
            <div dangerouslySetInnerHTML={{ __html: get(receipe, 'i18n.de.definition.instructions', '') }} />
            <h4>Zutaten:</h4>
            <div className="ingredients">
                {get(receipe, 'ingredientslist', []).map(ingredient => <Ingredient amount={get(ingredient, 'amount')} unit={get(ingredient, 'unit')} id={get(ingredient, 'food_id')} />)}
            </div>
        </div>}
    </CompS>
};
export default Comp;
const CompS = styled.div`
    margin:0.5rem -1rem !important;
    padding:0.5rem 1rem !important;
    border-radius:0.5rem;
    background:rgba(255,255,255,0.4);
    &.expanded{
        background:rgba(255,255,255,0.9);
    }
    h3{
        margin:0;
        font-size:1.1rem;
        color:rgba(38, 38, 38, 0.8);
        display:flex;
        justify-content:space-between;
        cursor:pointer;
    }
    .details{
        padding:1rem 0;
    }
    h4{
        font-size:1.0rem;
        color:rgba(38, 38, 38, 0.8);
        margin:0.5rem 0;
    }
    .ingredients{
        > *{
            margin:0.25rem 0;
        }
    }
`;