import React, { useRef, useEffect, useState, useCallback } from "react";
import { Stream } from "@cloudflare/stream-react";
import styled from "styled-components";
import useLocalStorage from "./useLocalStorage";
import { Icon } from "semantic-ui-react";

export const CustomStreamPlayer = ({ src, poster, cb = () => {} }) => {
  const [isFullscreen, setIsFullscreen] = useState(false);
  const videoRef = useRef(null);
  const wref = useRef(null);
  const [initalTime, setInitialTime] = useState(0);
  const [started, setStarted] = useState(false);
  const [currentTime, setCurrentTime] = useLocalStorage(
    "kk_videopos_" + src,
    0
  );
  const [intervalCurrentTime, setIntervalCurrentTime] = useState();
  useEffect(() => {
    setInitialTime(currentTime);
  }, []);

  const handlePlay = () => {
    setStarted(true);
    clearInterval(intervalCurrentTime);
    videoRef.current.play();
    setIntervalCurrentTime(
      setInterval(() => {
        setCurrentTime(videoRef.current.currentTime);
      }, 5000)
    );
  };

  const handlePause = () => {
    setStarted(false);
    clearInterval(intervalCurrentTime);
    videoRef.current.pause();
  };

  const handleSeekBack = () => {
    videoRef.current.currentTime -= 10; // Seek back 10 seconds
    setCurrentTime(videoRef.current.currentTime);
  };
  const handleGotoStart = () => {
    videoRef.current.currentTime = 0;
  };
  const handleEnded = useCallback(() => {
    clearInterval(intervalCurrentTime);
    setStarted(false);
    cb("end");
  }, [cb, intervalCurrentTime]);

  const handleFullScreen = () => {
    if (wref.current.requestFullscreen) {
      wref.current.requestFullscreen();
    } else if (wref.current.webkitRequestFullscreen) {
      wref.current.webkitRequestFullscreen();
    } else if (wref.current.msRequestFullscreen) {
      wref.current.msRequestFullscreen();
    }
    setIsFullscreen(true);
  };
  const handleCloseFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      // WebKit-Browser
      document.webkitExitFullscreen();
    } else if (document.msExitFullscreen) {
      // IE11
      document.msExitFullscreen();
    }
    setIsFullscreen(false); // Aktualisieren Sie Ihren Zustand entsprechend
  };

  document.addEventListener("fullscreenchange", () => {
    if (!document.fullscreenElement) {
      setIsFullscreen(false); // Zurücksetzen, wenn der Vollbildmodus verlassen wird
    }
  });
  if (videoRef.current) {
    videoRef.current.startTime = Math.round(currentTime);
  }
  return (
    <Comp ref={wref}>
      <Stream
        src={src}
        poster={poster}
        currentTime={initalTime}
        onEnded={handleEnded}
        controls={true} // Disable default controls
        streamRef={videoRef} // Pass the ref to the CloudflareStream component
      />
      <div
        className="controls"
        style={{ position: isFullscreen ? "fixed" : "relative" }}
      >
        {started && (
          <button onClick={handleGotoStart}>
            <Icon name="fast backward" />
          </button>
        )}
        {started && (
          <button onClick={handleSeekBack}>
            <Icon name="undo" />
          </button>
        )}
        {!started && (
          <button onClick={handlePlay}>
            <Icon name="play" />
          </button>
        )}
        {started && (
          <button onClick={handlePause}>
            <Icon name="pause" />
          </button>
        )}

        <button
          onClick={isFullscreen ? handleCloseFullScreen : handleFullScreen}
        >
          <Icon name="expand" />
        </button>
      </div>
    </Comp>
  );
};
const Comp = styled.div`
  position: relative;
  iframe {
    border-radius: 20px;
  }
  .controls {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    gap: 1rem;
    padding: 0.5rem 0;
    bottom: 0;
    z-index: 9999;
    button {
      cursor: pointer;
      background: none;
      border: none;
      color: white;
      box-shadow: none;
      border-radius: 1em;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0.25rem 0.5rem !important;
      i {
        margin: 0 !important;
        font-size: 1.3rem;
      }
    }
  }
`;
