import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { useRecoilState } from 'recoil';
import { foodsState, foodsAdditionalState } from '../../atoms';
import { AppUtils } from '../../utils';
import { COLORS } from '../../constants';
import { Icon, Input, Button } from 'semantic-ui-react';
import { produce } from 'immer';
import { get, sortBy } from 'lodash';

const FoodSearch = (props) => {
  const { setFound, resetSearch } = props;
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [foodlist, setFoodlist] = useRecoilState(foodsState);
  const [foodadditionals, setFoodadditionals] = useRecoilState(foodsAdditionalState);
  const [foods, setFoods] = useState();
  const [foodsdb, setFoodsdb] = useState();
  const [timeoutSearch, setTimeoutSearch] = useState(null);

  useEffect(() => {
    setFoods(null);
    setFoodsdb(null);
    setFilter('');
  }, [resetSearch]);

  useEffect(() => {
    setLoading(true);
    if (!foodadditionals) {
      AppUtils.getS3Data('data/kk_foods_data_additional/index.json')
        .then(result => {
          setFoodadditionals(produce(foodadditionals, (draft) => {
            draft = {};
            result.forEach(item => {
              const id = get(item, '_id');
              item.id = id
              delete item._rev;
              delete item._id;
              draft[id] = item;
            })
            return draft;
          }))
          setLoading(false);
        }).catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [foodadditionals, setFoodadditionals]);

  useEffect(() => {
    setLoading(true);
    if (!foodlist) {
      AppUtils.getS3Data('data/kk_food/index.json')
        .then(result => {
          setFoodlist(produce(foodlist, (draft) => sortBy(result.map(item => ({
            tags: (get(item, 'tags', []) || []).join(','),
            id: get(item, '_id'),
            title: get(item, 'i18n.de.definition.title', ''),
          })), (item) => (item.title || '').toLowerCase())));
          setLoading(false);
        }).catch(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [foodlist, setFoodlist]);

  useEffect(() => {
    setFound && setFound({ foods, foodsdb })
  }, [foods, foodsdb, setFound]);

  const applyFilter = useCallback((val) => {
    clearTimeout(timeoutSearch);
    if (foodlist && filter) {
      setTimeoutSearch(setTimeout(() => {
        setFoods(foodlist.filter(item =>
          (item.title || '').toLowerCase().includes(filter.toLowerCase()) ||
          (item.tags || '').toLowerCase().includes(filter.toLowerCase())
        ))
      }, 500));
    }
  }, [foodlist, filter, timeoutSearch, setTimeoutSearch])

  return <Comp>
    <Input
      value={filter}
      onChange={(e, d) => { setFilter(d.value); applyFilter(d.value) }}
      action={
        <Button basic floating icon="remove" onClick={() => { setFilter(''); setFoods(); setFoodsdb() }} />
      }
      icon={
        <Icon name="search" loading={loading} />
      }
      iconPosition='left'
      placeholder='Suchbegriff eingeben...'
    />
  </Comp>
};

export default FoodSearch;

const Comp = styled.div`
  > *{
    width: 100%;
  }
  .ui.button.basic{
    background:white!important;
    border-top-right-radius:2rem!important;
    border-bottom-right-radius:2rem!important;
    box-shadow:none!important;
    border-right:1px solid ${COLORS.BLUE};
    border-top:1px solid ${COLORS.BLUE};
    border-bottom:1px solid ${COLORS.BLUE};
  }
  .ui.input{
    input{
      line-height:2rem;
      background:white!important;
      border-top-left-radius:2rem!important;
      border-bottom-left-radius:2rem!important;
      box-shadow:none!important;
      border:none;
      border-left:1px solid ${COLORS.BLUE};
    border-top:1px solid ${COLORS.BLUE};
    border-bottom:1px solid ${COLORS.BLUE};
    }
  }
`;

/*

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { noop } from 'lodash';
import aeclient from 'aws-elasticsearch-client';
import { fromJS, List,Map } from 'immutable';
import { Auth } from 'aws-amplify';
import { Button } from 'semantic-ui-react';
import { AppContext } from '../../containers/App/context';
import AppUtils, { buttonLabelPage } from '../../utils/AppUtils';
import { FormInput, FormInputNew } from '../components';

export default class FoodSearch extends React.Component {
  static propTypes = {
    setFound: PropTypes.func,
    clear: PropTypes.bool,
    limit:PropTypes.number,
  };

  static defaultProps = {
    setFound: noop,
    clear: false,
    limit:50,
  };

  state = {
    filter: '',
    // eslint-disable-next-line react/no-unused-state
    loading: false,
    list: new List(),
    additionals:new Map(),
    timeoutSearch: null,
  };

  static contextType = AppContext;

  componentDidMount() {
    this.loadData();
  }

  componentWillReceiveProps(props) {
    if (props.clear) {
      this.resetFilter();
    }
    if(props.limit!==this.props.limit){
      this.setState({limit:props.limit},()=>{this.search()})
    }
  }

  moreResults=()=>{
    this.setState({limit:this.state.limit+50})
  }

  loadData = () => {
    this.setState({ loading: true });
    Promise.all([
      AppUtils.getCachedS3Data('data/kk_food/index.json'),
      AppUtils.getS3Data('data/kk_foods_data_additional/index.json')
        .then(result => {
          const r = fromJS(result);
          let additionals = new Map();
          r.forEach(a => {
            const id = a.get('_id');
            a=a.delete('_id');
            a=a.delete('_rev');
            additionals = additionals.set(`c-${id}`, a);
          });
          return additionals;
        }),
    ]).then(
      data => {
        this.setState({
          list: fromJS(data[0]).map(elem =>
            fromJS({
              tags: elem.get('tags', new List()).join(','),
              id: elem.get('_id'),
              title: elem.getIn(['i18n', 'de', 'definition', 'title'], ''),
            }),
          ),
          additionals:data[1],
          loading: false,
        });
      },
    );
  };

  search=(withWildcard)=>{
    const self=this;
    this.setState({
      timeoutSearch: setTimeout(() => {
        const filtered = this.state.filter
          ? this.state.list.filter(
            elem =>
              elem.get('title', '').toLowerCase().includes(this.state.filter.toLowerCase()) ||
                elem.get('tags', '').toLowerCase().includes(this.state.filter.toLowerCase()),
          )
          : new List();
        Auth.currentCredentials().then(credentials => {
          const creds = Auth.essentialCredentials(credentials);
          const options = {
            credentials: creds,
            host:
              'https://search-foodsearch-f5yb72y7cuqvx335apd3vrzyqu.eu-central-1.es.amazonaws.com/',
            region: 'eu-central-1',
          };
          const client = aeclient.create(options);
          // const words=this.state.filter.split(' ');
          self.setState({ loading: true });
          client
            .search({
              q: withWildcard ? `${this.state.filter}*`:this.state.filter,
              size: this.props.limit,
              index: 'codecheck',
            })
            .then(result => {
              const r = fromJS(result);
              self.props.setFound(
                fromJS({
                  foods: filtered,
                  foodsdb: r.getIn(['hits', 'hits'], new List())
                    .filter(el=>!this.state.additionals.getIn([`c-${el.getIn(['_source', 'ean'])}`,'deleted'],false))
                    .map(el => {
                      let title = '';
                      if (el.getIn(['_source', 'subtit'], '').length > 0) {
                        title = `${el.getIn([
                          '_source',
                          'name',
                        ])} (${el.getIn(['_source', 'subtit'])})`;
                      } else {
                        title = el.getIn(['_source', 'name']);
                      }
                      const titleRaw = el.getIn(['_source', 'name'],'');
                      let score = el.get('_score',0);
                      if(titleRaw.toLowerCase().includes(this.state.filter.toLowerCase())){
                        score+=5;
                      }
                      if(titleRaw.toLowerCase().startsWith(this.state.filter.toLowerCase())){
                        score+=15;
                      }
                      return fromJS({
                        title,
                        score,
                        id: el.getIn(['_source', 'ean']),
                        codecheckID:el.getIn(['_source', 'id']),
                        source:'foodsdb',
                        sourcedata:el.get('_source',new Map()),
                      });
                    }),
                }),
              );
              if (r.getIn(['hits', 'hits'], new List()).size < 5){
                this.search(true);
              }
              self.setState({
                loading: false,
              });
            })
            .catch();
        });
      }, 500),
    });
  }

  changeFilter = (event, data) => {
    clearTimeout(this.state.timeoutSearch);
    this.setState({ filter: data.value, loading: true },()=>{
      if (data.value.length > 1) {
        this.search();
      }
    });
  };

  resetFilter = () => {
    this.setState({ filter: '' });
    this.props.setFound(
      fromJS({
        source: 'kk_foods',
        foods: new List(),
      }),
    );
  };

  render() {
    return (
      <Comp>
        <FormInputS
          action={
            <Button basic compact icon="remove" onClick={this.resetFilter} />
          }
          icon="search"
          size="mini"
          iconPosition="left"
          placeholder={buttonLabelPage(
            this.context,
            'labelsEat',
            'Search_Input_Placeholder',
          )}
          value={this.state.filter}
          onChange={this.changeFilter}
        />
      </Comp>
    );
  }
}

const Comp = styled.div`
  width: 100%;
`;

const FormInputS = styled(FormInputNew)`
  position: relative;
  border-radius: 30px;
  background; #fff
  .ui[class*='left icon'].input > input {
    padding: 0.25rem 0.25rem 0.25rem 1.5em !important;
    width: 100%;
  }
 }
`;


*/