import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import {
  RecoilRoot
} from "recoil";

import { I18n, Amplify } from 'aws-amplify';
// eslint-disable-next-line import/order
import aws_exports from './aws-exports';
Amplify.configure(aws_exports);
const dict = {
  de: {
    'Sign in to your account': 'Anmelden',
    'Sign In': "Anmelden",
    'Sign in': "Anmelden",
    'Sign Up': "Registrieren",
    'Sign Out': "Abmelden",
    'Forgot Password': "Passwort vergessen",
    'Username': "Benutzername",
    'Password': "Passwort",
    'Change Password': "Passwort ändern",
    'New Password': "Neues Passwort",
    'Email': "Email",
    'Phone Number': "Telefon",
    'Confirm a Code': "Code bestätigen",
    'Confirm Sign In': "Anmeldung bestätigen",
    'Confirm Sign Up': "Registrierung bestätigen",
    'Back to Sign In': "Zurück zur Anmeldung",
    'Send Code': "Code zusenden",
    'Confirm': "Bestätigen",
    'Resend a Code': "Code nochmal anfordern",
    'Submit': "Absenden",
    'Skip': "Überspringen",
    'Verify': "Prüfen",
    'Verify Contact': "Kontakt prüfen",
    'Code': "Code",
    'Account recovery requires verified contact information':
      "Wiederherstellung des Accounts bentötigt Kontaktinformationen",
    'User does not exist': "Benutzer existiert nicht",
    'User already exists': "Benutzer existiert bereits",
    'Incorrect username or password': "Benutzername oder Passwort nicht korrekt",
    'Invalid password format': "Kein gültiges Passwort",
    'Invalid phone number format': "Keine gültige Telefonnummer",
    'Enter your username': 'Benutzernamen eingeben',
    'Enter your password': 'Passwort eingeben',
    'Forget your password? ': 'Passwort vergessen? ',
    'No account? ': 'Kein Benutzerkonto? ',
    'Have an account? ': 'Sie sind bereits registriert? ',
    'Create account': 'Registrieren',
    'Reset password': 'Passwort zurücksetzen',
    'Reset your password': 'Passwort zurücksetzen',
    'Resend Code': "Code nochmal anfordern",
    'Create a new Account': "Registrieren",
    'Create a new account': "Registrieren",
    'Create Account': "Registrieren",
    'Email Address': 'Email-Adresse',
    'Create a username': 'Benutzername',
    'Create a password': 'Passwort',
  },
};

I18n.putVocabularies(dict);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RecoilRoot>
      <App />
    </RecoilRoot>
  </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

defineCustomElements(window);
