/* eslint-disable eqeqeq */
import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import ReelSelector from './ReelSelector';
import { COLORS } from '../../constants';
const minutes = ['00', '15', '30', '45'];
const TimePicker = props => {
  const { hour, minute, callback = () => { }, nolabel = false, label, hoursRangeTo, labelHours = 'Stunde', labelMinutes = 'Minuten' } = props;
  const [currentHour, setCurrentHour] = useState(hour);
  const [currentMinute, setCurrentMinute] = useState(minute);

  const hours = useMemo(() => {
    const h = [];
    for (let i = 0; i < (hoursRangeTo || 24); i++) {
      h.push(`${i}`.padStart(2, '0'));
    }
    return h;
  }, [hoursRangeTo]);

  /*
    useEffect(() => {
      if (hour != currentHour) {
        setCurrentHour(hour);
      }
      if (minute != currentMinute) {
        setCurrentMinute(minute);
      }
  
    }, [hour, minute, currentMinute, currentHour]);
  */
  useEffect(() => {
    if (currentHour != hour || currentMinute != minute) {
      callback && callback(currentHour, currentMinute)
    }

  }, [currentHour, currentMinute, callback, hour, minute]);
  return (<Wrapper>
    {(currentHour && currentMinute && !!!nolabel) && <label>{label || 'Uhrzeit'}</label>}
    <Selectors>
      <div>
        <h4>{labelHours}</h4>
        <ReelSelector visibleItems={3} name="hh" selectedItem={currentHour} items={hours} onChange={evt => { setCurrentHour(evt) }} />
      </div>
      <div><h4>{labelMinutes}</h4>
        <ReelSelector visibleItems={3} name="mm" selectedItem={currentMinute} items={minutes} onChange={evt => { setCurrentMinute(evt) }} />
      </div>
    </Selectors>
  </Wrapper>);
}
export default TimePicker;

const Selectors = styled.div`
	display:flex;
	justify-content:space-between;
	width:100%;
	gap:1rem;
	padding:0 ;
  > *{
    flex:1 1 50%;
    border-radius:1rem;
    padding:1rem 0 0 0;
    gap:0;
    background:white;
    border:1px solid ${COLORS.BLUE};
  }
	width:100%;
	h3 {
		color: ${COLORS.BLUE}!important;
		margin:1rem 0 0.5rem 0;
		font-weight: bold;
		text-transform: none;
		font-size: 1.1rem;
		line-height: 1em;
		width: 100%;
		padding:0 ;
		text-align:center;
	  }
	  h4 {
		margin:0;
		color: ${COLORS.ORANGE}!important;
		font-weight: bold;
		text-transform: none;
		font-size: 1.1rem;
		line-height: 1.5em;
		width: 100%;
		padding:0 ;
		text-align:center;
	  }
`;

const Wrapper = styled.div`
display:flex;
justify-content:flex-start;
flex-direction:column;
.selblocks{
  display:flex;
  flex-direction:row;
  margin:0 -1rem;
}
label{
  color:${COLORS.BLUE};
}
.selblock{
  &.selh{
    flex:0 1 80%!important;
  }
  &.selm{
    flex:0 1 50%!important;
  }
  display:flex;
  flex-direction:column;
  flex:0 1 50%;
  margin:0 0.5rem;
  button{
    font-size:1rem;
    color:white;
    cursor:pointer;
    background: ${COLORS.ORANGE};
    border-radius:1.5rem;
    padding:0.5rem 0.5rem;
    line-height:1rem;
    margin:0.5rem;  
    flex:0 1 auto;
    &.selected{
      border: 1px solid ${COLORS.BLUE};
      color:${COLORS.BLUE};
      font-size:1.4rem;
      line-height:1em;
      background:white;
    }
  }
  .buttonlist{
    margin:0 -0.5rem;
    display:flex;
    flex-wrap:wrap;
    flex:0 1 auto;
    justify-content:flex-start;
  }
}
`;