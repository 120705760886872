import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AppUtils } from "../../utils";
import { get } from "lodash";
import { qty } from "../../kk_qte_spec";

const Comp = (props) => {
    const { id, amount, unit } = props;
    const [food, setFood] = useState();

    useEffect(() => {
        if (id !== "") {
            AppUtils.getS3Data(`data/kk_food/${id}.json`)
                .then((result) => {
                    setFood(result);
                })
                .catch((e) => {
                    console.log(e);
                });
        }
    }, [id]);

    return <CompS>
        {amount && <span>{amount}&nbsp;</span>}{unit && <span>{get(qty, `${unit}.i18n.de.definition.title`, '')}&nbsp;</span>}{get(food, "i18n.de.definition.title")}
    </CompS>
};
export default Comp;
const CompS = styled.div`

`;