import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TitleBar, Section, InfoBox } from "../common";
import { useLocalStorage } from "usehooks-ts";
import { Button, Icon, Input } from "semantic-ui-react";
import { COLORS } from "../../constants";
import useInBodyLocationFinder from "./useInBodyLocationFinder";
import useGeolocation from "../Uvida/useGeolocation";
import logo from './logo.png'

const Comp = (props) => {
    const [infoHidden, setInfoHidden] = useLocalStorage(
        "nutrick24_inbody_infobox_hidden",
        false
    );
    const [infoSearchHidden, setInfoSearchHidden] = useLocalStorage(
        "nutrick24_inbody_infobox_search_hidden",
        false
    );
    const [plz, setPlz] = useState("");
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");

    const location = useGeolocation();

    const { found, loadingInBody } = useInBodyLocationFinder({
        latitude,
        longitude,
        radius: 10,
        plz,
    });

    useEffect(() => {
        if (location) {
            if (!location.error) {
                setLatitude(location.coordinates.lat);
                setLongitude(location.coordinates.lon);
            }
        }
    }, [location]);

    return (
        <CompS>
            <TitleBar label="InBody Bio-Impedanz-Analyse" />
            <Section id="infoblock1" className={infoHidden && "hidden"}>
                {infoHidden ? (
                    <Icon
                        name="info circle"
                        onClick={() => {
                            setInfoHidden(false);
                        }}
                    />
                ) : (
                    <InfoBox
                        dismiss={() => {
                            setInfoHidden(true);
                        }}
                        mode="dark"
                    >
                        Die Bio-Impedanz-Analyse ist ein nützliches Verfahren, das uns dabei hilft, die Zusammensetzung Ihres Körpergewichts besser zu verstehen. Es zeigt uns, wie viel von unserem Gewicht aus Muskeln, Körperfett und Wasser besteht.
                        <br />
                        Diese Analyse ermöglicht es uns auch, die Verteilung von Körperfett zu sehen.
                    </InfoBox>
                )}
            </Section>
            <Section id="infoblocksearch">
                <h2>
                    <img alt="" src={logo} height="20px" />Standorte zur Vermessung{" "}
                    <div>
                        {infoSearchHidden && (
                            <Icon
                                name="info circle"
                                onClick={() => {
                                    setInfoSearchHidden(false);
                                }}
                            />
                        )}
                    </div>
                </h2>
                {!infoSearchHidden && (
                    <InfoBox
                        mode="inline light"
                        dismiss={() => {
                            setInfoSearchHidden(true);
                        }}
                    >
                        Suche über eine Postleitzahl oder die aktuelle Position.
                    </InfoBox>
                )}
                <div className="locationbuttons">
                    <div id="plzsearch">
                        <Input
                            value={plz}
                            onChange={(e, d) => {
                                setPlz(d.value);
                            }}
                            action={
                                <Button
                                    loading={loadingInBody}
                                    basic
                                    floating
                                    icon="remove"
                                    onClick={() => {
                                        setPlz("");
                                    }}
                                />
                            }
                            icon={<Icon name="search" />}
                            iconPosition="left"
                            placeholder="Postleitzahl"
                        />
                    </div>
                </div>
                {found.length > 0 && (
                    <div className="foundlocations">
                        {found.map((location, index) => {
                            const { id } = location;
                            return <InBodyLocation key={id} location={location} />;
                        })}
                    </div>
                )}
            </Section>
        </CompS>
    );
};

export default Comp;

const InBodyLocation = (props) => {
    const { location } = props;
    const { city, web, name, zip } = location;
    const [open, setOpen] = useState(false);

    return (
        <InBodyLocationS>
            <div
                className="titlebar"
                onClick={() => {
                    setOpen(!open);
                }}
            >
                <div>
                    <h3>
                        <span>{city}</span>
                    </h3>{" "}
                    <h3 dangerouslySetInnerHTML={{ __html: name }} />
                </div>
                <Icon name={open ? "chevron up" : "chevron down"} />
            </div>
            <div className={`details ${open ? "open" : "closed"}`}>
                <div>
                    <h4>Adresse</h4>
                    <p>
                        {zip} {city}
                    </p>
                </div>
                {web && <div>
                    <h4>Homepage</h4>
                    <p>
                        <a
                            rel="noreferrer"
                            target="_blank"
                            href={`https://${web.replace("https://", "").replace("http://", "")}`}
                        >
                            {web}
                        </a>
                    </p>
                </div>}
            </div>
        </InBodyLocationS>
    );
};

const InBodyLocationS = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.BLUE};
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  color: white;
  .titlebar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    gap: 1rem;
    h3 {
      font-size: 1rem;
      margin: 0;
      font-weight: normal;

      span {
        font-weight: bold;
      }
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    a {
      color: white !important;
      text-decoration: underline;
    }
    h4 {
      font-size: 1rem;
      margin: 0;
    }

    &.open {
      opacity: 1;
      padding: 1rem 0 0 0;
      max-height: 1000px;
      transition: all 0.5s ease-in-out;
    }
    &.closed {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      padding: 0;
      transition: all 0.5s ease-in-out;
    }
  }
`;

const CompS = styled.div`
  h2 {
    margin: 0;
  }
  .foundlocations {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }
  .locationbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    > * {
      flex: 1 1 10%;
    }
    #plzsearch {
      .ui.button.basic {
        background: white !important;
        border-top-right-radius: 2rem !important;
        border-bottom-right-radius: 2rem !important;
        box-shadow: none !important;
        border-right: 1px solid ${COLORS.BLUE};
        border-top: 1px solid ${COLORS.BLUE};
        border-bottom: 1px solid ${COLORS.BLUE};
      }
    }
    .ui.input {
      input {
        line-height: 1.3rem;
        background: white !important;
        border-top-left-radius: 2rem !important;
        border-bottom-left-radius: 2rem !important;
        box-shadow: none !important;
        border: none;
        width: 40%;
        border-left: 1px solid ${COLORS.BLUE};
        border-top: 1px solid ${COLORS.BLUE};
        border-bottom: 1px solid ${COLORS.BLUE};
      }
    }
  }
  #infoblock1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    &.hidden {
      margin-top: -1rem;
    }
    i {
      cursor: pointer;
      font-size: 2rem;
      line-height: 2rem;
      box-shadow: none !important;
      border: none !important;
      color: ${COLORS.ORANGE};
      &.remove {
        font-size: 1rem;
        color: white;
      }
      &.info {
        margin-top: 1rem;
        font-size: 1.3rem;
      }
    }
  }
  #infoblocksearch {
    h2 {
      display: flex;
      justify-content: flex-start;
      gap: 0.5rem;
    }
  }
`;
