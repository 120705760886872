import { useCallback } from "react";
import { useNavigate as useRNavigate } from "react-router-dom";

export default function useNavigate() {
  const goto = useRNavigate();
  const g = useCallback(
    (route) => {
      goto(route);
      window.scrollTo(0, 0);
    },
    [goto]
  );

  return g;
}
