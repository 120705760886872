import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { TitleBar, Datepicker, Section } from "../../common";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import {
    foodfavoritesState,
    cognitoUserState,
    caseState,
    currentChecklistState,
    newMealDataState,
    reloadfoodfavoritesState,
} from "../../../atoms";
import { Icon } from "semantic-ui-react";
import { AppUtils } from "../../../utils";
import { COLORS } from "../../../constants";
import { KKButton } from "../../Buttons";
import { get, set } from "lodash";
import { produce } from "immer";
import EatAssist from "./EatAssist";
import { CompS } from "../common.jsx";
//import Resizer from "react-image-file-resizer";

const Comp = (props) => {
    const { day } = useParams();
    const [currentChecklist, setCurrentchecklist] = useRecoilState(
        currentChecklistState
    );
    const [caseN, setCaseN] = useRecoilState(caseState);
    const [assist, setAssist] = useState(false);
    const cognitoUser = useRecoilValue(cognitoUserState);
    const favorites = useRecoilValue(foodfavoritesState);
    const setNewMealData = useSetRecoilState(newMealDataState);
    const setReloadfoodfavorites = useSetRecoilState(reloadfoodfavoritesState);
    const [now, setNow] = useState(Date.now());

    const reloadFoodFavorites = () => {
        setReloadfoodfavorites(Date.now());
    };

    useEffect(() => {
        if (caseN) {
            const d = get(caseN, `checklists.d${day}`);
            if (d) {
                setCurrentchecklist(d);
            } else {
                setCurrentchecklist({ id: `d${day}`, done: false });
            }
        }
    }, [caseN, setCurrentchecklist, day]);

    const save = useCallback(
        (cl) => {
            const pc = produce(caseN || {}, (draft) => {
                set(draft, `checklists.${cl.id}`, cl);
            });
            setCaseN(pc);
            AppUtils.putS3Data(`user/${cognitoUser.sub}/case.json`, pc);
        },
        [cognitoUser, caseN, setCaseN]
    );

    const removeFood = useCallback(
        (id) => {
            const cl = produce(currentChecklist, (draft) => {
                draft.foods_type_f = (draft.foods_type_f || []).filter(
                    (f) => f && f.id !== id
                );
                draft.foods_type_m = (draft.foods_type_m || []).filter(
                    (f) => f && f.id !== id
                );
                draft.foods_type_a = (draft.foods_type_a || []).filter(
                    (f) => f && f.id !== id
                );
                draft.foods_type_z = (draft.foods_type_z || []).filter(
                    (f) => f && f.id !== id
                );
            });
            setCurrentchecklist(cl);
            save(cl);
        },
        [currentChecklist, save, setCurrentchecklist]
    );

    const removeFoodFromFavorites = (id) => {
        const idParts = id.split("--");
        AppUtils.delS3Data(
            `user/${cognitoUser.sub}/foodfavorites/${idParts[0]}.json`
        )
            .then(() => {
                reloadFoodFavorites();
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    };
    const addFoodToFavorites = (f) => {
        const idParts = get(f, "id", "").split("--");
        f = set(f, "id", idParts[0]);
        AppUtils.putS3Data(
            `user/${cognitoUser.sub}/foodfavorites/${idParts[0]}.json`,
            f
        )
            .then(() => {
                reloadFoodFavorites();
            })
            .catch((err) => {
                // eslint-disable-next-line no-console
                console.log(err);
            });
    };

    return (
        <div>
            {assist ? (
                <EatAssist
                    onClose={() => {
                        setAssist(false);
                    }}
                    now={now}
                />
            ) : (
                <>
                    <TitleBar label="Ernährungstagebuch" />
                    <CompS>
                        <Section>
                            <Datepicker day={day} baseroute="/protocols/eat" />
                        </Section>
                        <Section>
                            <KKButton
                                color={COLORS.ORANGE}
                                onClick={() => {
                                    setNewMealData({ isnew: true });
                                    setAssist(true);
                                    setNow(Date.now());
                                }}
                            >
                                Lebensmittel hinzufügen
                            </KKButton>
                        </Section>
                        {mlists
                            .filter(
                                (mlist) =>
                                    (get(currentChecklist, mlist.ftype, []) || []).length > 0
                            )
                            .map((mlist) => (
                                <Section key={mlist.ftype}>
                                    <h2>{mlist.title}</h2>
                                    <div className="mlist">
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th className="left">Lebensmittel</th>
                                                    <th className="right">Menge</th>
                                                    <th className="right">Uhrzeit</th>
                                                    <th></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {(get(currentChecklist, mlist.ftype, []) || []).map(
                                                    (f, idx) => {
                                                        let isFavorite = false;
                                                        favorites.forEach((fav) => {
                                                            if (
                                                                get(f, "id", "").startsWith(get(fav, "_id"))
                                                            ) {
                                                                isFavorite = true;
                                                            }
                                                        });
                                                        return (
                                                            <tr key={idx}>
                                                                <td className="left">
                                                                    <Icon
                                                                        name={isFavorite ? "star" : "star outline"}
                                                                        onClick={() => {
                                                                            if (isFavorite) {
                                                                                removeFoodFromFavorites(f.id);
                                                                            } else {
                                                                                addFoodToFavorites(f);
                                                                            }
                                                                        }}
                                                                    />
                                                                    {get(
                                                                        f,
                                                                        "i18n.de.definition.title",
                                                                        get(f, "definition.title", "")
                                                                    )}
                                                                </td>
                                                                <td className="right">
                                                                    {" "}
                                                                    {get(
                                                                        f,
                                                                        "definition.portion_amount_selected",
                                                                        get(f, "definition.portion_amount", 0)
                                                                    )}
                                                                    &nbsp;{get(f, "definition.portion_unit", "")}
                                                                </td>
                                                                <td className="right">
                                                                    {get(f, "etime", "0000").substring(0, 2)}:
                                                                    {get(f, "etime", "0000").substring(2)}
                                                                </td>
                                                                <td className="right icons">
                                                                    <Icon
                                                                        name="edit outline"
                                                                        onClick={() => {
                                                                            setNewMealData(
                                                                                produce(f, (d) => {
                                                                                    d.isedit = true;
                                                                                    d.isnew = false;
                                                                                    d.mtype = mlist.ft;
                                                                                    d.food = produce(d, (dd) => {
                                                                                        delete dd.isedit;
                                                                                        delete dd.isnew;
                                                                                    });
                                                                                })
                                                                            );
                                                                            setAssist(true);
                                                                        }}
                                                                    />
                                                                    <Icon
                                                                        name="minus circle"
                                                                        onClick={() => {
                                                                            removeFood(f.id);
                                                                        }}
                                                                    />
                                                                </td>
                                                            </tr>
                                                        );
                                                    }
                                                )}
                                            </tbody>
                                        </table>
                                    </div>
                                </Section>
                            ))}
                    </CompS>
                </>
            )}
        </div>
    );
};
export default Comp;

const mlists = [
    {
        ftype: "foods_type_f",
        ft: "f",
        title: "Frühstück",
    },
    {
        ftype: "foods_type_m",
        ft: "m",
        title: "Mittag",
    },
    {
        ftype: "foods_type_z",
        ft: "z",
        title: "Snacks",
    },
    {
        ftype: "foods_type_a",
        ft: "a",
        title: "Abend",
    },
];

/*
import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import { fromJS, Map, List } from 'immutable';
import 'rc-slider/dist/rc-slider.css';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import { Icon, Button } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';
import moment from 'moment';
import request from 'superagent';
import FoodListItem from '../FoodListItem';
import { AppContext } from '../../containers/App/context';
import TimePicker from '../TimePicker';
import TitleBar from '../TitleBar';
import SavedImage from './SavedImage';
import AppUtils, {
    htmlDecode,
    buttonLabelPage,
    labelFlat,
} from '../../utils/AppUtils';
import { COLORS } from '../../constants';
import KKNoLinkButton from '../KKNoLinkButton';
import FoodSearch from '../FoodSearch';
import { CalButton, CalButtonNew, DatePickerComp } from '../Calendar';
import KKLinkButton from '../KKLinkButton';
import {
    Favresults,
    FoodsList,
    FoodsListLine,
    FoodsListLineFood,
    FoodsListLineAmount,
    FoodsListLineTime,
    FoodsListLineActions,
    FoodsListLineLabel,
    DeleteButton,
    Searchresults,
    SearchResultSection,
    SearchResLabel,
    SearchItem,
    FavItem,
    KKButton,
    KKButtonSmall,
} from '../components';
import Snack from '../../images/snack.svg';
import Dinner from '../../images/dinner.svg';
import Breakfast from '../../images/breakfast.svg';
import Lunch from '../../images/lunch.svg';
import ImgUpload from '../../images/imgupload.png';
import Resizer from "react-image-file-resizer";

function trailNull(v) {
    return (100 + v).toString().substring(1, 3);
}


let marks = new Map();
for (let i = 0; i <= 96; i++) { // eslint-disable-line
    if (i % 12 === 0) {
        marks = marks.set(i, trailNull(i / 4));
    }
}

export default class Maint extends React.Component {
    static propTypes = {
        checklist: PropTypes.instanceOf(Map),
        update: PropTypes.func,
        // eslint-disable-next-line react/no-unused-prop-types
        baseMetabolicRate: PropTypes.number,
        // eslint-disable-next-line react/no-unused-prop-types
        dailyReq: PropTypes.number,
        day: PropTypes.string,
    };

    static defaultProps = {
        checklist: new Map(),
        update: noop,
        baseMetabolicRate: 0,
        dailyReq: 0,
        day: '20100101',
    };

    state = {
        daystartPickerVisible: true,
        mealtimePickerVisible: true,
        newMeal: '',
        newMealData: new Map(),
        foods: new List(),
        foodsdb: new List(),
        clearSearch: false,
        showFavorites: false,
        datepickerDayOpen: false,
        MEALS: [],
        activeMeal: 'f',
        newMealDataDPicker: new Map(),
        favorites: new List(),
        limitSearch: 50,
        dayStartHour: '',
        dayStartMinute: '',
        mealTimeHour: '',
        mealTimeMinute: '',
        accountdata: new Map(),
        eat: true
    };

    static contextType = AppContext;

    componentDidMount() {
        this.loadData();
    }

    componentWillReceiveProps() {
        this.loadData();
    }

    loadData = () => {
        Auth.currentAuthenticatedUser().then(user => {
            const account = user.attributes.sub;
            Promise.all([
                AppUtils.getS3ItemsFromPath(`user/${account}/foodfavorites`)
                    .then(result => this.setState({ favorites: fromJS(result) }))
                    .catch(),
            ]).catch(e => this.setState({ error: e }));
        });
        const MEALS = [
            {
                id: 'f',
                label: labelFlat(
                    this.context.getIn(['labels', 'Fruehstueck', 'de'], ''),
                ),
                img: { Breakfast },
            },
            {
                id: 'm',
                label: labelFlat(this.context.getIn(['labels', 'Mittag', 'de'], '')),
                img: '../../images/lunch.png'
            },
            {
                id: 'z2',
                label: labelFlat(this.context.getIn(['labels', 'Snack', 'de'], '')),
                img: '../../images/snack.png'
            },
            {
                id: 'a',
                label: labelFlat(this.context.getIn(['labels', 'Abend', 'de'], '')),
                img: '../../images/dinner.png'
            },
        ];
        this.setState({ MEALS });
    };

    onDaystartHourChange = val => {
        this.setState({ dayStartHour: val.length == 1 ? '0' + val : val }, () => {
            if (this.state.dayStartHour && this.state.dayStartMinute) {
                this.props.update(
                    this.props.checklist.set('daystart', this.state.dayStartHour + this.state.dayStartMinute),
                );
            }
        });
    };

    onDaystartMinChange = val => {
        this.setState({ dayStartMinute: val.length == 1 ? '0' + val : val }, () => {
            if (this.state.dayStartHour && this.state.dayStartMinute) {
                this.props.update(
                    this.props.checklist.set('daystart', this.state.dayStartHour + this.state.dayStartMinute),
                );
            }
        });
    };

    resetMeal = () => {
        this.setState({
            newMealData: this.state.newMealData.set('food', null),
        });
    };

    onMealtimeHourChange = val => {
        this.setState({ mealTimeHour: val.length == 1 ? '0' + val : val }, () => {
            if (this.state.mealTimeHour && this.state.mealTimeMinute) {
                this.setState({
                    newMealDataDPicker: this.state.newMealDataDPicker.setIn(
                        [this.state.activeMeal],
                        this.state.mealTimeHour + this.state.mealTimeMinute,
                    )
                });
            }
        });
    };

    onMealtimeMinChange = val => {
        this.setState({ mealTimeMinute: val.length == 1 ? '0' + val : val }, () => {
            if (this.state.mealTimeHour && this.state.mealTimeMinute) {
                this.setState({
                    newMealDataDPicker: this.state.newMealDataDPicker.setIn(
                        [this.state.activeMeal],
                        this.state.mealTimeHour + this.state.mealTimeMinute,
                    )
                });
            }
        });
    };

    toggleFav = () => {
        this.setState({ showFavorites: !this.state.showFavorites });
    };

    toggleDaystartPickerVisible = () => {
        this.setState({ daystartPickerVisible: !this.state.daystartPickerVisible });
    };

    toggleMealtimePickerVisible = () => {
        this.setState({ mealtimePickerVisible: !this.state.mealtimePickerVisible });
    };

    toggleNewMeal = () => {
        this.setState({ newMeal: this.state.activeMeal });
    };

    closeNewMeal = () => {
        this.setState({ newMeal: '' });
    };

    setFoundFoods = result => {
        this.setState({
            foods: result.get('foods', new List()),
            foodsdb: result.get('foodsdb', new List()),
            showFavorites: false,
        });
    };

    removeFoodFromFavorites = (e, id) => {
        const idParts = id.split('--');
        Auth.currentAuthenticatedUser().then(user => {
            const account = user.attributes.sub;
            AppUtils.delS3Data(`user/${account}/foodfavorites/${idParts[0]}.json`)
                .then(this.loadData)
                .catch(err => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
        });
    };

    addFoodToFavorites = (e, f) => {
        Auth.currentAuthenticatedUser().then(user => {
            const account = user.attributes.sub;
            const idParts = f.get('id', '').split('--');
            // eslint-disable-next-line no-param-reassign
            f = f.set('id', idParts[0]);
            AppUtils.putS3Data(
                `user/${account}/foodfavorites/${f.get('id')}.json`,
                f.toJS(),
            )
                .then(this.loadData)
                .catch(err => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
        });
    };

    editFood = (e, f) => {
        this.setState(
            {
                clearSearch: true,
                newMealData: this.state.newMealData.set('food', f),
                newMeal: this.state.activeMeal,
                newMealDataDPicker: this.state.newMealDataDPicker.setIn(
                    [this.state.activeMeal],
                    f.get('etime'),
                ),
            },
            () => {
                this.removeFood(null, f.get('id'));
                this.setState({ clearSearch: false });
            },
        );
    };

    setNewFood = (e, data) => {
        switch (data.source) {
            case 'fav':
                this.setState({
                    newMealData: this.state.newMealData.set(
                        'food',
                        fromJS(data.data).set(
                            'id',
                            `${data.data.get('id', '')}--${Date.now()}`,
                        ),
                    ),
                });
                break;
            case 'foods':
                AppUtils.getCachedS3Data(`data/kk_food/${data.id}.json`).then(f => {
                    this.setState(
                        {
                            clearSearch: true,
                            newMealData: this.state.newMealData.set(
                                'food',
                                fromJS(f).set('id', `${f._id}--${Date.now()}`),
                            ),
                        },
                        () => this.setState({ clearSearch: false }),
                    );
                });
                break;
            case 'foodsdb':
                {
                    let food = new Map();
                    this.state.foodsdb.forEach(f => {
                        if (data.id === f.get('id')) {
                            const productName = f.getIn(['sourcedata', 'name'], '');
                            request
                                .get(
                                    `https://api.eat-and-move.com/codecheck/get/${f.get(
                                        'codecheckID',
                                    )}`,
                                )
                                .then(response => {
                                    const respData = response.body.result || {};
                                    const foodDataset = fromJS(respData);
                                    food = fromJS({
                                        _id: f.get('id'),
                                        id: `${f.get('id')}--${Date.now()}`,
                                        source: 'foodsdb',
                                        definition: {
                                            portion_hydrates: foodDataset
                                                .getIn(['nutriTable', 'carbonhydrate'], 0)
                                                .toString(),
                                            portion_fat: foodDataset
                                                .getIn(['nutriTable', 'fat'], '0')
                                                .toString(),
                                            portion_protein: foodDataset
                                                .getIn(['nutriTable', 'protein'], '0')
                                                .toString(),
                                            portion_amount: '100',
                                            portion_unit: 'gr / ml',
                                            portion_calories: Math.floor(
                                                parseFloat(
                                                    foodDataset.getIn(['nutriTable', 'calories'], '0'),
                                                ),
                                            )
                                                .toFixed(0)
                                                .toString(),
                                            name: productName,
                                            title: productName,
                                            score: f.get('score'),
                                        },
                                    });
                                    this.setState(
                                        {
                                            clearSearch: true,
                                            newMealData: this.state.newMealData.set('food', food),
                                        },
                                        () => this.setState({ clearSearch: false }),
                                    );
                                });
                        }
                    });
                }
                break;
            case 'img':
                let food = new Map();
                food = fromJS({
                    id: `imgupload--${Date.now()}`,
                    source: 'img',
                    datauri: data.data,
                });
                this.setState(
                    {
                        clearSearch: true,
                        newMealData: this.state.newMealData.set('food', food),
                    },
                    () => this.setState({ clearSearch: false }),
                );
                break;
            case 'imgupload':
                const foodUpload = fromJS({
                    id: `imgupload--${Date.now()}`,
                    source: 'imgupload',
                    imgid: data.filename,
                });
                this.setState(
                    {
                        clearSearch: true,
                        newMealData: this.state.newMealData.set('food', foodUpload),
                    },
                    () => this.setState({ clearSearch: false }),
                );
                break;
            default:
        }
    };

    increaseSearchLimit = () => {
        this.setState({ limitSearch: this.state.limitSearch + 50 });
    };

    toggleCalender = () => {
        this.setState({ datepickerDayOpen: !this.state.datepickerDayOpen });
    };

    changeDayfromCalender = d => {
        this.setState({
            datepickerDayOpen: false,
        });
        this.props.history.push(`/eat/${moment(d).format('YYYYMMDD')}`);
    };

    setChangedFood = food => {
        this.setState({ newMealData: this.state.newMealData.set('food', food) });
    };

    saveMeal = () => {
        const m = this.state.newMealData.get('food', new Map());
        const mealtime = this.state.newMealDataDPicker.get(
            this.state.activeMeal,
            this.props.checklist.get('daystart', '0000'),
        );
        const mealtype = this.state.activeMeal.substr(0, 1);
        const foods = this.props.checklist
            .get(`foods_type_${mealtype}`, new List())
            .push(m.set('etime', mealtime));
        this.props.update(
            this.props.checklist.set(`foods_type_${mealtype}`, foods),
        );
        this.setState({
            newMealData: new Map({ etime: mealtime }),
            newMeal: false,
        });
    };

    removeFood = (e, id) => {
        let updatedChecklist = this.props.checklist.set(
            'foods_type_f',
            this.props.checklist
                .get('foods_type_f', new List())
                .filter(f => f.get('id', '') !== id),
        );
        updatedChecklist = updatedChecklist.set(
            'foods_type_m',
            this.props.checklist
                .get('foods_type_m', new List())
                .filter(f => f.get('id', '') !== id),
        );
        updatedChecklist = updatedChecklist.set(
            'foods_type_a',
            this.props.checklist
                .get('foods_type_a', new List())
                .filter(f => f.get('id', '') !== id),
        );
        updatedChecklist = updatedChecklist.set(
            'foods_type_z',
            this.props.checklist
                .get('foods_type_a', new List())
                .filter(f => f.get('id', '') !== id),
        );
        this.props.update(updatedChecklist);
    };

    setActiveMeal = id => {
        this.setState({ activeMeal: id });
    };

    nextDay = () => {
        this.props.history.push(`/eat/${moment(this.props.day).add(1, "days").format('YYYYMMDD')}`);
    }

    lastDay = () => {
        this.props.history.push(`/eat/${moment(this.props.day).subtract(1, "days").format('YYYYMMDD')}`);
    }

    handleFileRead = async (event) => {
        const file = event.target.files[0];
        const image = await this.resizeFile(file);
        const fileDataset = {
            lastModified: file.lastModified,
            data: image
        };
        Auth.currentAuthenticatedUser().then(user => {
            const account = user.attributes.sub;
            const fileName = Date.now();
            AppUtils.putS3Data(
                `user/${account}/images/${fileName}.json`,
                fileDataset,
            )
                .then(() => {
                    this.setNewFood(null, {
                        source: 'imgupload',
                        filename: fileName,
                    })
                })
                .catch(err => {
                    // eslint-disable-next-line no-console
                    console.log(err);
                });
        });
    }

    resizeFile = (file) => {
        return new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                512,
                384,
                "JPEG",
                35,
                0,
                (uri) => {
                    resolve(uri);
                },
                "base64"
            );
        });
    }

    render() {
        const daystart = this.props.checklist.get('daystart', '');
        const dayStartHour = daystart ? daystart.substr(0, 2) : this.state.dayStartHour || '';
        const dayStartMinute = daystart ? daystart.substr(2, 2) : this.state.dayStartMinute || '';
        const subtype = this.props.checklist.getIn(
            ['plan', 'data', 'definition', 'subtype'],
            '',
        );
        const daystartFilled = daystart || this.state.dayStartHour + this.state.dayStartMinute || '0000';
        const daystartPickerValue =
            parseInt(daystartFilled.substr(0, 2), 10) * 4 +
            parseInt(daystartFilled.substr(2, 2), 10) / 15;
        const etime = this.state.newMealDataDPicker.get(
            this.state.activeMeal,
            false,
        );
        const mtimePickerValue = etime
            ? parseInt(etime.substr(0, 2), 10) * 4 +
            parseInt(etime.substr(2, 2), 10) / 15
            : false;
        let foods = new List();
        if (this.state.activeMeal.startsWith('z')) {
            foods = this.props.checklist
                .get('foods_type_z', new List())
                .filter(
                    f =>
                        this.state.activeMeal === 'z1'
                            ? parseInt(f.get('etime', '0000'), 10) <=
                            parseInt(daystartFilled, 10) + 600
                            : parseInt(f.get('etime', '0000'), 10) >
                            parseInt(daystartFilled, 10) + 600,
                );
        } else {
            foods = this.props.checklist.get(
                `foods_type_${this.state.activeMeal}`,
                new List(),
            );
        }
        foods = foods.sortBy(f => f.get('etime', '0000'));
        let timepickerMealMin = daystartPickerValue;
        let timepickerMealMax = 96;
        if (subtype !== 'protocol') {
            switch (this.state.activeMeal) {
                case 'f':
                    timepickerMealMax = daystartPickerValue + 3 * 4;
                    break;
                case 'z1':
                    timepickerMealMax = daystartPickerValue + 6 * 4;
                    break;
                case 'm':
                    timepickerMealMin = daystartPickerValue + 3 * 4;
                    timepickerMealMax = daystartPickerValue + 8 * 4;
                    break;
                case 'z2':
                    timepickerMealMin = daystartPickerValue + 6 * 4;
                    break;
                case 'a':
                    timepickerMealMin = daystartPickerValue + 8 * 4;
                    break;
                default:
            }
        }

        const currentDay = moment(this.props.day, 'YYYYMMDD');
        let marksMeal = new Map();
        for (let i = timepickerMealMin; i <= timepickerMealMax; i++) { // eslint-disable-line
            if (i % (subtype === 'protocol' ? 12 : 4) === 0) {
                marksMeal = marksMeal.set(i, trailNull(i / 4));
            }
        }
        return (
            <Comp>
                <TitleBar
                    noNav={this.state.changedData}
                    color='#fff'
                    tcolor={COLORS.BLUE}
                    label={
                        'Essen_erfassen'
                    }
                    history={this.props.history}
                />
                <Intro>
                    <CalWrapper>
                        <button onClick={this.lastDay}>
                            <Icon name="angle left" />
                        </button>
                        <CalButtonNew day={this.props.day} onClick={this.toggleCalender} />
                        <button onClick={this.nextDay}>
                            <Icon name="angle right" />
                        </button>
                    </CalWrapper>
                    {this.state.datepickerDayOpen && <DatePickerComp
                        currentDay={currentDay}
                        changeDayfromCalender={this.changeDayfromCalender}
                    />}
                </Intro>
                <DaystartCalenderSection>
                    <QSec>
                        <SectionTitle
                            dangerouslySetInnerHTML={{
                                __html: htmlDecode(
                                    this.context.getIn(
                                        ['labelsEat', 'Frage_Tagesbeginn', 'de'],
                                        '',
                                    ),
                                ),
                            }}
                        />
                    </QSec>
                    <TimePicker hour={dayStartHour} minute={dayStartMinute} callback={(h, m) => {
                        this.onDaystartHourChange(h);
                        this.onDaystartMinChange(m)
                    }} />
                </DaystartCalenderSection>
                <MealSection>
                    {daystartPickerValue !== 0 && <React.Fragment>
                        <SectionTitle
                            dangerouslySetInnerHTML={{
                                __html: htmlDecode(
                                    this.context.getIn(
                                        ['labelsEat', 'Mahlzeit_erfassen', 'de'],
                                        '',
                                    ),
                                ),
                            }}
                        />
                        <Meals>
                            <MealTabButtons>
                                {this.state.MEALS.map(m => (
                                    m.id == 'f' || m.id == 'm' ?
                                        (
                                            <MealTabButton
                                                key={m.id}
                                                id={m.id}
                                                className={
                                                    this.state.activeMeal === m.id ? 'active' : 'inactive'
                                                }
                                                onClick={() => this.setActiveMeal(m.id)}
                                            >

                                                <img src={m.id == 'f' ? Breakfast : m.id == 'a' ? Dinner : m.id == 'z2' ? Snack : Lunch} style={{ width: "125px" }} />
                                            </MealTabButton>
                                        ) : null
                                ))}
                                {this.state.MEALS.map(m => (
                                    m.id == 'z2' || m.id == 'a' ?
                                        (
                                            <MealTabButton
                                                key={m.id}
                                                id={m.id}
                                                className={
                                                    this.state.activeMeal === m.id ? 'active' : 'inactive'
                                                }
                                                onClick={() => this.setActiveMeal(m.id)}
                                            >

                                                <img src={m.id == 'f' ? Breakfast : m.id == 'a' ? Dinner : m.id == 'z2' ? Snack : Lunch} style={{ width: "125px" }} />
                                            </MealTabButton>
                                        ) : null
                                ))}
                            </MealTabButtons>
                        </Meals>
                        {this.state.newMeal === this.state.activeMeal ? (
                            <MealTabContent>
                                <MInput>
                                    <CloseMinputButton onClick={this.closeNewMeal}>
                                        <Icon name="close" />
                                    </CloseMinputButton>
                                    <MInputSection>

                                        <SectionTitle
                                            dangerouslySetInnerHTML={{
                                                __html: htmlDecode(
                                                    this.context.getIn(
                                                        ['labelsEat', 'Label_Uhrzeit_Mahlzeit', 'de'],
                                                        '',
                                                    ),
                                                ),
                                            }}
                                        />
                                        <div>
                                            <TimePicker hour={this.state.mealTimeHour} minute={this.state.mealTimeMinute} callback={(h, m) => {
                                                this.onMealtimeHourChange(h);
                                                this.onMealtimeMinChange(m)
                                            }} />
                                        </div>
                                        {this.state.newMealData.get('food') ? (
                                            <NewFood>
                                                <FoodListItem
                                                    food={this.state.newMealData.get('food')}
                                                    onFoodChange={this.setChangedFood}
                                                />
                                                <div className="buttons">
                                                    <KKNoLinkButtonSmallWhite
                                                        onClick={this.resetMeal}
                                                        color={COLORS.ORANGE}
                                                        page="labelsEat"
                                                        label="Button_Mahlzeit_zuruecksetzen"
                                                    />
                                                    <KKNoLinkButtonSmallWhite
                                                        onClick={this.saveMeal}
                                                        color={COLORS.ORANGE}
                                                        page="labelsEat"
                                                        label="Button_Mahlzeit_speichern"
                                                    />
                                                </div>
                                            </NewFood>
                                        ) : null}
                                    </MInputSection>
                                    {mtimePickerValue &&
                                        <MInputSection>
                                            <div>
                                                <SearchBar className="ui form">
                                                    
                                                    <FoodSearch
                                                        setFound={this.setFoundFoods}
                                                        limit={this.state.limitSearch}
                                                        clear={this.state.clearSearch}
                                                    />
                                                </SearchBar>
                                            </div>
                                            {this.state.foods.size > 0 && !this.state.showFavorites ? (
                                                <SearchResultSection>
                                                    <SearchResLabel>
                                                        {buttonLabelPage(
                                                            this.context,
                                                            'labelsEat',
                                                            'Suchergebnis_unsere_Auswahl',
                                                        )}
                                                    </SearchResLabel>
                                                    <Searchresults>
                                                        {this.state.foods
                                                            .sortBy(f => f.get('title').toLowerCase())
                                                            .map(f => (
                                                                <SearchItem
                                                                    key={f.get('id')}
                                                                    onClick={e =>
                                                                        this.setNewFood(e, {
                                                                            id: f.get('id'),
                                                                            source: 'foods',
                                                                        })
                                                                    }
                                                                >
                                                                    {f.get('title')}
                                                                </SearchItem>
                                                            ))}
                                                    </Searchresults>
                                                </SearchResultSection>
                                            ) : null}
                                            {this.state.foodsdb.size > 0 &&
                                                !this.state.showFavorites ? (
                                                <SearchResultSection>
                                                    <SearchResLabel>
                                                        {buttonLabelPage(
                                                            this.context,
                                                            'labelsEat',
                                                            'Suchergebnis_Handel',
                                                        )}
                                                    </SearchResLabel>
                                                    <Searchresults>
                                                       
                                                        {this.state.foodsdb
                                                            .sortBy(f => 100 - f.get('score', 100))
                                                            .map(f => (
                                                                <SearchItem
                                                                    key={f.get('id')}
                                                                    onClick={e =>
                                                                        this.setNewFood(e, {
                                                                            id: f.get('id'),
                                                                            source: 'foodsdb',
                                                                            codecheckID: f.get('codecheckID'),
                                                                        })
                                                                    }
                                                                >
                                                                    {f.get('title')}
                                                                </SearchItem>
                                                            ))}
                                                        <Button onClick={this.increaseSearchLimit}>
                                                            Weitere Suchergebnisse
                                                        </Button>
                                                    </Searchresults>
                                                </SearchResultSection>
                                            ) : null}

                                            <KKLinkButton
                                                to="/food/new"
                                                color={COLORS.ORANGE}
                                                label="Eigenes_Lebensmittel"
                                                className="fullWidthButton noMarginLeft"
                                            />

                                            <label>
                                                <img src={ImgUpload} />
                                                <input
                                                    id="originalFileName"
                                                    type="file"
                                                    inputProps={{ accept: 'image/*' }}
                                                    label="Bild"
                                                    name="originalFileName"
                                                    onChange={e => this.handleFileRead(e)}
                                                    variant="standard"
                                                    style={{ display: "none" }}
                                                />
                                            </label>

                                            <FavouriteSection>

                                                <FavSectionTitle
                                                    dangerouslySetInnerHTML={{
                                                        __html: htmlDecode(
                                                            this.context.getIn(
                                                                ['labelsEat', 'Favoriten', 'de'],
                                                                '',
                                                            ),
                                                        ),
                                                    }}
                                                />
                                                <Favresults>
                                                    {this.state.favorites.map(f => (
                                                        <FavItem
                                                            key={f.get('id')}
                                                            onClick={e =>
                                                                this.setNewFood(e, {
                                                                    id: f.get('id'),
                                                                    source: 'fav',
                                                                    data: f,
                                                                })
                                                            }
                                                        >
                                                            {f.getIn(
                                                                ['i18n', 'de', 'definition', 'title'],
                                                                f.getIn(['definition', 'title'], ''),
                                                            )}
                                                        </FavItem>
                                                    ))}
                                                </Favresults>
                                            </FavouriteSection>
                                        </MInputSection>
                                    }
                                </MInput>
                            </MealTabContent>
                        ) : (
                            <MealTabContentWhite>
                                <KKNoLinkButton
                                    onClick={this.toggleNewMeal}
                                    color={COLORS.ORANGE}
                                    page="labelsEat"
                                    label="Button_Mahlzeit_hinzufuegen"
                                    className="fullWidthMargin"
                                />
                            </MealTabContentWhite>
                        )}
                    </React.Fragment>}

                </MealSection>


                {foods.size > 0 && <FoodsList>
                    <FoodsListLine>
                        <FoodsListLineFood>
                            <FoodsListLineLabel>
                                {buttonLabelPage(
                                    this.context,
                                    'labelsEat',
                                    'Label_Mahlzeit',
                                )}
                            </FoodsListLineLabel>
                        </FoodsListLineFood>
                        <FoodsListLineAmount>
                            <FoodsListLineLabel>
                                {buttonLabelPage(
                                    this.context,
                                    'labelsEat',
                                    'Label_Menge',
                                )}
                            </FoodsListLineLabel>
                        </FoodsListLineAmount>
                        <FoodsListLineTime>
                            <FoodsListLineLabel>
                                {buttonLabelPage(
                                    this.context,
                                    'labelsEat',
                                    'Label_Uhrzeit',
                                )}
                            </FoodsListLineLabel>
                        </FoodsListLineTime>
                        <FoodsListLineActions />
                    </FoodsListLine>
                    {foods.map((f, idx) => {
                        let isFavorite = false;
                        let isImage = f.get("source", '') == 'imgupload' ? true : false;
                        this.state.favorites.forEach(fav => {
                            if (f.get('id', '').startsWith(fav.get('_id'))) {
                                isFavorite = true;
                            }
                        });
                        return (
                            <FoodsListLine key={f.get('id', idx)}>
                                <FoodsListLineFood>
                                    {isImage ? (<SavedImage imageid={f.get("imgid")} />) : f.getIn(
                                        ['i18n', 'de', 'definition', 'title'],
                                        f.getIn(['definition', 'title'], ''),
                                    )}
                                </FoodsListLineFood>
                                <FoodsListLineAmount>
                                    {f.getIn(
                                        ['definition', 'portion_amount_selected'],
                                        f.getIn(['definition', 'portion_amount'], 0),
                                    )}
                                    &nbsp;
                                    {!isImage && f.getIn(['definition', 'portion_unit'], '')}
                                </FoodsListLineAmount>
                                <FoodsListLineTime>
                                    {f
                                        .get('etime', '0000')
                                        .toString()
                                        .substr(0, 2)}
                                    :
                                    {f
                                        .get('etime', '0000')
                                        .toString()
                                        .substr(2, 4)}
                                </FoodsListLineTime>
                                <FoodsListLineActions>
                                    <DeleteButton onClick={e => this.editFood(e, f)}>
                                        <Icon
                                            name="edit outline"
                                            style={{ color: COLORS.ORANGE, fontSize: '1.5rem' }}
                                        />
                                    </DeleteButton>
                                    <DeleteButton
                                        onClick={e => this.removeFood(e, f.get('id'))}
                                    >
                                        <Icon
                                            name="minus circle"
                                            style={{ color: COLORS.ORANGE, fontSize: '1.5rem' }}
                                        />
                                    </DeleteButton>
                                    {isImage ? (null) :
                                        <DeleteButton
                                            onClick={e =>
                                                isFavorite
                                                    ? this.removeFoodFromFavorites(e, f.get('id'))
                                                    : this.addFoodToFavorites(e, f)
                                            }
                                        >
                                            <Icon
                                                name={isFavorite ? 'star' : 'star outline'}
                                                style={{ color: COLORS.ORANGE, fontSize: '1.5rem' }}
                                            />
                                        </DeleteButton>
                                    }
                                </FoodsListLineActions>
                            </FoodsListLine>
                        );
                    })}
                </FoodsList>}
            </Comp>
        );
    }
}

// Label_Lebensmittel_auswaehlen Label_Mahlzeit Label_Uhrzeit Suchergebnis_unsere_Auswahl Suchergebnis_Handel

const CalWrapper = styled.div`
  display:flex;
  justify-content:center;
  align:center;
  flex-wrap:none;
  button{
    cursor:pointer;
  }
  i{
    font-size:2rem;
  }
  padding:0 1rem;
`;

const PlanInfo = styled.div`
  padding: 0 1rem 1rem 1rem;
  ul {
    padding: 0;
    li {
      margin: 0 0 1em 1em;
    }
  }
  h2 {
    font-size: 1.4rem;
    color: ${COLORS.GREEN};
  }
  h3 {
    font-size: 1.2rem;
    color: ${COLORS.PETROL};
  }
`;

const InfoButton = styled(Button)`
  margin: 1rem 0 0 1rem !important;
`;

const Comp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 70px;
`;
const MealTabButtons = styled.div`
  margin: 0 -0.5rem !important;
  display: flex;
  justify-content: space-between;
  flex-wrap:wrap;
  > * {
    flex: 1 0 auto;
    margin:0.5rem;
  }
`;
const MealTabButton = styled.button`
  padding: 0.5rem 0.5rem;
  font-size: 0.95rem;
  border: 1px #fff solid;
  border-radius: 25px;
  &.inactive {
    background: transparent;
    color: ${COLORS.FONT};
  }
  &.active {
    
    color: white;
    border: 1px ${COLORS.BLUE} solid;
    border-radius: 25px;
  }
  
`;
const MealTabContent = styled.div`
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${COLORS.BROWN};
  border-radius: 25px 25px 0px 0px;
  min-height:100vh;
  width: 100%;
`;
const MealTabContentWhite = styled.div`
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  border-radius: 25px 25px 0px 0px;
  width: 100%;
`;
const Meals = styled.div`
  width: 100%;
  display:flex;
  justify-content:center;
  align-items:flex-end;
  flex-wrap:wrap;
  > * {
    margin:1rem;
  }
`;
const NewFood = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  > * {
    margin: 0.25rem 0;
  }
  .buttons {
    width: 100%;
    display: flex;
    justify-content: space-between;
    > * {
      width: 49%;
    }
  }
`;

const SearchBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 1rem;
`;

const DaystartCalenderSection = styled.div`
  width: 100%;
  padding: 1rem;
`;

const SectionTitle = styled.div`
  color: ${COLORS.BLUE};
  tex-align: left;
  margin:0 0 1rem 0;
`;

const FavSectionTitle = styled.div`
  color: #fff;
  text-align: center;
  margin-bottom: 10px;
`;



const QSec = styled.div`
  display: flex;
  justify-content: space-between;
`;
const KKNoLinkButtonSmallWhite = styled(KKNoLinkButton)`
  padding: 0.25rem 0.5rem !important;
  width: auto;
  min-width: 0px;
  margin: 0;
  text-transform: none;
  color: #fff;
`;

const MealSection = styled.div`
  width: 100%;
  padding: 0 1rem;;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  position: relative;
`;
const MInput = styled.div`
  border-top: 1px solid ${COLORS.GREY_LIGHT};
  border-bottom: 1px solid ${COLORS.GREY_LIGHT};
  margin: 0;
  width: 100%;
  position: relative;
  padding: 1rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const CloseMinputButton = styled(KKNoLinkButton)`
  padding: 1rem;
  width: auto;
  min-width: 0px;
  position: absolute !important;
  right: -1rem;
  top: 0.25rem;
  box-shadow: none;
  border: none;
  margin: 0;
  background: transparent !important;
  z-index: 9;
`;
const MInputSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  label {
    font-style: italic;
    display: inline-block;
  }
  width: 100%;
`;



const Intro = styled.div`
  padding: 1rem;
  h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  text-align:center;
  
`;


const FavouriteSection = styled.div`
  padding: 2rem 0 2rem 0;
  background: ${COLORS.BLUE};
  color: #fff;
  border-radius: 30px 30px 30px 30px;
  margin-top: 25px;
  padding-left: 15px;
  padding-top: 35px;
  
`;
*/
