
import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { set, get } from 'lodash';
import { produce } from 'immer';
import { Icon } from 'semantic-ui-react';
import { COLORS } from '../../constants';

const getAmountClassAdditor = amount => {
  let additor = 1;
  const am = parseInt(amount, 10);
  if (am >= 20 && am < 100) {
    additor = 5;
  }
  if (am >= 100 && am < 250) {
    additor = 25;
  }
  if (am >= 250) {
    additor = 50;
  }
  return additor;
};

const Comp = (props) => {
  const { food, onFoodChange, action } = props;
  const currentAmount = useMemo(() => parseInt(
    get(food, 'definition.portion_amount_selected', 0),
    10,
  ) ||
    parseInt(get(food, 'definition.portion_amount', 1), 10), [food]);
  ;
  const unit = useMemo(() => get(food, 'definition.portion_unit', ''), [food]);
  const changeAmount = useCallback((dir) => {

    switch (dir) {
      case 'up':
        onFoodChange(produce(food, (draftFood) =>
          set(draftFood, 'definition.portion_amount_selected', currentAmount + getAmountClassAdditor(currentAmount))
        ));
        break;
      case 'down':
        if (currentAmount > 1) {
          onFoodChange(produce(food, (draftFood) =>
            set(draftFood, 'definition.portion_amount_selected', currentAmount - getAmountClassAdditor(currentAmount))
          ));
        }
        break;
      default:
    }
  }, [food, onFoodChange, currentAmount]);
  const datauri = useMemo(() => get(food, 'datauri'), [food])
  return <CompS>
    {datauri ? <img alt="" src={datauri} /> :
      <div className="foodtitle">
        {get(food, 'i18n.de.definition.title', get(food, 'definition.title', get(food, 'definition.title', '')))}
      </div>
    }
    {!datauri && <div className="foodamount">
      <button onClick={() => changeAmount('down')}>
        <Icon
          name="minus circle"
          style={{ color: COLORS.ORANGE, fontSize: '1.8rem' }}
        />
      </button>
      <div className="amount">{currentAmount}{unit && <span> {unit}</span>}</div>
      <button onClick={() => changeAmount('up')}>
        <Icon
          name="plus circle"
          style={{ color: COLORS.BLUE, fontSize: '1.8rem' }}
        />
      </button>
    </div>}
    {action && <button onClick={() => { action() }} className="save">
      {food.isedit ? <span>Speichern</span> : <span>Hinzufügen</span>}
    </button>}
  </CompS>
};

export default Comp;

const CompS = styled.div`
  border: 1px solid ${COLORS.BLUE};
  background:white;
  padding: 1rem;
  border-radius:1rem;
  display:flex;
  flex-direction:column;
  justify-content:flex-start;
  align-items:center;
  button.save{
    border-radius: 2rem;
    border:none;
    color: white;
    font-size: 1rem;
    padding: 0.7rem 1rem;
    text-align: center;
    font-weight: bold;
    margin: 1rem 0 0 0;
    background:${COLORS.ORANGE};
  }
  .foodtitle{
    color: ${COLORS.BLUE};
    font-size:1.5rem;
    margin-bottom:2rem;
  }
  .foodamount{
    display:flex;
    justify-content:center;
    gap:2rem;
    align-items:center;
    button{
      border:none;
      background:transparent;
    }
    .amount{
      font-size:1.5rem;
      color: ${COLORS.BLUE};
      span{
        color: ${COLORS.ORANGE};
      }
    }
  }
`;
