import styled from "styled-components";
import { Form } from 'semantic-ui-react';

const LoginForm = styled(Form)`
  padding: 2rem 0 0 0;
  width: 90%;
  max-width: 320px;
  a,
  button {
    width: 100%;
    margin: 0.5rem 0;
  }
  > *{
    width:100%;
  }
`;
export default LoginForm;