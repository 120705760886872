import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { TitleBar, Section, InfoBox } from "../common";
import { useLocalStorage } from "usehooks-ts";
import { Button, Icon, Input } from "semantic-ui-react";
import { COLORS } from "../../constants";
import { KKButton } from "../Buttons";
import useNavigate from "../../useNavigate";
import UvidaConnection from "./appconnect";
import useUvidaLocationFinder from "./useUvidaLocationFinder";
import useGeolocation from "./useGeolocation";

const Comp = (props) => {
  const [infoHidden, setInfoHidden] = useLocalStorage(
    "nutrick24_uvida_infobox_hidden",
    false
  );
  const [infoUvidaaccountHidden, setInfoUvidaaccountHidden] = useLocalStorage(
    "nutrick24_uvidaaccount_infobox_hidden",
    false
  );
  const [infoSearchHidden, setInfoSearchHidden] = useLocalStorage(
    "nutrick24_uvida_infobox_search_hidden",
    false
  );
  const [plz, setPlz] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");

  const goto = useNavigate();

  const location = useGeolocation();

  const { found, loadingUvida } = useUvidaLocationFinder({
    latitude,
    longitude,
    radius: 10,
    plz,
  });

  useEffect(() => {
    if (location) {
      if (!location.error) {
        setLatitude(location.coordinates.lat);
        setLongitude(location.coordinates.lon);
      }
    }
  }, [location]);

  return (
    <CompS>
      <TitleBar label="Stoffwechselanalyse" />
      <Section id="infoblock1" className={infoHidden && "hidden"}>
        {infoHidden ? (
          <Icon
            name="info circle"
            onClick={() => {
              setInfoHidden(false);
            }}
          />
        ) : (
          <InfoBox
            dismiss={() => {
              setInfoHidden(true);
            }}
            mode="dark"
          >
            Mit den Stoffwechselanalysen von uVida können wir Deinen Körper
            besser verstehen. Durch den exakt gemessenen Grundumsatz, können
            nachhaltige Ernährungspläne erstellt und Ziele langfristig erreicht
            werden.
          </InfoBox>
        )}
      </Section>
      <Section id="appconnect">
        <h2>uVida Konto</h2>
        {!infoUvidaaccountHidden && (
          <InfoBox
            mode="inline light"
            dismiss={() => {
              setInfoUvidaaccountHidden(true);
            }}
          >
            Verbinden Sie Ihr uVida-Konto mit nutrick24, damit wir Ihre
            Vermessungsdaten abrufen können.
          </InfoBox>
        )}
        <UvidaConnection />
      </Section>
      <Section id="infoblocksearch">
        <h2>
          Standorte zur Stoffwechselvermessung{" "}
          <div>
            {infoSearchHidden && (
              <Icon
                name="info circle"
                onClick={() => {
                  setInfoSearchHidden(false);
                }}
              />
            )}
          </div>
        </h2>
        {!infoSearchHidden && (
          <InfoBox
            mode="inline light"
            dismiss={() => {
              setInfoSearchHidden(true);
            }}
          >
            Suche über eine Postleitzahl, die aktuelle Position oder eine Karte.
          </InfoBox>
        )}
        <div className="locationbuttons">
          <div id="plzsearch">
            <Input
              value={plz}
              onChange={(e, d) => {
                setPlz(d.value);
              }}
              action={
                <Button
                  loading={loadingUvida}
                  basic
                  floating
                  icon="remove"
                  onClick={() => {
                    setPlz("");
                  }}
                />
              }
              icon={<Icon name="search" />}
              iconPosition="left"
              placeholder="Postleitzahl"
            />
          </div>

          <KKButton
            color={COLORS.ORANGE}
            onClick={() => {
              goto("/uvidamap");
            }}
          >
            <Icon name="map"></Icon>
          </KKButton>
        </div>
        {found.length > 0 && (
          <div className="foundlocations">
            {found.map((location, index) => {
              const { id } = location;
              return <UvidaLocation key={id} location={location} />;
            })}
          </div>
        )}
      </Section>
    </CompS>
  );
};

export default Comp;

const UvidaLocation = (props) => {
  const { location } = props;
  const { city, email, homepage, name, phone, street, postal_code } = location;
  const [open, setOpen] = useState(false);

  return (
    <UvidaLocationS>
      <div
        className="titlebar"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <div>
          <h3>
            <span>{city}</span>
          </h3>{" "}
          <h3> {name}</h3>
        </div>
        <Icon name={open ? "chevron up" : "chevron down"} />
      </div>
      <div className={`details ${open ? "open" : "closed"}`}>
        <div>
          <h4>Adresse</h4>
          <p>
            {street}
            <br />
            {postal_code} {city}
          </p>
        </div>
        <div>
          <h4>Kontaktdaten</h4>
          <p>
            Tel.: <a href={`tel:${phone}`}>{phone}</a>
            <br />
            EMail: <a href={`mailto:${email}`}>{email}</a>
          </p>
        </div>
        <div>
          <h4>Homepage</h4>
          <p>
            <a
              rel="noreferrer"
              target="_blank"
              href={`https://${homepage.replace("https://", "")}`}
            >
              {homepage}
            </a>
          </p>
        </div>
      </div>
    </UvidaLocationS>
  );
};

const UvidaLocationS = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.BLUE};
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  color: white;
  .titlebar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    gap: 1rem;
    h3 {
      font-size: 1rem;
      margin: 0;
      font-weight: normal;

      span {
        font-weight: bold;
      }
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    a {
      color: white !important;
      text-decoration: underline;
    }
    h4 {
      font-size: 1rem;
      margin: 0;
    }

    &.open {
      opacity: 1;
      padding: 1rem 0 0 0;
      max-height: 1000px;
      transition: all 0.5s ease-in-out;
    }
    &.closed {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      padding: 0;
      transition: all 0.5s ease-in-out;
    }
  }
`;

const CompS = styled.div`
  h2 {
    margin: 0;
  }
  .foundlocations {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0;
  }
  .locationbuttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    flex-wrap: wrap;
    > * {
      flex: 1 1 10%;
    }
    #plzsearch {
      .ui.button.basic {
        background: white !important;
        border-top-right-radius: 2rem !important;
        border-bottom-right-radius: 2rem !important;
        box-shadow: none !important;
        border-right: 1px solid ${COLORS.BLUE};
        border-top: 1px solid ${COLORS.BLUE};
        border-bottom: 1px solid ${COLORS.BLUE};
      }
    }
    .ui.input {
      input {
        line-height: 1.3rem;
        background: white !important;
        border-top-left-radius: 2rem !important;
        border-bottom-left-radius: 2rem !important;
        box-shadow: none !important;
        border: none;
        width: 40%;
        border-left: 1px solid ${COLORS.BLUE};
        border-top: 1px solid ${COLORS.BLUE};
        border-bottom: 1px solid ${COLORS.BLUE};
      }
    }
  }
  #infoblock1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    &.hidden {
      margin-top: -1rem;
    }
    i {
      cursor: pointer;
      font-size: 2rem;
      line-height: 2rem;
      box-shadow: none !important;
      border: none !important;
      color: ${COLORS.ORANGE};
      &.remove {
        font-size: 1rem;
        color: white;
      }
      &.info {
        margin-top: 1rem;
        font-size: 1.3rem;
      }
    }
  }
  #infoblocksearch {
    h2 {
      display: flex;
      justify-content: space-between;
      gap: 0.5rem;
    }
  }
`;
