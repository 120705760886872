import React, { useCallback } from "react";
import styled from 'styled-components';
import { Button } from 'semantic-ui-react';
import {  useLocation } from 'react-router-dom';
import useNavigate from '../../useNavigate';
import { COLORS } from '../../constants';

const TitleBar = (props) => {
  const { label, backroute } = props
  const navigate = useNavigate();
  const location = useLocation();

  const goBack = useCallback(() => {
    if (backroute) {
      navigate(backroute)
      return
    }
    if (location.state?.from) {
      navigate(location.state.from);
    } else {
      window.history.back();
    }
  }, [location, navigate, backroute]);

  return <Comp>
    <NavButton circular icon="arrow left" onClick={goBack} />
    {label && <h1>{label}</h1>}
    <NavButton circular icon="bars" onClick={() => { navigate("/") }} />
  </Comp>
};

export default TitleBar;

const Comp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  h1{
    margin:0;
    font-size:1.5rem;
    line-height:1.5rem;
    color: ${COLORS.BLUE};
  }
`;
const NavButton = styled(Button)`
  border: 1px solid ${COLORS.BLUE}!important;
  background: transparent !important;
  color: ${COLORS.BLUE}!important;
`;