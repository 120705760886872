import { atom } from "recoil";
export const errorState = atom({
  key: "errorState",
  default: null,
});
export const cognitoUserState = atom({
  key: "cognitoUserState",
  default: null,
});
export const botIdState = atom({
  key: "botIdState",
  default: "https://storage.googleapis.com/landbot.pro/v3/H-1474476-3T6DSL3GLYH0XVQ5/index.json",
});
// mit leistungen:
// "https://storage.googleapis.com/landbot.site/v3/H-1272997-X0S5VF82IM731T6Y/index.json"
export const praxisVersionState = atom({
  key: "praxisVersionState",
  default: false,
});
export const reloadAccountCaseState = atom({
  key: "reloadAccountCaseState",
  default: null,
});
export const accountState = atom({
  key: "accountState",
  default: null,
});
export const foodsState = atom({
  key: "foodsState",
  default: null,
});
export const foodsAdditionalState = atom({
  key: "foodsAdditionalState",
  default: {},
});
export const currentChecklistState = atom({
  key: "currentChecklistState",
  default: null,
});
export const activeMealState = atom({
  key: "activeMealState",
  default: "",
});
export const reloadfoodfavoritesState = atom({
  key: "reloadfoodfavoritesState",
  default: null,
});
export const foodfavoritesState = atom({
  key: "foodfavoritesState",
  default: [],
});
export const tokenState = atom({
  key: "tokenState",
  default: "",
});
export const mesHourState = atom({
  key: "mesHourState",
  default: "",
});
export const mesMinuteState = atom({
  key: "mesMinuteState",
  default: "",
});
export const newMealDataState = atom({
  key: "newMealDataState",
  default: "",
});
export const newBloodpressureDataState = atom({
  key: "newBloodpressureDataState",
  default: "",
});
export const currentBloodpressureDataState = atom({
  key: "currentBloodpressureDataState",
  default: "",
});
export const newWeightDataState = atom({
  key: "newWeightDataState",
  default: "",
});
export const currentWeightDataState = atom({
  key: "currentWeightDataState",
  default: "",
});
export const newBloodsugarDataState = atom({
  key: "newBloodsugarDataState",
  default: "",
});
export const currentBloodsugarDataState = atom({
  key: "currentBloodsugarDataState",
  default: "",
});
export const newSymptomDataState = atom({
  key: "newSymptomDataState",
  default: "",
});
export const currentSymptomDataState = atom({
  key: "currentSymptomDataState",
  default: "",
});
export const searchToggleState = atom({
  key: "searchToggleState",
  default: 0,
});
export const caseState = atom({
  key: "caseState",
  default: null,
});
export const courseRouteState = atom({
  key: "courseRouteState",
  default: null,
});
export const contentPrivacyState = atom({
  key: "contentPrivacyState",
  default: {},
});
export const contentLabelsEatState = atom({
  key: "contentLabelsEatState",
  default: {},
});
export const contentLabelsHelpState = atom({
  key: "contentLabelsHelpState",
  default: {},
});
export const contentLabelsReceipesState = atom({
  key: "contentLabelsReceipesState",
  default: {},
});
