import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import {
  TitleBar,
  Datepicker,
  Section,
  TimePicker,
  ReelSelector,
} from "../common";
import { useRecoilValue } from "recoil";
import { cognitoUserState } from "../../atoms";
import { Button } from "semantic-ui-react";
import { AppUtils, htmlDecode, buttonLabel } from "../../utils";
import { COLORS } from "../../constants";
import { KKButton } from "../Buttons";
import prot_image from "../../images/Protokoll_Bewegubgsprotokoll.svg";
import { get, set, sortBy, padStart } from "lodash";
import { produce } from "immer";

const Comp = (props) => {
  const { day } = useParams();
  const [currentChecklist, setCurrentchecklist] = useState();
  const [sportdata, setSportdata] = useState({});
  const cognitoUser = useRecoilValue(cognitoUserState);
  const [mesHour, setMesHour] = useState("");
  const [mesMinute, setMesMinute] = useState("");
  const [durHour, setDurHour] = useState("");
  const [durMinute, setDurMinute] = useState("");
  const [mesUpdated, setMesUpdated] = useState(false);
  const [durUpdated, setDurUpdated] = useState(false);
  const [typeIndex, setTypeIndex] = useState(0);
  const [currentKsport, setCurrentKsport] = useState();
  const [currentAsport, setCurrentAsport] = useState();

  useEffect(() => {
    AppUtils.getS3Data(`user/${cognitoUser.sub}/sport.json`).then((result) => {
      setSportdata(result);
    });
  }, [cognitoUser]);

  useEffect(() => {
    if (sportdata) {
      const d = get(sportdata, `checklists.d${day}`);
      if (d) {
        setCurrentchecklist(d);
      } else {
        setCurrentchecklist({ id: `d${day}`, done: false });
      }
    }
    setMesHour("");
    setMesMinute("");
    setDurHour("");
    setDurMinute("");
    setMesUpdated(false);
    setDurUpdated(false);
    setCurrentKsport();
    setCurrentAsport();
  }, [
    day,
    sportdata,
    setMesHour,
    setMesMinute,
    setDurHour,
    setDurMinute,
    setMesUpdated,
    setCurrentKsport,
    setCurrentAsport,
  ]);

  const addSport = useCallback(() => {
    const cl = produce(currentChecklist, (draft) => {
      draft.measurements = draft.measurements || [];
      draft.measurements.push({
        updatedAt: Date.now(),
        etime: mesHour + mesMinute,
        duration: durHour + durMinute,
        sport: currentAsport || currentKsport,
        type: currentAsport ? "Ausdauersport" : "Kraftsport",
      });
    });
    setCurrentchecklist(cl);
    const spd = produce(sportdata, (draft) => {
      set(draft, `checklists.${cl.id}`, cl);
    });
    setSportdata(spd);
    AppUtils.putS3Data(`user/${cognitoUser.sub}/sport.json`, spd);
    setCurrentAsport();
    setCurrentKsport();
  }, [
    cognitoUser,
    sportdata,
    currentChecklist,
    setCurrentchecklist,
    currentAsport,
    setCurrentAsport,
    currentKsport,
    setCurrentKsport,
    mesHour,
    mesMinute,
    durHour,
    durMinute,
  ]);

  const removeItem = useCallback(
    (updatedAt) => {
      const cl = produce(currentChecklist, (draft) => {
        draft.measurements = (draft.measurements || []).filter(
          (m) => m.updatedAt !== updatedAt
        );
      });
      setCurrentchecklist(cl);
      const spd = produce(sportdata, (draft) => {
        set(draft, `checklists.${cl.id}`, cl);
      });
      setSportdata(spd);
      AppUtils.putS3Data(`user/${cognitoUser.sub}/sport.json`, spd);
    },
    [cognitoUser, sportdata, currentChecklist]
  );

  const sports = useMemo(
    () => sortBy(get(currentChecklist, "measurements", []) || [], ["etime"]),
    [currentChecklist]
  );
  const timeRef = useRef();
  const durRef = useRef();
  const typeRef = useRef();

  return (
    <div>
      <TitleBar label="Bewegungstagebuch" />
      <CompS>
        <Section>
          <Datepicker day={day} baseroute="/protocols/sport" />
        </Section>
        <Section>
          <ProtIntro className="info">
            <p>
              Für optimale Ziele werden 3x pro Woche 1-1,5h Ausdauersport
              empfohlen!
            </p>
          </ProtIntro>
          {sports && sports.length > 0 && (
            <div className="sportslist">
              <table>
                <thead>
                  <tr>
                    <th className="left">Uhrzeit</th>
                    <th className="left">Art</th>
                    <th className="right">Dauer</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {sports.map((m, idx) => {
                    return (
                      <tr key={idx}>
                        <td className="left">
                          {get(m, "etime").substring(0, 2)}:
                          {get(m, "etime").substring(2)}
                        </td>
                        <td className="left">{get(m, "sport")}</td>
                        <td className="right">
                          {get(m, "duration", "0000").substring(0, 2)}:
                          {get(m, "duration", "0000").substring(2, 4)}
                        </td>
                        <td className="right">
                          <Button
                            size="mini"
                            circular
                            color="white"
                            icon="remove"
                            onClick={() => {
                              removeItem(m.updatedAt);
                            }}
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          )}
        </Section>
        <Section ref={timeRef}>
          <h2 className="proto-headline">
            {htmlDecode(buttonLabel("Sport_Headline_Time", "de"))}
          </h2>
          <TimePicker
            nolabel
            hour={mesHour}
            minute={mesMinute}
            callback={(h, m) => {
              setMesHour(padStart(h, 2, "0"));
              setMesMinute(padStart(m, 2, "0"));
              if (mesHour !== h || mesMinute !== m) {
                setMesUpdated(true);
              }
            }}
          />
        </Section>
        {mesUpdated && (
          <Section ref={durRef}>
            <h2 className="proto-headline">
              {htmlDecode(buttonLabel("Sport_Headline_Duration", "de"))}
            </h2>
            <TimePicker
              nolabel
              hour={durHour}
              minute={durMinute}
              callback={(h, m) => {
                setDurHour(padStart(h, 2, "0"));
                setDurMinute(padStart(m, 2, "0"));
                if (durHour !== h || durMinute !== m) {
                  setDurUpdated(true);
                }
              }}
              hoursRangeTo="9"
              labelHours="Stunden"
            />
          </Section>
        )}
        {mesUpdated && durUpdated && (
          <>
            <Section ref={typeRef}>
              <div className="typeToggle">
                <button
                  className={typeIndex === 0 ? "active" : "inactive"}
                  onClick={() => {
                    typeIndex === 0 ? setTypeIndex(1) : setTypeIndex(0);
                  }}
                >
                  {buttonLabel("Sport_Headline_Kraftsport", "de")}
                </button>
                <button
                  className={typeIndex === 1 ? "active" : "inactive"}
                  onClick={() => {
                    typeIndex === 1 ? setTypeIndex(0) : setTypeIndex(1);
                  }}
                >
                  {buttonLabel("Sport_Headline_Ausdauer", "de")}
                </button>
              </div>
            </Section>
            <Section>
              <div className="typeToggleContent">
                {typeIndex === 0 && (
                  <ReelSelector
                    visibleItems={5}
                    name="hh"
                    selectedItem={currentKsport}
                    items={ksports}
                    onChange={(evt) => {
                      setCurrentKsport(evt);
                    }}
                  />
                )}
                {typeIndex === 1 && (
                  <ReelSelector
                    visibleItems={5}
                    name="hh"
                    selectedItem={currentAsport}
                    items={asports}
                    onChange={(evt) => {
                      setCurrentAsport(evt);
                    }}
                  />
                )}
              </div>
            </Section>
            {(currentAsport || currentKsport) && (
              <section>
                <KKButton
                  color={COLORS.BLUE}
                  onClick={() => {
                    addSport();
                  }}
                >
                  Speichern
                </KKButton>
              </section>
            )}
          </>
        )}
      </CompS>
    </div>
  );
};
export default Comp;

const ksports = [
  "Calisthenics",
  "EMS",
  "Fitnesstraining",
  "Pilates",
  "Pole-Dance",
  "Power-Plate",
  "Turnsport",
  "Yoga",
];
const asports = [
  "Aerobic",
  "Badminton",
  "Basketballspielen",
  "Bergwandern",
  "Bowlen",
  "Fußballspielen",
  "Gehen",
  "Golfspielen",
  "Joggen",
  "Inline-Skaten",
  "Kampfsport",
  "Kanufahren",
  "Klettern",
  "Kraftsport",
  "Nordic Walking",
  "Pilates",
  "Radfahren",
  "Reiten",
  "Rudern",
  "Schlittschuhlaufen",
  "Schwimmen",
  "Seilspringen",
  "Skateboardfahren",
  "Skifahren",
  "Snowboarden",
  "Tanzen",
  "Tennis",
  "Tischtennis",
  "Turnen",
  "Volleyballspielen",
  "Yoga",
  "Zumba",
];

const CompS = styled.div`
  img {
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding: 1rem 0;
  min-height: 100vh;

  .sportslist {
    background: ${COLORS.BLUE};
    color: white;
    border-radius: 1rem;
    padding: 0.5rem;
    margin-top: 2rem;
    table {
      width: 100%;
      th,
      td {
        &.left {
          text-align: left;
        }
        &.right {
          text-align: right;
        }
      }
    }
  }

  .typeToggleContent {
    > * {
      width: 100%;
    }
    border-radius: 1rem;
    background: white;
    border: 1px solid ${COLORS.BLUE};
  }
  .typeToggle {
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    button {
      width: 50%;
      cursor: pointer;
      padding: 0.5rem 1rem;
      color: white;
      border-radius: 1rem;
      background: ${COLORS.ORANGE};
      border: none;
      &.active {
        background: ${COLORS.BLUE};
      }
    }
  }
`;
const ProtIntro = styled.div`
  background: url(${prot_image}) left bottom no-repeat;
  padding: 1rem 1rem 1rem 30%;
  h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  background-color: #d2d6e2;
  color: ${COLORS.BLUE};
  font-size: 1.2rem;
  border-radius: 1rem;
`;
/*
import React from 'react';
import styled from 'styled-components';
import { AppContext } from '../../containers/App/context';
import { htmlDecode } from '../../utils/AppUtils';
import { COLORS } from '../../constants';
import TitleBar from '../TitleBar';
import KKLinkButton from '../KKLinkButton';
import { CalButtonNew, DatePickerComp } from '../Calendar';
import PropTypes from 'prop-types';
import moment from 'moment';
import TimePicker from '../TimePicker';
import { Icon, Form, Button } from 'semantic-ui-react';
import { KKButton } from '../components';
import { fromJS, Map, List } from 'immutable';
import { Auth } from 'aws-amplify';
import AppUtils from '../../utils/AppUtils';
import prot_image from '../../images/Protokoll_Bewegubgsprotokoll.svg';

const initCase = {};

export default class Sport extends React.Component {
  static contextType = AppContext;

  static propTypes = {
    day: PropTypes.string,
  };

  static defaultProps = {
    day: '20220101',
  };

  state = {
    datepickerDayOpen: false,
    changedData: false,
    userdata: new Map(),
    accountdata: new Map(),
    sportdata: new Map(),
    currentChecklist: new Map(),
    saving: false,
    error: null,
    mesHour: '',
    mesMinute: '',
    mesWeight: '',
    ausdauerSport: 'Joggen',
    kraftSport: 'Fitnesstraining',
    mesDuration: '',
    durHour: '',
    durMinute: '',
  };

  loadData = () => {
    window.scrollTo(0, 0);
    this.setState({ loading: true });
    Auth.currentAuthenticatedUser().then(user => {
      const account = user.attributes.sub;
      this.setState({ account });
      Promise.all([
        new Promise(rs => {
          AppUtils.getS3Data(`user/${account}/sport.json`)
            .then(result => {
              this.setState({ sportdata: fromJS(result) }, rs);
            })
            .catch(e => {
              console.error(e);
              if (e.status === 404) {
                this.setState({ sportdata: fromJS(initCase) }, rs);

              }
              rs();
            });
        }),
      ])
        .then(this.setCurrentChecklist)
        .then(() => {
          this.setState({ loading: false, changedData: false });
        })
        .catch(e => this.setState({ error: e }));
    });
  };

  setCurrentChecklist = () =>
    new Promise(rs => {
      const selectedDay = this.props.match.params.day;
      const temp = this.state.sportdata;
      if (this.state.sportdata.getIn(['checklists', `d${selectedDay}`], false)) {
        this.setState(
          {
            currentChecklist: this.state.sportdata.getIn(
              ['checklists', `d${selectedDay}`],
              new Map(),
            ),
          },
          rs,
        );
      } else {
        this.setState(
          {
            currentChecklist: fromJS({
              id: `d${selectedDay}`,
              done: false,
            }),
          },
          rs,
        );
      }
    });

  componentDidMount() {
    const { day } = this.props.match.params;
    this.setState({ day });
    this.loadData();
  }

  componentWillReceiveProps(props) {
    const { day } = props.match.params;
    if (day !== this.state.day) {
      this.setState({ day }, () => {
        this.loadData();
      });
    }
  }

  toggleCalender = () => {
    this.setState({ datepickerDayOpen: !this.state.datepickerDayOpen });
  };

  changeDayfromCalender = d => {
    this.setState({
      datepickerDayOpen: false,
    });
    this.props.history.push(`/sport/${moment(d).format('YYYYMMDD')}`);
  };

  setError = e => {
    this.setState({ error: e });
  };

  save = () => {
    this.setState(
      {
        saving: true,
      },
      () => {
        const c = this.state.currentChecklist;
        Auth.currentAuthenticatedUser()
          .then(user => {
            const account = user.attributes.sub;
            if (account) {
              AppUtils.putS3Data(
                `user/${account}/sport.json`,
                this.state.sportdata
                  .toJS(),
              )
                .then(() => {
                  this.setState({ changedData: false, saving: false });
                  this.loadData();
                })
                .catch(e => {
                  console.error(e);
                  this.setError(e);
                });
            } else {
              this.setError(
                `Benutzer-Account konnte nicht synchronisiert werden. Möglicherweise besteht aktuell keine Internetverbindung. ${JSON.stringify(
                  user,
                )}`,
              );
            }
          })
          .catch(e => {
            console.error(e);
            this.setError(e);
          });
      },
    );
  };


  saveKraftSport = () => {
    const mes = { updatedAt: Date.now(), etime: this.state.mesHour + this.state.mesMinute, duration: this.state.durHour + this.state.durMinute, sport: this.state.kraftSport, type: 'Kraftsport' };
    let c = this.state.currentChecklist.get('measurements', new List()).push(mes);
    let checkl = this.state.currentChecklist;
    checkl = checkl.set("measurements", c);

    let geht = this.state.sportdata.setIn(["checklists", this.state.currentChecklist.get('id')], checkl);

    this.setState({ sportdata: geht });
    this.save();

  };

  saveAusdauerSport = () => {
    const mes = { updatedAt: Date.now(), etime: this.state.mesHour + this.state.mesMinute, duration: this.state.durHour + this.state.durMinute, sport: this.state.ausdauerSport, type: 'Ausdauersport' };
    let c = this.state.currentChecklist.get('measurements', new List()).push(mes);
    let checkl = this.state.currentChecklist;
    checkl = checkl.set("measurements", c);

    let geht = this.state.sportdata.setIn(["checklists", this.state.currentChecklist.get('id')], checkl);

    this.setState({ sportdata: geht });
    this.save();

  };
  removeItem = (updated) => {
    let c = this.state.currentChecklist.get('measurements', new List()).filter(it => it.get('updatedAt') != updated);
    let checkl = this.state.currentChecklist;
    checkl = checkl.set("measurements", c);

    let geht = this.state.sportdata.setIn(["checklists", this.state.currentChecklist.get('id')], checkl);

    this.setState({ sportdata: geht });
    this.save();
  }

  updateHourValue(val) {
    this.setState({
      mesHour: val.length == 1 ? '0' + val : val
    });
  }

  updateMinuteValue(val) {
    this.setState({
      mesMinute: val.length == 1 ? '0' + val : val
    });
  }

  updateHourDuration(val) {
    this.setState({
      durHour: val.length == 1 ? '0' + val : val
    });
  }

  updateMinuteDuration(val) {
    this.setState({
      durMinute: val.length == 1 ? '0' + val : val
    });
  }

  updateAusdauerValue(evt) {
    const val = evt.target.value;
    this.setState({
      ausdauerSport: val
    });
  }

  updateKraftValue(evt) {
    const val = evt.target.value;
    this.setState({
      kraftSport: val
    });
  }

  updateDurationValue(evt) {
    const val = evt.target.value;
    this.setState({
      mesDuration: val
    });
  }

  nextDay = () => {
    this.props.history.push(`/sport/${moment(this.state.day).add(1, "days").format('YYYYMMDD')}`);
  }

  lastDay = () => {
    this.props.history.push(`/sport/${moment(this.state.day).subtract(1, "days").format('YYYYMMDD')}`);
  }

  render() {

    const currentDay = moment(this.props.day, 'YYYYMMDD');
    const { day } = this.props.match.params;

    let sports = new List();
    sports = this.state.currentChecklist.get(
      `measurements`,
      new List(),
    );
    sports = sports.sortBy(m => m.get('etime', '0000'));

    return (
      <Comp>
        <TitleBar
          label="Bewegungsprotokoll"
          history={this.props.history}
          color={COLORS.BROWN}
          tcolor={COLORS.BLUE}
        />
        <Intro>
          <CalWrapper>
            <button onClick={this.lastDay}>
              <Icon name="angle left" />
            </button>
            <CalButtonNew day={day} onClick={this.toggleCalender} />
            <button onClick={this.nextDay}>
              <Icon name="angle right" />
            </button>
          </CalWrapper>
          {this.state.datepickerDayOpen ? (

            <DatePickerComp
              currentDay={moment(day, 'YYYYMMDD')}
              changeDayfromCalender={this.changeDayfromCalender}
            />
          ) : null}

        </Intro>

        <ProtIntro>
          <p>Für optimale Ziele werden 3x pro Woche 1-1,5h Ausdauersport empfohlen!</p>
        </ProtIntro>

        {sports.size > 0 &&
          <Section>
            <ProtEntries>
              <table>
                <tr>
                  <th>Uhrzeit</th>
                  <th>Art</th>
                  <th>Dauer</th>
                </tr>
                {sports.map((m, idx) => {
                  return (
                    <tr key={idx}>
                      <td>{m.get("etime").substring(0, 2)}:{m.get("etime").substring(2)}</td>
                      <td>{m.get("sport")}</td>
                      <td>{m.get("duration", '0000').substring(0, 2)}:{m.get("duration", '0000').substring(2, 4)}</td>
                      <td><Button basic size="mini" circular color="red" icon="remove" onClick={() => { this.removeItem(m.get("updatedAt")) }} /></td>
                    </tr>
                  );
                })}
              </table>
            </ProtEntries>
          </Section>
        }
        <Section>
          <h2 className="proto-headline">
            {htmlDecode(this.context.getIn(['labels', 'Sport_Headline_Time', 'de'], ''))}
          </h2>
          <TimePicker nolabel hour={this.state.mesHour} minute={this.state.mesMinute} callback={(h, m) => {
            this.updateHourValue(h);
            this.updateMinuteValue(m)
          }} />
          {(this.state.mesHour && this.state.mesMinute) && <React.Fragment>
            <h2 className="proto-headline">
              {htmlDecode(this.context.getIn(['labels', 'Sport_Headline_Duration', 'de'], ''))}
            </h2>
            <TimePicker nolabel hour={this.state.durHour} minute={this.state.durMinute} callback={(h, m) => {
              this.updateHourDuration(h);
              this.updateMinuteDuration(m)
            }} hoursRangeTo="9" labelHours="Stunden" nolabel="true" />
            {(this.state.durHour && this.state.durMinute) && <React.Fragment>
              <Form>
                <h2 className="proto-headline">
                  {htmlDecode(this.context.getIn(['labels', 'Sport_Headline_Kraftsport', 'de'], ''))}
                </h2>
                <SelectInput onChange={evt => this.updateKraftValue(evt)} className="input-style">
                  'Calisthenics',
                  'EMS',
                  'Fitnesstraining (Freihantel, Gerätetraining)',
                  'Pilates',
                  'Pole-Dance',
                  'Power-Plate',
                  'Turnsport',
                  'Yoga',
                </SelectInput>
              </Form>
              <KKButton color={COLORS.ORANGE} onClick={this.saveKraftSport} className="fullWidth">
                {htmlDecode(this.context.getIn(['labels', 'Add', 'de'], ''))}
              </KKButton>
              <Question>
                <Form>
                  <h2 className="proto-headline">
                    {htmlDecode(this.context.getIn(['labels', 'Sport_Headline_Ausdauer', 'de'], ''))}
                  </h2>
                  <SelectInput onChange={evt => this.updateAusdauerValue(evt)} className="input-style">
                    'Aerobic',
                    'Badminton',
                    'Basketballspielen',
                    'Bergwandern',
                    'Bowlen',
                    'Fußballspielen',
                    'Gehen',
                    'Golfspielen',
                    'Joggen',
                    'Inline-Skaten',
                    'Kampfsport',
                    'Kanufahren',
                    'Klettern',
                    'Kraftsport',
                    'Nordic Walking',
                    'Pilates',
                    'Radfahren',
                    'Reiten',
                    'Rudern',
                    'Schlittschuhlaufen',
                    'Schwimmen',
                    'Seilspringen',
                    'Skateboardfahren',
                    'Skifahren',
                    'Snowboarden',
                    'Tanzen',
                    'Tennis',
                    'Tischtennis',
                    'Turnen',
                    'Volleyballspielen',
                    'Yoga',
                    'Zumba',
                  </SelectInput>
                </Form>
              </Question>
              <KKButton color={COLORS.ORANGE} onClick={this.saveAusdauerSport} className="fullWidth">
                {htmlDecode(this.context.getIn(['labels', 'Add', 'de'], ''))}
              </KKButton>
            </React.Fragment>}

          </React.Fragment>}

        </Section>




      </Comp>
    );
  }
}

const ProtEntries = styled.div`
  background: #D2D6E2;
  color: ${COLORS.BLUE};
  border-radius: 25px;
  padding: 1rem;
  font-size: 16px;
  table {
    width: 100%;
  }
  tr {
    border-bottom: 1px solid ${COLORS.BLUE};
  }
  td, th {
    padding:0.25rem 0;
    text-align: left;
  }
`

const NumberInputStyled = styled.input`
  color: ${COLORS.BLUE};
  font-weight: bold;
`;
const NumberInput = props => <NumberInputStyled {...props} type="tel" />;
const SelectInput = styled.select`
  border-radius: 35px;
`;

const Float = styled.div`
  display: inline;
`

const Section = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  img {
    z-index: 0;
    max-height: 30vh;
  }
  h1 {
    color: ${COLORS.GREY_DARK}!important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: left !important;
    width: 100%;
  }
  h2 {
    color: ${COLORS.BLUE}!important;
    font-weight: bold;
    text-transform: none;
    font-size: 18px;
    line-height: 1.5rem;
    text-align: left !important;
    width: 100%;
  }
`;

const Question = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: left;
  h2 {
    span {
      color: ${COLORS.RED};
    }
    margin-bottom: 0;
  }
`;

const Comp = styled.div`
  img {
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding-bottom: 50px;
  min-height: 650px;
`;

const Intro = styled.div`
  padding: 1rem;
  h2 {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
  text-align:center;
  
`;
*/
