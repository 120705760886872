import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { TitleBar, Datepicker, Section, FoldableInfo } from "../common";
import { useRecoilValue, useRecoilState } from "recoil";
import { AppUtils } from "../../utils";
import { COLORS } from "../../constants";
import { get, set, times } from "lodash";
import { produce } from "immer";
import { getTodayPlan } from "./utils";
import {
  cognitoUserState,
  caseState,
} from "../../atoms";
import { qty } from "./../../kk_qte_spec";
import {
  IconFruitFilled,
  IconFruit,
  IconVeggie,
  IconVeggieFilled,
  IconHoney,
  IconHoneyFilled,
  IconDrink,
  IconDrinkFilled,
  IconCoffee,
  IconCoffeeFilled,
} from "../../icons";

const tabcolors = [
  "#efede7",
  "#fcfaf4",
  "#f9f6ea",
  "#f2ebd2",
  "#efede7",
  "#fcfaf4",
  "#f9f6ea",
  "#f2ebd2",
  "#efede7",
  "#fcfaf4",
  "#f9f6ea",
  "#f2ebd2",
  "#efede7",
  "#fcfaf4",
  "#f9f6ea",
  "#f2ebd2",
];

const Comp = (props) => {
  const { day } = useParams();
  const [currentChecklist, setCurrentchecklist] = useState();
  const [currentSectionIndex, setCurrentSectionIndex] = useState(0);
  const [todayPlan, setTodayPlan] = useState();
  const [caseN, setCaseN] = useRecoilState(caseState);
  const cognitoUser = useRecoilValue(cognitoUserState);

  const foodlistUpdate = (subID, foods) => {
    const cl = produce(currentChecklist, (draft) => {
      set(
        draft,
        "plan.data.definition.sections",
        get(currentChecklist, "plan.data.definition.sections").map(
          (section, index) =>
            produce(section, (draftSection) => {
              draftSection.subsections = get(section, "subsections", []).map(
                (subsection) =>
                  produce(subsection, (draftSubsection) => {
                    if (get(subsection, "key") === subID) {
                      set(draftSubsection, "foodlist", foods);
                    }
                  })
              );
            })
        )
      );
    });
    setCurrentchecklist(cl);
    save(cl);
  };

  useEffect(() => {
    if (caseN) {
      const d = get(caseN, `checklists.d${day}`);
      if (d) {
        setCurrentchecklist(d);
        setTodayPlan(d.plan);
      } else {
        const tp = getTodayPlan(day, caseN, "reduction");
        setCurrentchecklist({
          id: `d${day}`,
          done: false,
          plan: tp,
        });
        setTodayPlan(tp);
      }
    }
  }, [caseN, day]);

  const intro = useMemo(
    () => get(todayPlan, "data.i18n.de.definition.intro"),
    [todayPlan]
  );

  const save = useCallback(
    (cl) => {
      const pc = produce(caseN || {}, (draft) => {
        set(draft, `checklists.${cl.id}`, cl);
      });
      setCaseN(pc);
      AppUtils.putS3Data(`user/${cognitoUser.sub}/case.json`, pc);
    },
    [cognitoUser, caseN, setCaseN]
  );

  const sections = useMemo(
    () => get(todayPlan, "data.definition.sections", []) || [],
    [todayPlan]
  );

  return (
    <>
      <TitleBar label="Gewichtsreduktion" />
      <CompS>
        <Section>
          <Datepicker day={day} baseroute="/reduction" />
        </Section>
        {intro && (
          <Section>
            <FoldableInfo
              title="Ihr persönlicher Plan"
              sid="reduktion_info"
              initialOpen={true}
            >
              <div dangerouslySetInnerHTML={{ __html: intro }}></div>
            </FoldableInfo>
          </Section>
        )}
        {sections.map((section, index) => (
          <SectionTab
            key={get(section, "key", index) + day}
            className={`${index === currentSectionIndex
              ? "active"
              : index < sections.length - 1
                ? "inactive"
                : "active"
              } `}
            layer={index}
            background={tabcolors[index]}
          >
            <div
              className="header"
              onClick={() => {
                setCurrentSectionIndex(
                  index === currentSectionIndex ? null : index
                );
              }}
            >
              <h3>{get(section, "title", "")}</h3>
            </div>
            <div className="content">
              {get(section, "subsections", []).map((subsection, idxs) => {
                if (
                  (get(subsection, "foodlist", []) || []).length > 0 ||
                  get(subsection, "content", "")
                ) {
                  return (
                    <div className="subsection" key={idxs + day}>
                      {get(subsection, "title", "") &&
                        get(subsection, "title", "") !==
                        get(section, "title", "") && (
                          <h4
                            className={`color_${get(subsection, "color", "")}`}
                          >
                            {get(subsection, "title", "")}
                          </h4>
                        )}
                      {get(subsection, "content", "") && (
                        <div
                          className="subcontenttxt"
                          dangerouslySetInnerHTML={{
                            __html: get(subsection, "content", ""),
                          }}
                        ></div>
                      )}
                      {(get(subsection, "foodlist", []) || []).length > 0 && (
                        <React.Fragment>
                          {get(subsection, "foodlist_show", "") ===
                            "readonly" ? (
                            <div className="readonlyFoodlist">
                              <ul>
                                {get(subsection, "foodlist", []).map(
                                  (food, idx) => (
                                    <li key={get(food, "food_id", idx)}>
                                      {get(food, "name", "")}
                                    </li>
                                  )
                                )}
                              </ul>
                            </div>
                          ) : (
                            <div className="clickableFoodlist">
                              <FoodListClickable
                                foods={get(subsection, "foodlist", [])}
                                subID={get(subsection, "key", "")}
                                update={foodlistUpdate}
                              />
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                  );
                }
                else {
                  return <></>
                }
              })}
            </div>
          </SectionTab>
        ))}
      </CompS>
    </>
  );
};
export default Comp;

const FoodListClickable = (props) => {
  const { foods = [], subID, update } = props;
  const [foodsState, setFoodsState] = useState([]);

  foods.map((f) => {
    const fn = {};
    for (const [key, value] of Object.entries(f)) {
      fn[key] = value;
    }
    fn.amount4 = parseFloat(get(f, "amount", 1)) / 4;
    fn.selected = f.selected || 0;
    return f;
  });
  const [countSelected, setCountSelected] = useState(0);
  const setFoodSelected = (foodIndex, value) => {
    const changedFoods = produce(foodsState, (draftState) => {
      draftState[foodIndex].selected = draftState[foodIndex].selected + value;
      if (draftState[foodIndex].selected < 0) {
        draftState[foodIndex].selected = 0;
      }
    });
    setFoodsState(changedFoods);
    update(subID, changedFoods);
  };
  useEffect(() => {
    let count = 0;
    foodsState.forEach((food) => {
      count += food.selected;
    });
    setCountSelected(count);
  }, [foodsState]);
  useEffect(() => {
    setFoodsState(
      foods.map((f) =>
        produce(f, (draft) => {
          draft.amount4 = Number(
            (
              parseFloat(get(f, "amount", 1)) /
              parseFloat(get(f, "symbolcount", 4))
            ).toFixed(2)
          );
          draft.selected = draft.selected || 0;
        })
      )
    );
  }, [foods]);

  return (
    <div>
      {foodsState.map((food, idx) => (
        <div key={get(food, "key", idx)}>
          {(countSelected < parseFloat(get(food, "symbolcount", 4)) ||
            food.selected > 0) && (
              <FoodElement key={get(food, "key", idx)}>
                <h4>
                  {idx > 0 && <span className="small">oder &nbsp;</span>}
                  <span className="light">
                    {get(food, "amount", 1)}{" "}
                    {get(qty, `${food.unit}.i18n.de.definition.title`, "")}
                  </span>{" "}
                  {get(food, "name", "")}{" "}
                </h4>
                <div className="buttons">
                  {times(food.selected, String).map((no, idxno) => {
                    return (
                      <FoodButton
                        key={get(food, "key", idx) + no + idxno}
                        icon={get(food, "symbol")}
                        className="sel"
                        onClick={() => {
                          setFoodSelected(idx, -1);
                        }}
                      >
                        {" "}
                        <div>
                          {get(food, "amount4", 1)}{" "}
                          {get(qty, `${food.unit}.i18n.de.definition.title`, "")}
                        </div>
                      </FoodButton>
                    );
                  })}
                  {times(
                    parseFloat(get(food, "symbolcount", 4)) - countSelected,
                    String
                  ).map((no, idxno) => {
                    return (
                      <FoodButton
                        key={get(food, "key", idx) + no + idxno}
                        icon={get(food, "symbol")}
                        className="notsel"
                        onClick={() => {
                          setFoodSelected(idx, +1);
                        }}
                      >
                        <div>
                          {get(food, "amount4", 1)}{" "}
                          {get(qty, `${food.unit}.i18n.de.definition.title`, "")}
                        </div>
                      </FoodButton>
                    );
                  })}
                </div>
              </FoodElement>
            )}
        </div>
      ))}
    </div>
  );
};

const FoodElement = styled.div`
  h4 {
    margin: 1rem 0;
    .small {
      font-weight: normal;
      font-size: 0.9rem;
    }
    .light {
      font-weight: normal;
    }
  }
  &.hidden {
    display: none;
  }
  .buttons {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 1rem;
  }
  div {
    line-height: 1.1em;
  }
`;
const FoodButton = (props) => {
  const { icon, className, onClick, children } = props;
  return (
    <FoodButtonS
      className={`${className} ${icon ? "icon" : "noicon"}`}
      onClick={onClick}
    >
      {icon && (
        <React.Fragment>
          {icon === "water" && (
            <React.Fragment>
              {className.includes("notsel") ? (
                <IconDrink frontColor={COLORS.ORANGE} size="4rem" />
              ) : (
                <IconDrinkFilled frontColor={COLORS.ORANGE} size="4rem" />
              )}
            </React.Fragment>
          )}
          {icon === "coffee" && (
            <React.Fragment>
              {className.includes("notsel") ? (
                <IconCoffee frontColor={COLORS.ORANGE} size="4rem" />
              ) : (
                <IconCoffeeFilled frontColor={COLORS.ORANGE} size="4rem" />
              )}
            </React.Fragment>
          )}
          {icon === "fruits" && (
            <React.Fragment>
              {className.includes("notsel") ? (
                <IconFruit frontColor={COLORS.ORANGE} size="4rem" />
              ) : (
                <IconFruitFilled frontColor={COLORS.ORANGE} size="4rem" />
              )}
            </React.Fragment>
          )}
          {icon === "honey" && (
            <React.Fragment>
              {className.includes("notsel") ? (
                <IconHoney frontColor={COLORS.ORANGE} size="4rem" />
              ) : (
                <IconHoneyFilled frontColor={COLORS.ORANGE} size="4rem" />
              )}
            </React.Fragment>
          )}
          {icon === "veggies" && (
            <React.Fragment>
              {className.includes("notsel") ? (
                <IconVeggie frontColor={COLORS.ORANGE} size="4rem" />
              ) : (
                <IconVeggieFilled frontColor={COLORS.ORANGE} size="4rem" />
              )}
            </React.Fragment>
          )}
        </React.Fragment>
      )}
      {children}
    </FoodButtonS>
  );
};
const FoodButtonS = styled.div`
  &.noicon {
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3rem;
    height: 3rem;
    flex: 0 0 auto;
    border: 1px solid ${COLORS.ORANGE};
    padding: 0.5rem;
    background: white;
    color: ${COLORS.ORANGE};
    font-size: 1rem;
    text-align: center;
    flex-wrap: wrap;
    &.sel {
      color: white;
      background: ${COLORS.ORANGE};
    }
  }
  &.icon {
    font-size: 1rem;
    text-align: center;
  }

  cursor: pointer;

  &.hidden {
    display: none;
  }
`;

const SectionTab = styled.div`
  flex: 1 1 auto;
  width: 100%;
  padding: 0 1rem 4rem 1rem;
  background-color: ${(props) => props.background};
  border-top-left-radius: 1.5rem;
  border-top-right-radius: 1.5rem;
  z-index: ${(props) => props.layer};
  .subsection {
    padding: 0.5rem 0;
    margin: 0.5rem 0;
    border-bottom: 1px solid white;
  }
  .header {
    padding: 1rem 1rem;
    margin: 0 -1rem;
    cursor: pointer;
  }
  &:first-child {
    margin-top: 2rem;
  }
  &:last-child {
    min-height: 100vh;
  }
  &.inactive {
    height: 7rem;
    .content {
      display: none;
    }
  }
  h4 {
    margin-bottom: 1rem;
  }
  .color_ {
    color: ${COLORS.BLUE};
  }
  .color_red {
    color: red;
  }
  .color_yellow {
    color: orange;
  }
  .color_green {
    color: green;
  }
  .clickableFoodlist {
  }
`;

const CompS = styled.div`
  .typeToggle {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    button {
      width: 50%;
      cursor: pointer;
      padding: 0.5rem 1rem;
      color: white;
      border-radius: 1rem;
      background: ${COLORS.ORANGE};
      border: none;
      &.active {
        background: ${COLORS.BLUE};
      }
    }
  }
  img {
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  padding-bottom: 50px;
  min-height: 100vh;
`;
