import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import useNavigate from "../../useNavigate";
import { useRecoilValue, useRecoilState } from "recoil";
import { accountState, caseState } from "../../atoms";
import { get, set } from "lodash";
import PaypalPayment from "../../components/PaypalPayment";
import { InfoBox } from "../../components/common";
import { produce } from "immer";

const productInitial = {
  id: "reduktion_2023-001",
  name: "Individuelle Gewichtsreduktion",
  price: 344,
};

const Comp = () => {
  const account = useRecoilValue(accountState);
  const [pcase, setPcase] = useRecoilState(caseState);
  const [product, setProduct] = useState(productInitial);
  const [productSelected, setProductSelected] = useState(false);
  const goto = useNavigate();
  let paymentIndex = useMemo(
    () =>
      (get(account, "payments", []) || []).findIndex(
        (payment) => payment.productId === product.id
      ),
    [account, product]
  );

  const anamnesecomplete = useMemo(
    () => get(pcase, `anamnese.complete`, false),
    [pcase]
  );
  const testuser =
    (get(account, "email", "") || "").endsWith("nutrick24.de") ||
    (get(account, "email", "") || "").endsWith("test.de");

  return (
    <div>
      {((paymentIndex < 0 && !testuser) || (testuser && !productSelected)) && (
        <CompS>
          {account && (
            <>
              {/*
          // -----------------------
          // --------------> Payment
          // -----------------------
          */}
              <div>
                <p>
                  Wir bieten Ihnen eine detaillierte Analyse Ihrer persönlichen
                  Daten, darunter digitale Stoffwechselvermessungen (falls
                  vorhanden), Ihre Gesundheitsanamnese sowie Ihre aktuellen
                  Lebens- und Ernährungsgewohnheiten. Sie erhalten die
                  Ergebnisse auch als praktische PDF-Datei.
                </p>
                <p>
                  Im Anschluss erwartet Sie eine maßgeschneiderte
                  Reduktionsphase von 4-6 Wochen, in der Sie von einer
                  engmaschigen 1:1-Betreuung durch unsere Ärzte und
                  Ernährungswissenschaftler profitieren – ganz einfach via
                  Bot-Chat.
                </p>
                <p>
                  Darüber hinaus erhalten Sie digitale Pläne und Beratung für
                  eine anschließende Gewichtserhaltung. Dies beinhaltet ein
                  individuelles Foodtracking sowie unzählige Rezepte für
                  insgesamt 3 Monate.
                </p>
                <p>
                  Die Abrechnung kann nach der Gebührenordnung für Ärzte (GoÄ)
                  erfolgen oder Sie entscheiden sich für die
                  Selbstzahler-Option. Wählen Sie zwischen zwei attraktiven
                  Preisoptionen:
                </p>
                <ul>
                  <li
                    className={
                      product.id === "reduktion_2023-001_onetime"
                        ? "active"
                        : ""
                    }
                    onClick={() => {
                      setProduct(
                        produce(product, (draft) => {
                          draft.price = 344;
                          draft.id = "reduktion_2023-001_onetime";
                          draft.name =
                            "Individuelle Gewichtsreduktion - Einmalig";
                        })
                      );
                      setProductSelected(true);
                    }}
                  >
                    344,00 Euro einmalig für 4-6 Wochen, mit der Möglichkeit ein
                    Abo abzuschließen
                  </li>
                  {/*
              ABO MUSS NOCH GEKLÄRT UND GETESTET WERDEN

              <li className={product.id === "reduktion_2023-001_abo12m" ? 'active' : ''} onClick={() => {
                setProduct(produce(product, (draft) => {
                  draft.price = 220;
                  draft.id = "reduktion_2023-001_abo12m";
                  draft.name = "Individuelle Gewichtsreduktion - Abo 12M";
                }));
                setProductSelected(true);
              }}>220,00 Euro im Rahmen eines 12-monatigen Abonnements</li>
              */}
                </ul>
              </div>
              {productSelected && (
                <PaypalPayment
                  productId={product.id}
                  productName={product.name}
                  productPrice={product.price}
                />
              )}
            </>
          )}
        </CompS>
      )}
      {((paymentIndex >= 0 && !testuser) || (testuser && productSelected)) && (
        // --------------------------------
        // --------------> Anamnese
        // --------------------------------
        <>
          <div className="info">
            <InfoBox
              visible={
                !get(pcase, `course.${product.id}.msg_welcome.dismissed`, false)
              }
              dismiss={() => {
                setPcase(
                  produce(pcase, (draft) => {
                    set(
                      draft,
                      `course.${product.id}.msg_welcome.dismissed`,
                      true
                    );
                  })
                );
              }}
            >
              Vielen Dank, dass Sie `{product.name}` gebucht haben.
            </InfoBox>
            {!anamnesecomplete && (
              <InfoBox visible>
                Bitte füllen Sie im ersten Schritt den Anamnese-Fragebogen aus.
                Anschließend erstellen wir einen individuellen Plan für Sie.
              </InfoBox>
            )}
          </div>
          <ul>
            <li
              className={anamnesecomplete ? "inactive" : "active"}
              onClick={() => {
                !anamnesecomplete && goto(`/anamnese/${product.id}`);
              }}
            >
              <div>
                <Icon name="doctor" />
                Anamnese
              </div>{" "}
              {get(pcase, `course.${product.id}.anamnese.complete`, false) && (
                <Icon color="green" name="check" />
              )}
            </li>
          </ul>
          {anamnesecomplete && (
            <div>
              Wir danken Ihnen für Ihr Vertrauen und werden uns in den nächsten
              2-3 Werktage per Mail mit Ihnen in Verbindung setzen und uns mit
              Ihnen zur weiteren Planung verabreden.
            </div>
          )}
        </>
      )}
    </div>
  );
};
export default Comp;

const CompS = styled.div`
  padding: 0 1rem;
  p {
    margin: 0 0 1rem 0;
  }
  li {
    &.active {
      background: rgba(255, 255, 255, 0.3);
    }
  }
  video {
    max-width: 100%;
  }
  .info {
    margin: 0;
  }
  #disclaimer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    i {
      margin-right: 1rem;
    }
    i,
    label {
      cursor: pointer;
    }
  }
`;
