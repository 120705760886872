import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { TitleBar, Datepicker, Section, FoldableInfo } from "../../common";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";

import {
  cognitoUserState,
  currentChecklistState,
  newBloodpressureDataState,
  currentBloodpressureDataState,
} from "../../../atoms";
import { Icon } from "semantic-ui-react";
import { AppUtils } from "../../../utils";
import { COLORS } from "../../../constants";
import { KKButton } from "../../Buttons";
import { get, set } from "lodash";
import { produce } from "immer";
import Assist from "./Assist";
import { CompS } from "../common";

const Comp = (props) => {
  const { day } = useParams();
  const [currentChecklist, setCurrentchecklist] = useRecoilState(
    currentChecklistState
  );
  const [assist, setAssist] = useState(false);
  const cognitoUser = useRecoilValue(cognitoUserState);
  const setNewBloodpressureData = useSetRecoilState(newBloodpressureDataState);
  const [currentData, setCurrentData] = useRecoilState(
    currentBloodpressureDataState
  );
  const [now, setNow] = useState(Date.now());

  useEffect(() => {
    AppUtils.getS3Data(`user/${cognitoUser.sub}/bloodpressure.json`).then(
      (result) => {
        setCurrentData(result);
      }
    );
  }, [cognitoUser, setCurrentData]);

  useEffect(() => {
    if (currentData) {
      const d = get(currentData, `checklists.d${day}`);
      if (d) {
        setCurrentchecklist(d);
      } else {
        setCurrentchecklist({ id: `d${day}`, done: false });
      }
    }
  }, [currentData, setCurrentchecklist, day]);

  const save = useCallback(
    (cl) => {
      const pc = produce(currentData || {}, (draft) => {
        set(draft, `checklists.${cl.id}`, cl);
      });
      setCurrentData(pc);
      AppUtils.putS3Data(`user/${cognitoUser.sub}/bloodpressure.json`, pc);
    },

    [cognitoUser, currentData, setCurrentData]
  );

  const removeEntry = useCallback(
    (id) => {
      const cl = produce(currentChecklist, (draft) => {
        draft.measurements = (draft.measurements || []).filter(
          (f) => f && f.id !== id
        );
      });
      setCurrentchecklist(cl);
      save(cl);
    },
    [currentChecklist, save, setCurrentchecklist]
  );

  const mes = useMemo(
    () => get(currentChecklist, "measurements", []) || [],
    [currentChecklist]
  );

  return (
    <div>
      {assist ? (
        <Assist
          now={now}
          onClose={() => {
            setAssist(false);
          }}
        />
      ) : (
        <>
          <TitleBar label="Blutdruck" />
          <CompS>
            <Section>
              <Datepicker day={day} baseroute="/protocols/bloodpressure" />
            </Section>
            <Section>
              <FoldableInfo
                title="Hinweise"
                sid="bloodpressure_info"
                initialOpen={false}
              >
                <p>
                  Bitte messen sie täglich Ihren Blutdruck unter konstanten
                  Messbedingungen (z.B. jeden morgen, nüchtern, nach dem
                  Aufstehen, in Ruhe). Bei der Systole handelt es sich um den
                  größeren, bei der Diastole um den kleineren Wert.
                </p>
              </FoldableInfo>
            </Section>
            <Section>
              <KKButton
                color={COLORS.ORANGE}
                onClick={() => {
                  setNewBloodpressureData({ isnew: true });
                  setAssist(true);
                  setNow(Date.now());
                }}
              >
                Messung hinzufügen
              </KKButton>
            </Section>
            {mes.length > 0 && (
              <Section>
                <h2>Messungen</h2>
                <div className="mlist">
                  <table>
                    <thead>
                      <tr>
                        <th className="right">Systole</th>
                        <th className="right">Diastole</th>
                        <th className="right">Puls</th>
                        <th className="right">Uhrzeit</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {mes.map((m) => (
                        <tr key={m.id}>
                          <td className="right">{get(m, "systole")}</td>
                          <td className="right">{get(m, "diastole")}</td>
                          <td className="right">{get(m, "puls")}</td>
                          <td className="right">
                            {get(m, "etime", "0000").substring(0, 2)}:
                            {get(m, "etime", "0000").substring(2)}
                          </td>
                          <td className="right icons">
                            <Icon
                              name="edit outline"
                              onClick={() => {
                                setNewBloodpressureData(
                                  produce(m, (d) => {
                                    d.isedit = true;
                                    d.isnew = false;
                                  })
                                );
                                setAssist(true);
                              }}
                            />
                            <Icon
                              name="minus circle"
                              onClick={() => {
                                removeEntry(m.id);
                              }}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </Section>
            )}
          </CompS>
        </>
      )}
    </div>
  );
};
export default Comp;
