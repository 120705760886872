import React, { useMemo } from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import useNavigate from "../../useNavigate";
import { useRecoilValue, useRecoilState } from "recoil";
import { accountState, caseState } from "../../atoms";
import { get, set } from "lodash";
import PaypalPayment from "../../components/PaypalPayment";
import { InfoBox } from "../../components/common";
import Appointment from "../../components/Appointment";
import { AppUtils, validToday } from "../../utils";
import { produce } from "immer";

const product = {
  id: "initcons",
  name: "Ernährungsmedizinisches Erstgespräch",
  price: 150,
};

const Comp = () => {
  const account = useRecoilValue(accountState);
  const [pcase, setPcase] = useRecoilState(caseState);
  const goto = useNavigate();
  const linktoday = useMemo(() => AppUtils.getToday(), []);

  const hasMaintenanceCurrent = useMemo(() => {
    let ok = false;
    (get(pcase, "plans", []) || []).forEach((pl) => {
      if (
        validToday(get(pl, "from", "99999999"), get(pl, "to", "00000000")) &&
        get(pl, "data.definition.type", "") === "maintenance"
      ) {
        ok = true;
      }
    });
    return ok;
  }, [pcase]);

  let paymentIndex = useMemo(
    () =>
      (get(account, "payments", []) || []).findIndex(
        (payment) => payment.productId === product.id
      ),
    [account]
  );
  const anamnesecomplete = useMemo(
    () => get(pcase, `course.${product.id}.anamnese.complete`, false),
    [pcase]
  );

  return (
    <div>
      {paymentIndex < 0 ? (
        <CompS>
          {account && (
            // -----------------------
            // --------------> Payment
            // -----------------------
            <>
              <div>
                <p>
                  Im Rahmen einer bequemen Online-Sprechstunde, die etwa 25-30
                  Minuten dauert, erhalten Sie ein professionelles ärztliches
                  Erstgespräch. Wir werten ausführlich Ihre persönlichen Daten
                  aus - etwa vorhandene digitale Stoffwechselvermessungen, Ihre
                  Gesundheitsvorgeschichte und Ihre aktuellen Lebens- und
                  Ernährungsgewohnheiten. Sie bekommen die Ergebnisse auch in
                  Form einer PDF-Datei zur Verfügung gestellt.
                </p>
                <p>
                  Unser Expertenteam erstellt auf Basis dieser Informationen
                  individuelle Therapie- und Ernährungspläne, die optimal auf
                  Ihre Bedürfnisse abgestimmt sind. Zusätzlich profitieren Sie
                  von einer 2-wöchigen ernährungswissenschaftlichen Begleitung
                  via Chat, um eventuelle Fragen zu klären und weitere Tipps zu
                  erhalten.
                </p>
                <p>
                  Dieses Angebot ist besonders empfehlenswert für Personen, die
                  ihre Ernährung optimieren und gesundheitliche Probleme angehen
                  möchten.
                </p>
                <p>
                  Die Abrechnung kann nach der Gebührenordnung für Ärzte (GoÄ)
                  erfolgen oder Sie entscheiden sich für die
                  Selbstzahler-Option. Zögern Sie nicht und buchen Sie jetzt Ihr
                  Ernährungsmedizinisches Erstgespräch!
                </p>
                <p>Preis: 150€</p>
                <p>Kein Abonnement</p>
              </div>
              <PaypalPayment
                productId={product.id}
                productName={product.name}
                productPrice={product.price}
              />
            </>
          )}
        </CompS>
      ) : (
        // --------------------------------
        // -------------> Anamnese & Jameda
        // --------------------------------
        <div>
          <div className="info">
            <InfoBox
              visible={
                !get(pcase, `course.${product.id}.msg_welcome.dismissed`, false)
              }
              dismiss={() => {
                setPcase(
                  produce(pcase, (draft) => {
                    set(
                      draft,
                      `course.${product.id}.msg_welcome.dismissed`,
                      true
                    );
                  })
                );
              }}
            >
              Vielen Dank, dass Sie ein Ernährungsmedizinisches Erstgespräch
              gebucht haben.
            </InfoBox>
            {!anamnesecomplete && (
              <InfoBox visible>
                Bitte füllen Sie im ersten Schritt den Anamnese-Fragebogen aus.
                Anschließend können Sie einen Termin wählen.
              </InfoBox>
            )}
          </div>
          <ul>
            <li
              className={anamnesecomplete ? "inactive" : "active"}
              onClick={() => {
                !anamnesecomplete && goto(`/anamnese/${product.id}`);
              }}
            >
              <div>
                <Icon name="doctor" />
                Anamnese
              </div>{" "}
              {get(pcase, `course.${product.id}.anamnese.complete`, false) && (
                <Icon color="green" name="check" />
              )}
            </li>
          </ul>
          {anamnesecomplete && !hasMaintenanceCurrent && (
            <>
              <InfoBox visible>
                Bitte buchen Sie einen Termin über Jameda:
              </InfoBox>
              <Appointment inline />
            </>
          )}
          {anamnesecomplete && hasMaintenanceCurrent && (
            <ul>
              <li
                onClick={() => {
                  goto(`/maintenance/${linktoday}`);
                }}
              >
                <div>Zu meinem Ernährungsplan</div>
              </li>
            </ul>
          )}
        </div>
      )}
    </div>
  );
};
export default Comp;

const CompS = styled.div`
  p {
    margin: 0 0 1rem 0;
  }
  padding: 0;
`;
