export const COLORS = {
  GREY_LIGHT: "rgb(226,225,225)",
  FONT: "#707070",
  GREEN: "rgb(11,100,84)",
  ORANGE: "#EE7203",
  BLUE_LIGHT: "#B4BED7",
  BLUE_LIGHTER: "#7586B0",
  BLUE: "#365194",
  YELLOW_LIGHT: "#F1EAD2",
  GREY: "rgb(138,137,137)",
  GREY_DARK: "rgb(69,68,68)",
  YELLOW: "rgb(242,191,17)",
  PETROL: "rgb(0,90,107)",
  RED: "rgb(133,29,55)",
  BROWN: "rgb(241,234,210)",

  INPUT_BACK: "rgb(138,137,137)",
  INPUT_FONT: "white",
};
export const paypalClientIds = {
  prod: "AUe2G9-9o1VvmM4bxYcniA6RRmhzWq2SZFOIghWUuQEE_6YCK5H4-8LC0K48GqmcTRUD0L9SSR_n_vdl",
  sandbox:
    "AfYCne-o82CWjkU3jdqt7urIAu0-FT5pAIcdV6pSIu21pLUNyiyHN-fwQlBbqtBs1lZS8CbKMSdN8rRU",
};
export const CONTENTS_BASE_URL =
  "https://kielkowskiweb.github.io/appcontents-api";

export const API = "https://api.nutrick24.de";
