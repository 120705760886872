import React from 'react';
import styled from 'styled-components';

const Error = (props) => {
  return <ErrorS>{props.children}</ErrorS>
}

export default Error;

const ErrorS = styled.div`
  padding:1rem 0;
  color:red;
`;