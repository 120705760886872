/* eslint-disable react/prop-types */
import React from "react";
import { COLORS } from "./constants";
/*
import iconep from "./icon_ernaehrungsplan.png";
import iconepr from "./icon_ernaehrungsplan_red.png";
import iconepg from "./icon_ernaehrungsplan_grey.png";
export const IconPlan = (props) => (
  <div
    className="icon"
    style={{
      width: props.size || "20px",
      height: props.size || "20px",
      background: `url(${iconep}) center center no-repeat`,
      backgroundSize: "contain",
    }}
  />
);
export const IconPlanGrey = (props) => (
  <div
    className="icon"
    style={{
      width: props.size || "20px",
      height: props.size || "20px",
      background: `url(${iconepg}) center center no-repeat`,
      backgroundSize: "contain",
    }}
  />
);
export const IconPlanRed = (props) => (
  <div
    className="icon"
    style={{
      width: props.size || "20px",
      height: props.size || "20px",
      background: `url(${iconepr}) center center no-repeat`,
      backgroundSize: "contain",
    }}
  />
);
*/
export const IconEat = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 151.45 104.18"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <circle
      style={{ fill: props.frontColor || COLORS.GREY }}
      cx="75.72"
      cy="52.09"
      r="52.09"
    />
    <circle
      style={{ fill: props.backColor || "white" }}
      cx="75.72"
      cy="52.09"
      r="34.59"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M234.79,350.51v.06a3.68,3.68,0,0,0-.57-.06H234c-2,0-3.6,1.95-3.6,4.33V437c0,2.38,1.62,4.32,3.6,4.32h.2c2,0,3.6-1.94,3.6-4.32V395.43c3-.77,5.12-2.27,5.12-4V355C242.94,352.74,239.4,350.88,234.79,350.51Z"
      transform="translate(-230.42 -343.82)"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M381.85,371.33V351.48a1.54,1.54,0,0,0-1.53-1.54h-.13a1.54,1.54,0,0,0-1.54,1.54v15.86a4.13,4.13,0,0,0-1.11-.15h-.2V351.48a1.54,1.54,0,0,0-1.53-1.54h-.13a1.54,1.54,0,0,0-1.54,1.54v15.71h-1.68V351.48a1.54,1.54,0,0,0-1.53-1.54h-.13a1.54,1.54,0,0,0-1.54,1.54v15.71h-.56a4.2,4.2,0,0,0-1.12.15V351.48a1.54,1.54,0,0,0-1.53-1.54h-.13a1.54,1.54,0,0,0-1.54,1.54v28.87a4.34,4.34,0,0,0,4.32,4.33h.72v52.88c0,2.38,1.62,4.33,3.6,4.33h.2c2,0,3.6-1.95,3.6-4.33V384.68h.72a4.34,4.34,0,0,0,4.32-4.33v-8.84A1.09,1.09,0,0,0,381.85,371.33Z"
      transform="translate(-230.42 -343.82)"
    />
  </svg>
);
export const IconHelp = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 117.17 106.2"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M60.29,96.73a43.29,43.29,0,1,1,43.29-43.29A43.3,43.3,0,0,1,60.29,96.73Zm1.13-72.15c-9.19,0-16.07,4-20.92,12A1.76,1.76,0,0,0,41,39l7.44,5.64a1.69,1.69,0,0,0,1.07.34,1.81,1.81,0,0,0,1.41-.68c2.65-3.38,3.77-4.39,4.84-5.18a9,9,0,0,1,4.85-1.36c3.61,0,6.93,2.32,6.93,4.8,0,2.93-1.52,4.39-5,6-4,1.8-9.47,6.48-9.47,12v2a1.78,1.78,0,0,0,1.81,1.8H65.7a1.78,1.78,0,0,0,1.8-1.8h0c0-1.3,1.64-4.06,4.29-5.58,4.28-2.42,10.14-5.69,10.14-14.26C81.93,32.3,71.11,24.58,61.42,24.58ZM67.5,69.67a1.78,1.78,0,0,0-1.8-1.8H54.88a1.78,1.78,0,0,0-1.81,1.8V80.5a1.78,1.78,0,0,0,1.81,1.8H65.7a1.78,1.78,0,0,0,1.8-1.8Z"
    />
  </svg>
);
export const IconFav = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 117.17 106.2"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M107.59,44.31L86.07,65.3l5.1,29.65c0.06,0.42,0.06,0.77,0.06,1.19c0,1.54-0.71,2.96-2.43,2.96
	c-0.83,0-1.66-0.3-2.37-0.71L59.8,84.4L33.17,98.39c-0.77,0.42-1.54,0.71-2.37,0.71c-1.72,0-2.49-1.42-2.49-2.96
	c0-0.42,0.06-0.77,0.12-1.19l5.1-29.65L11.95,44.31c-0.71-0.77-1.48-1.78-1.48-2.85c0-1.78,1.84-2.49,3.32-2.73l29.77-4.33
	L56.89,7.43C57.43,6.3,58.43,5,59.8,5s2.37,1.3,2.91,2.43l13.34,26.98l29.77,4.33c1.42,0.24,3.32,0.95,3.32,2.73
	C109.13,42.53,108.36,43.54,107.59,44.31z"
    />
  </svg>
);
export const IconCal = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 117.17 106.2"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M107.63,29.19A7.26,7.26,0,0,0,100.41,22H93.2V16.56a9,9,0,0,0-9-9H80.57a9,9,0,0,0-9,9V22H49.91V16.56a9,9,0,0,0-9-9H37.29a9,9,0,0,0-9,9V22H21.06a7.26,7.26,0,0,0-7.22,7.21v72.14a7.27,7.27,0,0,0,7.22,7.22h79.35a7.27,7.27,0,0,0,7.22-7.22ZM86,32.8a1.85,1.85,0,0,1-1.8,1.8H80.57a1.86,1.86,0,0,1-1.8-1.8V16.56a1.86,1.86,0,0,1,1.8-1.8h3.61a1.85,1.85,0,0,1,1.8,1.8Zm-43.28,0a1.86,1.86,0,0,1-1.8,1.8H37.29a1.86,1.86,0,0,1-1.81-1.8V16.56a1.86,1.86,0,0,1,1.81-1.8H40.9a1.86,1.86,0,0,1,1.8,1.8Z"
    />
  </svg>
);
export const IconAnamnese = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 102 109"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M75.17,44.88a2.28,2.28,0,0,0-.75-2,3,3,0,0,0-2-.74H28.21a2.29,2.29,0,0,0-2,.74,3,3,0,0,0-.75,2c0,1.77.92,2.66,2.77,2.66h44.2c1.84,0,2.76-.89,2.76-2.66M45.69,63.42a5.86,5.86,0,0,0,.31-2c0-1.77-.38-2.66-1.14-2.66H26.58c-.76,0-1.14.89-1.14,2.66a4.53,4.53,0,0,0,.3,2q.36.75.84.75H44.86c.35.07.63-.18.83-.75M46,77.84A4.19,4.19,0,0,0,45.69,76c-.24-.51-.51-.77-.84-.77H26.59a1.22,1.22,0,0,0-.84.77,4.57,4.57,0,0,0-.31,1.83c0,1.61.38,2.41,1.15,2.41H44.85c.76,0,1.15-.8,1.15-2.41M81.86,90.25A5.05,5.05,0,0,1,78,92H22.58a5.21,5.21,0,0,1-3.94-1.7,5.57,5.57,0,0,1-1.59-3.93V19.91A4.87,4.87,0,0,1,18.64,16a5.61,5.61,0,0,1,3.94-1.59h5.63v5.52a10.27,10.27,0,0,0,3.18,7.76A10.73,10.73,0,0,0,39.26,31h22.1a11.09,11.09,0,0,0,7.86-3.29,10.59,10.59,0,0,0,3.19-7.76V14.39H78A4.72,4.72,0,0,1,81.86,16a5.61,5.61,0,0,1,1.6,3.93V86.32a5.58,5.58,0,0,1-1.6,3.93m-15-75.86v5.52a5.57,5.57,0,0,1-1.59,3.93,5.16,5.16,0,0,1-3.93,1.7H39.26a5.36,5.36,0,0,1-3.93-1.7,5.37,5.37,0,0,1-1.71-3.93V14.39Zm-44.3-11A16.54,16.54,0,0,0,10.89,8.22,15.74,15.74,0,0,0,6,19.91V86.32a16.63,16.63,0,0,0,4.89,11.79A15.72,15.72,0,0,0,22.58,103H78A16.7,16.7,0,0,0,94.61,86.32V19.91A16.52,16.52,0,0,0,89.72,8.22,15.73,15.73,0,0,0,78,3.34Z"
    />
    <circle className="cls-3" cx="64.26" cy="71.55" r="16.22" />
    <text
      style={{ fontSize: "2.7rem", fill: props.backColor || COLORS.GREY }}
      transform="translate(52.5 84)"
    >
      +
    </text>
  </svg>
);
export const IconReceipes = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 120.9 155.8"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <g>
      <g>
        <rect
          x="20"
          y="15.9"
          style={{ fill: props.backColor || COLORS.GREY }}
          width="80.5"
          height="68.8"
        />
        <path
          style={{ fill: props.frontColor || COLORS.GREY }}
          d="M105.7,147.9H20.2c-3.7,0-6.7-3-6.7-6.7v-105c0-3.7,3-6.7,6.7-6.7h85.5c3.7,0,6.7,3,6.7,6.7v105
			C112.3,144.9,109.3,147.9,105.7,147.9z"
        />
        <path
          style={{ fill: props.frontColor || COLORS.GREY }}
          d="M103.9,20.7H17.4c-1.7,0-3.1-1.4-3.1-3.1v-3.2c0-1.7,1.4-3.1,3.1-3.1h86.4c1.7,0,3.1,1.4,3.1,3.1v3.2
			C107,19.3,105.6,20.7,103.9,20.7z"
        />
        <path
          style={{ fill: props.frontColor || COLORS.GREY }}
          d="M22.2,53.2h-4.4c-2.4,0-4.3-1.9-4.3-4.3V15.5c0-2.4,1.9-4.3,4.3-4.3h4.4c2.4,0,4.3,1.9,4.3,4.3v33.4
			C26.5,51.3,24.6,53.2,22.2,53.2z"
        />
        <path
          style={{ fill: props.frontColor || COLORS.GREY }}
          d="M102.7,53.2h-4.4c-2.4,0-4.3-1.9-4.3-4.3V15.5c0-2.4,1.9-4.3,4.3-4.3h4.4c2.4,0,4.3,1.9,4.3,4.3v33.4
			C107,51.3,105.1,53.2,102.7,53.2z"
        />
      </g>
      <g>
        <path
          style={{ fill: props.backColor || COLORS.GREY }}
          d="M82.8,81.8L93.6,71c0.5-0.5,0.5-1.2,0-1.7l-0.1-0.1c-0.5-0.5-1.2-0.5-1.7,0l-8.6,8.6
			c-0.1-0.2-0.3-0.5-0.5-0.7l-0.1-0.1l8.5-8.5c0.5-0.5,0.5-1.2,0-1.7l-0.1-0.1c-0.5-0.5-1.2-0.5-1.7,0l-8.5,8.5L80,74.5l8.5-8.5
			c0.5-0.5,0.5-1.2,0-1.7l-0.1-0.1c-0.5-0.5-1.2-0.5-1.7,0l-8.5,8.5L78,72.4c-0.2-0.2-0.4-0.4-0.7-0.5l8.6-8.6
			c0.5-0.5,0.5-1.2,0-1.7l-0.1-0.1c-0.5-0.5-1.2-0.5-1.7,0L73.3,72.4l-2.8,2.8l-2,2c-1.3,1.3-1.3,3.4,0,4.7l0.4,0.4L40.2,111
			c-1.3,1.3-1.5,3.2-0.4,4.3l0.1,0.1c1.1,1.1,3,0.9,4.3-0.4l28.7-28.7l0.4,0.4c1.3,1.3,3.4,1.3,4.7,0l4.8-4.8
			C82.8,81.9,82.8,81.8,82.8,81.8z"
        />
        <path
          style={{ fill: props.backColor || COLORS.GREY }}
          d="M53.7,71.1c-5.4-5.4-12.2-7.4-15.2-4.5c-3,3-0.9,9.7,4.5,15.2c4.1,4.1,9,6.3,12.4,5.8l27.6,27.6
			c1.3,1.3,3.2,1.5,4.3,0.4l0.1-0.1c1.1-1.1,0.9-3-0.4-4.3L59.5,83.6C60,80.2,57.9,75.3,53.7,71.1z"
        />
      </g>
    </g>
  </svg>
);
export const IconFruitFilled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M56.42,49.52c-0.02,0.62-0.52,1.11-1.14,1.13c-2.93,0.09-5.34,1.01-7.19,2.73c-2.66,2.5-4.1,6.69-4.27,12.46
		c-0.04,1.33-0.02,2.64,0.06,3.89c0.04,0.63-0.43,1.18-1.06,1.24h0c-0.66,0.06-1.24-0.43-1.29-1.09c-0.09-1.33-0.11-2.71-0.07-4.11
		c0.19-6.42,1.87-11.17,5.01-14.11c2.24-2.1,5.24-3.25,8.72-3.37C55.89,48.28,56.44,48.84,56.42,49.52 M101.2,63.98
		c-0.79-27.31-23.09-20.32-25.6-19c-2.51,1.32-5.14,1.65-6.99,1.65c-1.85,0-4.49-0.33-6.99-1.65c-2.51-1.32-24.8-8.31-25.6,19
		c-0.79,27.31,17.81,33.91,23.09,33.91c5.28,0,4.88-1.58,9.5-1.58c4.62,0,4.22,1.58,9.5,1.58S101.99,91.29,101.2,63.98"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M70.62,44.76c1.8-6.82,4.16-10.31,5.02-11.42c0.18-0.23,0.14-0.57-0.1-0.75c-2.15-1.67-5.38-2.3-6.66-2.49
		c-0.3-0.04-0.58,0.17-0.61,0.47c-0.69,6.21-1.61,10.78-2.38,13.83c-0.08,0.3,0.13,0.61,0.43,0.66c0.92,0.14,1.72,0.18,2.29,0.18
		c0.41,0,0.94-0.02,1.55-0.08C70.38,45.14,70.56,44.98,70.62,44.76"
    />
    <path
      style={{
        fill: props.frontColor || COLORS.GREY,
        stroke: props.frontColor || COLORS.GREY,
      }}
      d="M44.68,19.64c-0.25,0-0.46,0.17-0.52,0.41c-0.47,1.97-1.99,9.98,3.3,14.35c2.98,2.46,5.97,3.24,8.58,3.24
		c2.69,0,5-0.83,6.49-1.49c1.26-0.55,1.98-0.89,2.57-1c0.19-0.03,0.35-0.17,0.41-0.35c0.06-0.17,0.12-0.35,0.17-0.52
		c0.06-0.18,0.02-0.38-0.11-0.53c-0.57-0.64-1.1-1.5-1.51-2.17c-1.02-1.64-5.15-6.46-9.18-8.48c-3.85-1.93-6.81-3.16-10.15-3.47
		C44.71,19.64,44.69,19.64,44.68,19.64z M46.14,22.22c2.37,0.48,4.71,1.49,7.69,2.99c3.53,1.77,7.37,6.22,8.24,7.62l0.01,0.02
		c0.14,0.22,0.29,0.47,0.46,0.73c-0.19,0.08-0.4,0.18-0.62,0.27L61.58,34c-1.97,0.87-3.78,1.29-5.55,1.29
		c-2.53,0-4.91-0.91-7.08-2.7c-1.74-1.43-2.73-3.6-2.96-6.44C45.87,24.67,45.98,23.28,46.14,22.22"
    />
  </svg>
);
export const IconFruit = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{
        fill: "white",
        stroke: props.frontColor || COLORS.GREY,
        strokeWidth: "3",
        strokeMiterlimit: "10",
      }}
      d="M55.92,50.02c-0.02,0.62-0.52,1.11-1.14,1.13c-2.93,0.09-5.34,1.01-7.19,2.73c-2.66,2.5-4.1,6.69-4.27,12.46
		c-0.04,1.33-0.02,2.64,0.06,3.89c0.04,0.63-0.43,1.18-1.06,1.24h0c-0.66,0.06-1.24-0.43-1.29-1.09c-0.09-1.33-0.11-2.71-0.07-4.11
		c0.19-6.42,1.87-11.17,5.01-14.11c2.24-2.1,5.24-3.25,8.72-3.37C55.39,48.78,55.94,49.34,55.92,50.02z M100.7,64.48
		c-0.79-27.31-23.09-20.32-25.6-19c-2.51,1.32-5.14,1.65-6.99,1.65c-1.85,0-4.49-0.33-6.99-1.65c-2.51-1.32-24.8-8.31-25.6,19
		c-0.79,27.31,17.81,33.91,23.09,33.91c5.28,0,4.88-1.58,9.5-1.58c4.62,0,4.22,1.58,9.5,1.58S101.49,91.79,100.7,64.48z"
    />
    <path
      d="M70.12,45.26c1.8-6.82,4.16-10.31,5.02-11.42c0.18-0.23,0.14-0.57-0.1-0.75c-2.15-1.67-5.38-2.3-6.66-2.49
		c-0.3-0.04-0.58,0.17-0.61,0.47c-0.69,6.21-1.61,10.78-2.38,13.83c-0.08,0.3,0.13,0.61,0.43,0.66c0.92,0.14,1.72,0.18,2.29,0.18
		c0.41,0,0.94-0.02,1.55-0.08C69.88,45.64,70.06,45.48,70.12,45.26z"
    />
    <path
      d="M44.68,19.64c-0.25,0-0.46,0.17-0.52,0.41c-0.47,1.97-1.99,9.98,3.3,14.35c2.98,2.46,5.97,3.24,8.58,3.24
		c2.69,0,5-0.83,6.49-1.49c1.26-0.55,1.98-0.89,2.57-1c0.19-0.03,0.35-0.17,0.41-0.35c0.06-0.17,0.12-0.35,0.17-0.52
		c0.06-0.18,0.02-0.38-0.11-0.53c-0.57-0.64-1.1-1.5-1.51-2.17c-1.02-1.64-5.15-6.46-9.18-8.48c-3.85-1.93-6.81-3.16-10.15-3.47
		C44.71,19.64,44.69,19.64,44.68,19.64z M46.14,22.22c2.37,0.48,4.71,1.49,7.69,2.99c3.53,1.77,7.37,6.22,8.24,7.62l0.01,0.02
		c0.14,0.22,0.29,0.47,0.46,0.73c-0.19,0.08-0.4,0.18-0.62,0.27L61.58,34c-1.97,0.87-3.78,1.29-5.55,1.29
		c-2.53,0-4.91-0.91-7.08-2.7c-1.74-1.43-2.73-3.6-2.96-6.44C45.87,24.67,45.98,23.28,46.14,22.22"
    />
  </svg>
);
export const IconVeggie = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      d="M48.72,19.69c-3.14,0-3.57,3.39-3.59,5.12c-0.01,0.61-0.3,1.18-0.78,1.56c-3.44,2.68-1.29,5.86,0.03,7.29
		c0.37,0.41,0.9,0.63,1.45,0.63c0.09,0,0.18-0.01,0.27-0.02c0.52-0.07,1.03-0.1,1.53-0.1c4.83,0,8.75,3.06,11.45,6.1
		c0.41,0.46,0.94,0.67,1.45,0.67c1.07,0,2.1-0.88,1.97-2.16c-0.16-1.57-0.36-3.29-0.61-5.04c-0.04-0.28-0.08-0.54-0.12-0.81
		c-0.01-0.1-0.04-0.2-0.07-0.29c-1.25-4.13-3.48-8.23-7.39-10.75C51.86,20.29,50.05,19.69,48.72,19.69z M48.72,21.52
		c0.76,0,2.17,0.33,4.61,1.9c3.02,1.94,5.25,5.22,6.62,9.74c0,0.01,0,0.02,0.01,0.03c0.04,0.26,0.08,0.53,0.12,0.8
		c0.22,1.56,0.42,3.24,0.6,4.97c0.01,0.06-0.01,0.08-0.01,0.09c-0.03,0.03-0.09,0.06-0.13,0.06c-0.02,0-0.04,0-0.08-0.05
		c-3.91-4.39-8.34-6.71-12.82-6.71c-0.59,0-1.18,0.04-1.77,0.12c-0.01,0-0.02,0-0.03,0c-0.02,0-0.07,0-0.1-0.03
		c-0.95-1.05-1.46-2.1-1.39-2.9c0.02-0.22,0.07-0.88,1.14-1.71c0.93-0.73,1.48-1.82,1.49-2.99c0.01-0.98,0.17-1.85,0.45-2.44
		C47.71,21.76,48.06,21.52,48.72,21.52"
    />
    <path
      d="M87.65,19.69c-1.33,0-3.14,0.6-5.6,2.19c-3.91,2.51-6.13,6.62-7.39,10.75c-0.03,0.1-0.05,0.19-0.07,0.29
		c-0.04,0.26-0.08,0.53-0.12,0.81c-0.25,1.75-0.45,3.46-0.61,5.04c-0.13,1.28,0.9,2.16,1.97,2.16c0.52,0,1.04-0.2,1.45-0.67
		c2.7-3.04,6.62-6.1,11.45-6.1c0.5,0,1.01,0.03,1.53,0.1c0.09,0.01,0.18,0.02,0.27,0.02c0.55,0,1.08-0.22,1.45-0.63
		c1.31-1.44,3.47-4.62,0.03-7.29c-0.48-0.38-0.77-0.94-0.78-1.56C91.22,23.07,90.79,19.69,87.65,19.69z M87.65,19.69v1.83
		c0.66,0,1.01,0.23,1.31,0.86c0.28,0.59,0.44,1.46,0.45,2.44c0.01,1.17,0.55,2.26,1.49,2.99c1.07,0.83,1.12,1.49,1.14,1.71
		c0.07,0.8-0.44,1.86-1.4,2.9c-0.03,0.03-0.08,0.03-0.1,0.03c-0.01,0-0.02,0-0.03,0c-0.59-0.08-1.18-0.12-1.77-0.12
		c-4.48,0-8.91,2.32-12.82,6.71c-0.05,0.05-0.07,0.05-0.08,0.05c-0.04,0-0.1-0.02-0.13-0.06c-0.01-0.01-0.02-0.02-0.01-0.09
		c0.18-1.73,0.38-3.4,0.6-4.97c0.04-0.27,0.08-0.53,0.12-0.8c0-0.02,0.01-0.03,0.01-0.03c1.37-4.52,3.6-7.79,6.62-9.74
		c2.44-1.57,3.85-1.9,4.61-1.9V19.69z"
    />
    <path
      d="M68.19,2.53c-3.78,0-5.29,3.03-5.8,4.48c-0.15,0.43-0.43,0.82-0.83,1.05c-2.09,1.24-2.42,3.85-2.39,5.52
		c0.01,0.77,0.5,1.43,1.22,1.73c1.4,0.59,2.55,2.29,3.5,4.61c1.19,2.89,1.75,6.01,1.75,9.14v9.92c0,1.08,0.88,1.96,1.96,1.96h1.18
		c1.08,0,1.96-0.88,1.96-1.96v-9.92c0-3.13,0.56-6.24,1.75-9.14c0.95-2.32,2.11-4.02,3.5-4.61c0.71-0.3,1.2-0.95,1.22-1.73
		c0.02-1.67-0.3-4.28-2.39-5.52c-0.4-0.23-0.68-0.62-0.83-1.05C73.48,5.56,71.97,2.53,68.19,2.53z M68.19,4.36
		c1.15,0,2.07,0.37,2.81,1.14c0.7,0.72,1.09,1.63,1.25,2.11c0.3,0.87,0.88,1.59,1.63,2.03c1.34,0.79,1.51,2.79,1.49,3.91
		c0,0.01-0.03,0.04-0.1,0.07c-1.78,0.75-3.25,2.58-4.49,5.61c-1.25,3.05-1.88,6.35-1.88,9.83v9.92c0,0.07-0.06,0.13-0.13,0.13H67.6
		c-0.07,0-0.13-0.06-0.13-0.13v-9.92c0-3.48-0.63-6.79-1.88-9.83c-1.24-3.02-2.71-4.86-4.49-5.61C61.03,13.59,61,13.55,61,13.55
		c-0.02-1.12,0.16-3.12,1.49-3.91c0.75-0.44,1.32-1.16,1.63-2.03c0.17-0.48,0.55-1.39,1.25-2.11C66.12,4.73,67.04,4.36,68.19,4.36"
    />
    <path
      style={{
        fill: "white",
        stroke: props.frontColor || COLORS.GREY,
        strokeWidth: "2",
        strokeMiterlimit: "10",
      }}
      d="M76.42,53.23h-9c-0.43,0-0.79-0.35-0.79-0.79v-0.47c0-0.43,0.35-0.79,0.79-0.79h9c0.43,0,0.79,0.35,0.79,0.79
		v0.47C77.2,52.88,76.85,53.23,76.42,53.23z M81.35,43.1H55.02c-1.98,0-3.47,1.81-3.08,3.76l2.78,13.92
		c0.07,0.37,0.4,0.63,0.77,0.63h10.36c0.43,0,0.79,0.35,0.79,0.79v0.47c0,0.43-0.35,0.79-0.79,0.79h-9.64
		c-0.5,0-0.87,0.45-0.77,0.94l5.11,25.63c0.07,0.37,0.4,0.63,0.77,0.63h4.35c0.43,0,0.79,0.35,0.79,0.79v0.47
		c0,0.43-0.35,0.79-0.79,0.79h-3.63c-0.5,0-0.87,0.45-0.77,0.94l3.83,19.21c0.67,3.37,5.49,3.37,6.17,0l7.72-38.71
		c0.1-0.49-0.28-0.94-0.77-0.94h-5.79c-0.43,0-0.79-0.35-0.79-0.79v-0.47c0-0.43,0.35-0.79,0.79-0.79h6.51
		c0.37,0,0.7-0.26,0.77-0.63l4.72-23.67C84.82,44.91,83.33,43.1,81.35,43.1z"
    />
  </svg>
);
export const IconVeggieFilled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      d="M49.32,24.34c-3.16,0-3.59,3.4-3.61,5.14c-0.01,0.62-0.3,1.19-0.78,1.56c-3.46,2.69-1.29,5.88,0.03,7.33
		c0.38,0.41,0.91,0.63,1.46,0.63c0.09,0,0.18-0.01,0.28-0.02c0.52-0.07,1.03-0.1,1.54-0.1c4.85,0,8.79,3.08,11.5,6.12
		c0.41,0.46,0.94,0.67,1.46,0.67c1.08,0,2.11-0.89,1.98-2.17c-0.16-1.58-0.36-3.3-0.61-5.06c-0.04-0.28-0.08-0.55-0.12-0.81
		c-0.01-0.1-0.04-0.2-0.07-0.3c-1.26-4.15-3.49-8.27-7.42-10.8C52.47,24.94,50.65,24.34,49.32,24.34 M49.32,26.18
		c0.76,0,2.18,0.33,4.63,1.91c3.04,1.95,5.28,5.25,6.66,9.78c0,0.01,0,0.02,0.01,0.03c0.04,0.26,0.08,0.53,0.12,0.8
		c0.22,1.57,0.43,3.25,0.6,4.99c0.01,0.06-0.01,0.08-0.01,0.09c-0.03,0.03-0.09,0.06-0.13,0.06c-0.02,0-0.04,0-0.08-0.05
		c-3.93-4.41-8.38-6.74-12.88-6.74c-0.59,0-1.19,0.04-1.78,0.12c-0.01,0-0.02,0-0.03,0c-0.02,0-0.07,0-0.1-0.03
		c-0.96-1.05-1.47-2.11-1.4-2.92c0.02-0.22,0.07-0.88,1.15-1.71c0.94-0.73,1.48-1.83,1.49-3c0.01-0.99,0.17-1.86,0.45-2.45
		C48.3,26.41,48.66,26.18,49.32,26.18"
    />
    <path
      d="M88.43,24.34c-1.33,0-3.15,0.61-5.63,2.2c-3.93,2.53-6.16,6.65-7.42,10.8c-0.03,0.1-0.05,0.2-0.07,0.3
		c-0.04,0.27-0.08,0.54-0.12,0.81c-0.25,1.76-0.45,3.48-0.61,5.06c-0.13,1.29,0.9,2.17,1.98,2.17c0.52,0,1.05-0.21,1.46-0.67
		c2.71-3.05,6.65-6.13,11.5-6.13c0.5,0,1.02,0.03,1.54,0.1c0.09,0.01,0.18,0.02,0.28,0.02c0.55,0,1.08-0.22,1.46-0.63
		c1.32-1.44,3.48-4.64,0.03-7.33c-0.49-0.38-0.78-0.95-0.78-1.56C92.02,27.74,91.59,24.33,88.43,24.34 M88.43,24.34v1.84
		c0.66,0,1.02,0.23,1.31,0.86c0.28,0.59,0.44,1.46,0.45,2.45c0.01,1.18,0.55,2.27,1.49,3c1.07,0.84,1.13,1.5,1.15,1.72
		c0.07,0.8-0.44,1.87-1.4,2.92c-0.03,0.03-0.08,0.03-0.1,0.03c-0.01,0-0.02,0-0.03,0c-0.59-0.08-1.19-0.12-1.78-0.12
		c-4.5,0-8.95,2.33-12.88,6.74c-0.05,0.05-0.07,0.05-0.08,0.05c-0.04,0-0.1-0.02-0.13-0.06c-0.01-0.01-0.02-0.02-0.01-0.09
		c0.18-1.74,0.38-3.42,0.6-4.99c0.04-0.27,0.08-0.54,0.12-0.8c0-0.02,0.01-0.03,0.01-0.03c1.38-4.54,3.62-7.83,6.66-9.78
		c2.45-1.58,3.87-1.91,4.63-1.91V24.34z"
    />
    <path
      d="M68.87,7.09c-3.8,0-5.32,3.04-5.82,4.5c-0.15,0.44-0.43,0.82-0.83,1.06c-2.1,1.24-2.43,3.87-2.4,5.55
		c0.01,0.78,0.51,1.43,1.22,1.74c1.4,0.59,2.56,2.3,3.52,4.63c1.19,2.91,1.75,6.04,1.75,9.18v9.96c0,1.09,0.88,1.97,1.97,1.97h1.18
		c1.09,0,1.97-0.88,1.97-1.97v-9.96c0-3.14,0.56-6.27,1.75-9.18c0.96-2.33,2.12-4.04,3.52-4.63c0.72-0.3,1.21-0.96,1.22-1.74
		c0.03-1.68-0.3-4.3-2.4-5.55c-0.4-0.23-0.68-0.62-0.83-1.06C74.19,10.14,72.67,7.09,68.87,7.09 M68.87,8.93
		c1.15,0,2.08,0.37,2.83,1.15c0.7,0.72,1.09,1.64,1.26,2.12c0.3,0.87,0.88,1.59,1.63,2.04c1.34,0.79,1.52,2.81,1.5,3.93
		c0,0.01-0.03,0.04-0.1,0.07c-1.79,0.75-3.26,2.59-4.51,5.63c-1.26,3.06-1.89,6.38-1.89,9.88v9.96c0,0.07-0.06,0.13-0.13,0.13h-1.18
		c-0.07,0-0.13-0.06-0.13-0.13v-9.96c0-3.49-0.64-6.82-1.89-9.88c-1.25-3.04-2.72-4.88-4.51-5.63c-0.06-0.03-0.09-0.06-0.09-0.06
		c-0.02-1.13,0.16-3.14,1.5-3.93c0.75-0.44,1.33-1.17,1.63-2.04c0.17-0.48,0.55-1.39,1.26-2.12C66.8,9.31,67.72,8.93,68.87,8.93"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M77.14,58.04H68.1c-0.44,0-0.79-0.35-0.79-0.79v-0.47c0-0.44,0.35-0.79,0.79-0.79h9.04
		c0.44,0,0.79,0.35,0.79,0.79v0.47C77.93,57.68,77.58,58.04,77.14,58.04 M82.1,47.85H55.65c-1.99,0-3.49,1.82-3.1,3.78l2.79,13.98
		c0.07,0.37,0.4,0.63,0.77,0.63h10.41c0.44,0,0.79,0.35,0.79,0.79v0.47c0,0.44-0.35,0.79-0.79,0.79h-9.68
		c-0.5,0-0.87,0.46-0.77,0.94L61.2,95c0.07,0.37,0.4,0.63,0.77,0.63h4.37c0.44,0,0.79,0.35,0.79,0.79v0.47
		c0,0.44-0.35,0.79-0.79,0.79H62.7c-0.5,0-0.87,0.46-0.77,0.94l3.85,19.3c0.68,3.39,5.52,3.39,6.2,0l7.76-38.89
		c0.1-0.49-0.28-0.94-0.77-0.94h-5.82c-0.44,0-0.79-0.35-0.79-0.79v-0.47c0-0.44,0.35-0.79,0.79-0.79h6.54
		c0.38,0,0.7-0.27,0.77-0.64l4.74-23.78C85.59,49.68,84.09,47.85,82.1,47.85"
    />
  </svg>
);
export const IconHoney = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M32.92,61.85l-8.2,14.22c-1.82,3.16-1.86,6.84-0.12,9.86c1.71,2.96,4.82,4.73,8.32,4.73
		c3.49,0,6.6-1.77,8.31-4.73c1.75-3.02,1.7-6.7-0.11-9.86C41.12,76.07,34.86,65.21,32.92,61.85 M32.92,93.98
		c-4.7,0-8.88-2.39-11.19-6.39c-2.34-4.05-2.3-8.98,0.11-13.17l9.64-16.71c0.3-0.51,0.84-0.83,1.43-0.83c0.59,0,1.14,0.31,1.44,0.83
		C36.71,61.77,44,74.42,44,74.42c2.41,4.2,2.46,9.12,0.11,13.18C41.79,91.59,37.61,93.98,32.92,93.98 M74.04,37.95
		c-0.94,0-1.83,0.37-2.51,1.05L54.6,55.93c-1.37,1.36-1.4,3.55-0.09,4.86c1.28,1.28,3.54,1.24,4.86-0.09L76.3,43.77
		c1.37-1.37,1.41-3.55,0.09-4.86C75.77,38.29,74.94,37.95,74.04,37.95 M45.09,51.2c-0.67,0.67-1.04,1.54-1.05,2.47
		c-0.01,0.91,0.33,1.76,0.96,2.39c1.28,1.28,3.54,1.23,4.86-0.09l21.71-21.71c1.36-1.36,1.4-3.55,0.09-4.86
		c-0.62-0.62-1.46-0.96-2.35-0.96c-0.94,0-1.83,0.37-2.51,1.05L45.09,51.2z M59.8,23.71c-0.94,0-1.83,0.37-2.51,1.05L40.36,41.69
		c-0.66,0.67-1.04,1.54-1.05,2.47c-0.01,0.91,0.33,1.76,0.96,2.39c1.27,1.28,3.54,1.23,4.86-0.09l16.93-16.93
		c0.67-0.67,1.04-1.55,1.05-2.47c0.01-0.91-0.33-1.76-0.96-2.39C61.53,24.05,60.7,23.71,59.8,23.71 M71.81,52.95l-3.27,3.26
		l41.99,41.99c0.47,0.47,1.3,0.48,1.78,0l1.48-1.48c0.24-0.24,0.37-0.55,0.37-0.89c0-0.34-0.13-0.65-0.37-0.89L71.81,52.95z
		 M36.68,41.22c-1.32-0.24-2.52-0.87-3.48-1.83c-1.27-1.27-1.96-2.97-1.93-4.78c0.02-1.8,0.74-3.49,2.02-4.77l12.16-12.16
		c1.3-1.3,3.03-2.02,4.86-2.02c1.78,0,3.45,0.69,4.69,1.93c0.96,0.96,1.59,2.17,1.83,3.48c0.92-0.45,1.94-0.68,2.99-0.68
		c1.78,0,3.45,0.69,4.69,1.93c0.96,0.96,1.59,2.17,1.83,3.48c0.92-0.45,1.94-0.68,2.99-0.68c1.78,0,3.45,0.69,4.69,1.93
		c1.27,1.27,1.96,2.97,1.93,4.78c-0.01,1.02-0.25,2.01-0.69,2.9c1.32,0.24,2.52,0.87,3.48,1.83c1.27,1.27,1.96,2.97,1.93,4.78
		c-0.02,1.8-0.74,3.49-2.02,4.78l-4.49,4.49l41.99,41.99c0.86,0.86,1.34,2.01,1.34,3.24c0,1.22-0.48,2.37-1.34,3.24l-1.48,1.48
		c-0.86,0.86-2.01,1.34-3.24,1.34s-2.37-0.48-3.24-1.34L66.2,58.56l-4.49,4.49c-1.31,1.3-3.03,2.02-4.86,2.02
		c-1.78,0-3.45-0.69-4.69-1.94c-0.98-0.98-1.59-2.2-1.82-3.48c-0.92,0.45-1.94,0.69-2.99,0.69c-1.78,0-3.45-0.69-4.69-1.94
		c-1.27-1.27-1.96-2.97-1.93-4.78c0.01-1.02,0.25-2.01,0.68-2.9c-1.32-0.24-2.52-0.87-3.48-1.83c-1.27-1.27-1.96-2.97-1.93-4.78
		C36,43.11,36.24,42.12,36.68,41.22 M50.3,18.98c-0.94,0-1.83,0.37-2.51,1.05L35.63,32.19c-1.37,1.36-1.4,3.55-0.09,4.86
		c1.28,1.28,3.54,1.23,4.86-0.09L52.56,24.8c0.67-0.67,1.04-1.54,1.05-2.47c0.01-0.91-0.33-1.76-0.96-2.39
		C52.03,19.32,51.19,18.98,50.3,18.98"
    />
  </svg>
);
export const IconHoneyFilled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M31.46,95.65c-4.89,0-9.25-2.49-11.66-6.65
		c-2.44-4.22-2.4-9.35,0.12-13.72l10.04-17.4c0.31-0.53,0.88-0.86,1.49-0.87c0.62,0,1.19,0.33,1.5,0.86
		C35.41,62.1,43,75.28,43,75.28c2.51,4.37,2.56,9.5,0.11,13.73C40.7,93.16,36.35,95.65,31.46,95.65 M35.37,40.69
		c-1.37-0.25-2.63-0.9-3.63-1.9c-1.32-1.32-2.04-3.09-2.01-4.98c0.02-1.87,0.77-3.64,2.11-4.97L44.5,16.17
		c1.36-1.36,3.15-2.11,5.06-2.11c1.85,0,3.59,0.72,4.89,2.02c1,1,1.65,2.26,1.9,3.63c0.96-0.47,2.02-0.71,3.11-0.71
		c1.85,0,3.59,0.71,4.89,2.01c1,1,1.65,2.26,1.9,3.63c0.96-0.47,2.02-0.71,3.11-0.71c1.85,0,3.59,0.72,4.89,2.01
		c1.32,1.32,2.04,3.09,2.02,4.98c-0.01,1.06-0.26,2.09-0.72,3.02c1.37,0.25,2.63,0.9,3.63,1.9c1.32,1.32,2.04,3.09,2.01,4.98
		c-0.02,1.87-0.77,3.64-2.1,4.97l-4.68,4.68l43.74,43.74c0.9,0.9,1.4,2.1,1.4,3.37c0,1.27-0.5,2.47-1.4,3.37l-1.54,1.54
		c-0.9,0.9-2.1,1.4-3.37,1.4c-1.27,0-2.47-0.5-3.37-1.4L66.13,58.76l-4.68,4.68c-1.36,1.36-3.15,2.11-5.06,2.11
		c-1.85,0-3.59-0.72-4.89-2.02c-1.02-1.02-1.65-2.29-1.9-3.63c-0.96,0.47-2.02,0.72-3.11,0.72c-1.85,0-3.59-0.72-4.89-2.02
		c-1.32-1.32-2.04-3.09-2.01-4.98c0.01-1.06,0.26-2.09,0.71-3.02c-1.37-0.25-2.63-0.9-3.63-1.9c-1.32-1.32-2.04-3.09-2.02-4.98
		C34.67,42.66,34.92,41.63,35.37,40.69"
    />
  </svg>
);
export const IconDrink = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M53.72,94.38h29.26c0.4,0,0.73-0.29,0.79-0.68l9.86-68.36c0.07-0.48-0.3-0.91-0.79-0.91H43.85
		c-0.49,0-0.86,0.43-0.79,0.91l9.86,68.36C52.98,94.09,53.32,94.38,53.72,94.38 M86.02,97.89H50.68c-0.4,0-0.73-0.29-0.79-0.68
		L39.01,21.83c-0.07-0.48,0.3-0.91,0.79-0.91h57.1c0.49,0,0.86,0.43,0.79,0.91L86.81,97.21C86.75,97.6,86.42,97.89,86.02,97.89"
    />
  </svg>
);
export const IconDrinkFilled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M53.72,94.38h29.26c0.4,0,0.73-0.29,0.79-0.68l9.86-68.36c0.07-0.48-0.3-0.91-0.79-0.91H43.85
		c-0.49,0-0.86,0.43-0.79,0.91l9.86,68.36C52.98,94.09,53.32,94.38,53.72,94.38 M86.02,97.89H50.68c-0.4,0-0.73-0.29-0.79-0.68
		L39.01,21.83c-0.07-0.48,0.3-0.91,0.79-0.91h57.1c0.49,0,0.86,0.43,0.79,0.91L86.81,97.21C86.75,97.6,86.42,97.89,86.02,97.89"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M48.1,45.68l6.63,45.94c0.06,0.39,0.39,0.68,0.79,0.68h25.67c0.4,0,0.73-0.29,0.79-0.68l6.63-45.94
		c0.07-0.48-0.3-0.91-0.79-0.91H48.89C48.4,44.77,48.03,45.2,48.1,45.68"
    />
  </svg>
);
export const IconCoffee = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M68.41,53.78c-0.27,0.22-0.68,0.15-0.86-0.15l-1-1.65l0.66,1.09l-0.39-0.64c-0.15-0.25-0.1-0.56,0.12-0.75
		c0.87-0.73,2.84-2.84,2.84-6.92c0-2.48-0.96-3.66-2.18-5.14c-1.35-1.65-2.89-3.52-2.89-7.22c0-5.66,2.84-8.24,4.1-9.11
		c0.29-0.2,0.7-0.1,0.85,0.22l0.84,1.72l0.01-0.01c-0.13,0.07-3.25,1.74-3.25,7.17c0,2.79,1.07,4.09,2.31,5.6
		c1.29,1.58,2.76,3.37,2.76,6.76C72.34,50.07,69.6,52.83,68.41,53.78z"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M57.28,54.75c-0.27,0.24-0.69,0.17-0.88-0.14l-0.99-1.63L55.4,53c0.06-0.04,1.54-1.03,1.54-3.54
		c0-1.18-0.43-1.7-1.08-2.5c-0.77-0.94-1.73-2.11-1.73-4.37c0-3.12,1.47-4.72,2.33-5.38c0.29-0.22,0.71-0.12,0.87,0.21l0.83,1.71
		c0,0,0.01,0,0.02-0.01c-0.06,0.03-1.5,0.85-1.5,3.48c0,1.35,0.48,1.94,1.15,2.75c0.74,0.9,1.66,2.03,1.66,4.12
		C59.49,52.38,58.09,54.04,57.28,54.75z"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M81.46,53.62c-0.19,0.31-0.61,0.38-0.88,0.14c-0.8-0.71-2.21-2.38-2.21-5.29c0-2.09,0.92-3.22,1.66-4.12
		c0.67-0.82,1.15-1.41,1.15-2.75c0-2.64-1.44-3.45-1.5-3.48c0.01,0.01,0.02,0.01,0.02,0.01l0.83-1.71c0.16-0.33,0.58-0.43,0.87-0.21
		c0.85,0.66,2.33,2.26,2.33,5.38c0,2.26-0.96,3.43-1.73,4.37c-0.65,0.8-1.08,1.32-1.08,2.5c0,2.51,1.48,3.5,1.54,3.54l-0.02-0.01
		L81.46,53.62z"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M104.64,88.85c-0.75,4-7.96,6.26-8.81,6.51c-0.05,0.02-0.11,0.02-0.17,0.02H42.38c-0.06,0-0.11-0.01-0.17-0.02
		c-0.85-0.25-8.06-2.51-8.81-6.51c-0.07-0.35,0.21-0.68,0.57-0.68h70.07C104.42,88.18,104.7,88.5,104.64,88.85z"
    />
    <path
      style={{
        fill: "white",
        stroke: props.frontColor || COLORS.GREY,
        strokeWidth: "2",
        strokeMiterlimit: "10",
      }}
      d="M54.12,82.83c-0.18,0.38-0.63,0.54-1.01,0.36c-5.32-2.56-9.93-5.76-13.37-9.3c-4.18-4.29-6.39-8.78-6.58-13.35
		c-0.01-0.36,0.12-0.72,0.37-0.98c0.25-0.26,0.6-0.41,0.97-0.41h9.27c0.42,0,0.75,0.34,0.75,0.75c0,0.42-0.34,0.75-0.75,0.75H35.5
		c-0.45,0-0.8,0.39-0.75,0.84c0.45,3.83,2.49,7.64,6.08,11.33c3.32,3.41,7.78,6.51,12.95,8.99C54.14,82.01,54.3,82.46,54.12,82.83z
		 M110.97,55.51H27.08c-0.33,0-0.59,0.27-0.58,0.6c0.3,13.67,10.95,25.34,26.03,30.35c0.35,0.12,0.72,0.17,1.08,0.17h30.83
		c0.37,0,0.73-0.06,1.08-0.17c15.08-5.01,25.73-16.68,26.03-30.35C111.56,55.78,111.29,55.51,110.97,55.51z"
    />
  </svg>
);
export const IconCoffeeFilled = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 130.47 127.57"
    width={props.size || "20px"}
    height={props.size || "20px"}
  >
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M68.41,53.78c-0.27,0.22-0.68,0.15-0.86-0.15l-1-1.65l0.66,1.09l-0.39-0.64c-0.15-0.25-0.1-0.56,0.12-0.75
		c0.87-0.73,2.84-2.84,2.84-6.92c0-2.48-0.96-3.66-2.18-5.14c-1.35-1.65-2.89-3.52-2.89-7.22c0-5.66,2.84-8.24,4.1-9.11
		c0.29-0.2,0.7-0.1,0.85,0.22l0.84,1.72l0.01-0.01c-0.13,0.07-3.25,1.74-3.25,7.17c0,2.79,1.07,4.09,2.31,5.6
		c1.29,1.58,2.76,3.37,2.76,6.76C72.34,50.07,69.6,52.83,68.41,53.78z"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M57.28,54.75c-0.27,0.24-0.69,0.17-0.88-0.14l-0.99-1.63L55.4,53c0.06-0.04,1.54-1.03,1.54-3.54
		c0-1.18-0.43-1.7-1.08-2.5c-0.77-0.94-1.73-2.11-1.73-4.37c0-3.12,1.47-4.72,2.33-5.38c0.29-0.22,0.71-0.12,0.87,0.21l0.83,1.71
		c0,0,0.01,0,0.02-0.01c-0.06,0.03-1.5,0.85-1.5,3.48c0,1.35,0.48,1.94,1.15,2.75c0.74,0.9,1.66,2.03,1.66,4.12
		C59.49,52.38,58.09,54.04,57.28,54.75z"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M81.46,53.62c-0.19,0.31-0.61,0.38-0.88,0.14c-0.8-0.71-2.21-2.38-2.21-5.29c0-2.09,0.92-3.22,1.66-4.12
		c0.67-0.82,1.15-1.41,1.15-2.75c0-2.64-1.44-3.45-1.5-3.48c0.01,0.01,0.02,0.01,0.02,0.01l0.83-1.71c0.16-0.33,0.58-0.43,0.87-0.21
		c0.85,0.66,2.33,2.26,2.33,5.38c0,2.26-0.96,3.43-1.73,4.37c-0.65,0.8-1.08,1.32-1.08,2.5c0,2.51,1.48,3.5,1.54,3.54l-0.02-0.01
		L81.46,53.62z"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M104.64,88.85c-0.75,4-7.96,6.26-8.81,6.51c-0.05,0.02-0.11,0.02-0.17,0.02H42.38c-0.06,0-0.11-0.01-0.17-0.02
		c-0.85-0.25-8.06-2.51-8.81-6.51c-0.07-0.35,0.21-0.68,0.57-0.68h70.07C104.42,88.18,104.7,88.5,104.64,88.85z"
    />
    <path
      style={{ fill: props.frontColor || COLORS.GREY }}
      d="M54.12,82.83c-0.18,0.38-0.63,0.54-1.01,0.36c-5.32-2.56-9.93-5.76-13.37-9.3c-4.18-4.29-6.39-8.78-6.58-13.35
		c-0.01-0.36,0.12-0.72,0.37-0.98c0.25-0.26,0.6-0.41,0.97-0.41h9.27c0.42,0,0.75,0.34,0.75,0.75c0,0.42-0.34,0.75-0.75,0.75H35.5
		c-0.45,0-0.8,0.39-0.75,0.84c0.45,3.83,2.49,7.64,6.08,11.33c3.32,3.41,7.78,6.51,12.95,8.99C54.14,82.01,54.3,82.46,54.12,82.83z
		 M110.97,55.51H27.08c-0.33,0-0.59,0.27-0.58,0.6c0.3,13.67,10.95,25.34,26.03,30.35c0.35,0.12,0.72,0.17,1.08,0.17h30.83
		c0.37,0,0.73-0.06,1.08-0.17c15.08-5.01,25.73-16.68,26.03-30.35C111.56,55.78,111.29,55.51,110.97,55.51z"
    />
  </svg>
);
