export const LABELS = {
  "data":
  {

    "Rezepte": {

      "de": "&lt;p&gt;Rezepte&lt;/p&gt;"

    },

    "Mein_Ernaehrungsplan": {

      "de": "&lt;p&gt;Ernährungsplan&lt;/p&gt;"

    },

    "Name": {

      "de": "&lt;p&gt;Name&lt;/p&gt;"

    },

    "SignUp_Check_Name": {

      "de": "&lt;p&gt;Name eingegeben&lt;/p&gt;"

    },

    "Abend": {

      "de": "&lt;p&gt;Abends&lt;/p&gt;"

    },

    "Mittag": {

      "de": "&lt;p&gt;Mittags&lt;/p&gt;"

    },

    "Snack": {

      "de": "&lt;p&gt;Snack&lt;/p&gt;"

    },

    "Fruehstueck": {

      "de": "&lt;p&gt;Morgens&lt;/p&gt;"

    },

    "Hinweis_Pflichtfelder": {

      "de": "&lt;p&gt;Bitte füllen Sie alle Pflichtfelder aus.&lt;/p&gt;"

    },

    "Button_Anamnese_Fertig": {

      "de": "&lt;p&gt;Senden&lt;/p&gt;"

    },

    "Button_Anamnese_Weiter": {

      "de": "&lt;p&gt;weiter&lt;/p&gt;"

    },

    "Button_Anamnese_Start": {

      "de": "&lt;p&gt;Los geht&#39;s&lt;/p&gt;"

    },

    "Anamnese": {

      "de": "&lt;p&gt;Anamnese&lt;/p&gt;"

    },

    "Favoriten_Intro": {

      "de": "&lt;h1&gt;Favoriten erfassen&lt;/h1&gt;&lt;p&gt;Um Ihnen die Erfassung Ihrer täglichen Mahlzeiten so einfach wie möglich zu machen, können Sie hier Ihre Favoriten erfassen - Wählen Sie aus mehr als 200.000 Lebensmittel Ihre Lieblinge aus.&lt;/p&gt;"

    },

    "Abbrechen": {

      "de": "&lt;p&gt;Abbrechen&lt;/p&gt;"

    },

    "Speichern": {

      "de": "&lt;p&gt;Speichern&lt;/p&gt;"

    },

    "Abmelden": {

      "de": "&lt;p&gt;Abmelden&lt;/p&gt;"

    },

    "Hilfe": {

      "de": "&lt;p&gt;Hilfe&lt;/p&gt;"

    },

    "Favoriten": {

      "de": "&lt;p&gt;Favoriten&lt;/p&gt;"

    },

    "Landing_Button_Intro": {

      "de": "&lt;p class=&quot;big-text&quot;&gt;Der folgende Fragebogen hilft uns zu verstehen, was Ihren Stoffwechsel, Ihr Gewicht und Ihre Gesundheit beeinflusst.&lt;/p&gt;&lt;p&gt;Bereit loszulegen?&lt;/p&gt;"

    },

    "Fehlerhafte_Angaben": {

      "de": "&lt;p&gt;Bitte prüfen Sie die eingegebenen Daten.&lt;/p&gt;"

    },

    "Passwort_aendern": {

      "de": "&lt;p&gt;Passwort Ändern&lt;/p&gt;"

    },

    "Codenummer": {

      "de": "&lt;p&gt;Code-Nummer&lt;/p&gt;"

    },

    "Passwort_aendern_Code_Button": {

      "de": "&lt;p&gt;Passwort ändern mit Code&lt;/p&gt;"

    },

    "Nochmal_senden": {

      "de": "&lt;p&gt;Nochmal senden&lt;/p&gt;"

    },

    "Passwort_vergessen_Erlaeuterung": {

      "de": "&lt;p&gt;Nach Eingabe Ihrer Emailadresse und Absenden der Anfrage erhalten Sie eine Email mit einer Codenummer zum Ändern Ihres Passworts. &lt;br/p&gt;Das Zusenden der Codenummer kann einige Minuten in Anspruch nehmen, bitte haben Sie etwas Geduld.&lt;/p&gt;&lt;p&gt;Lassen Sie dieses Browserfenster bitte geöffnet und prüfen Sie Ihre Emails, ggf. auch den SPAM-Ordner. Die Absenderadresse lautet no-reply@verificationemail.com&lt;/p&gt;"

    },

    "Passwort_vergessen_absenden": {

      "de": "&lt;p&gt;Code zusenden&lt;/p&gt;"

    },

    "SignIn_Benutzer_Passwort_falsch": {

      "de": "&lt;p&gt;Passwort nicht korrekt.&lt;/p&gt;"

    },

    "SignIn_Benutzer_existiert_nicht": {

      "de": "&lt;p&gt;Für diese E-Mail Adresse ist noch kein Kundenkonto angelegt. Sie können unter &lt;a href=&#34;/signup&#34; title=&#34;&#34;&gt;Registrieren&lt;/a&gt; ein neues Kundenkonto anlegen.&lt;/p&gt;"

    },

    "SignUp_Benutzer_existiert_Fehler": {

      "de": "&lt;p&gt;Für die angegebene Email-Adresse existiert bereits ein Benutzerkonto. Benutzen Sie den&lt;a href=&#34;/signin&#34; title=&#34;&#34;&gt; Login.&lt;/a&gt;&lt;/p&gt;"

    },

    "SignUp_Check_PW_LEN": {

      "de": "&lt;p&gt;Passwort ist min. 8 Zeichen lang.&lt;/p&gt;"

    },

    "SignUp_Check_PW_NUM": {

      "de": "&lt;p&gt;Passwort enthält min. 1 Zahl&lt;/p&gt;"

    },

    "SignUp_Check_PW_KB": {

      "de": "&lt;p&gt;Passwort enthält min. 1 Kleinbuchstaben&lt;/p&gt;"

    },

    "SignUp_Check_PW_GB": {

      "de": "&lt;p&gt;Passwort enthält min. 1 Großbuchstaben&lt;/p&gt;"

    },

    "SignUp_Check_PW_ident": {

      "de": "&lt;p&gt;Passwörter sind identisch&lt;/p&gt;"

    },

    "SignUp_Check_Email": {

      "de": "&lt;p&gt;Gültige Emailadresse&lt;/p&gt;"

    },

    "Passwort_vergessen_Button": {

      "de": "&lt;p&gt;Passwort vergessen&lt;/p&gt;"

    },

    "Passwort_vergessen_Text_Login": {

      "de": "&lt;p&gt;&lt;strong&gt;Passwort vergessen?&lt;/strong&gt; Klicken Sie einfach &lt;a href=&#34;/lostpassword&#34; title=&#34;&#34;&gt;&lt;strong&gt;HIER&lt;/strong&gt;&lt;/a&gt; um ein neues Passwort zu vergeben.&lt;/p&gt;"

    },

    "Passwort_bestaetigen": {

      "de": "&lt;p&gt;Passwort bestätigen&lt;/p&gt;"

    },

    "Passwort": {

      "de": "&lt;p&gt;Passwort&lt;/p&gt;"

    },

    "EMail_Adresse": {

      "de": "&lt;p&gt;E-Mail Adresse&lt;/p&gt;"

    },

    "Essen_erfassen": {

      "de": "&lt;p&gt;Ernährungstagebuch&lt;/p&gt;"

    },

    "Intro_Text_Start": {

      "de": "&lt;h1&gt;Willkommen bei EaMo - Lernen Sie wieder richtig zu Essen!&lt;/h1&gt;&lt;p&gt;In dieser Anwendung analysieren wir Ihr Essverhalten und coachen Sie aktiv für eine dauerhaft ausgewogene Ernährung und ein gesundes Gewicht.&lt;/p&gt;"

    },

    "Registrieren": {

      "de": "&lt;p&gt;Registrieren&lt;/p&gt;"

    },

    "Account_Vorhanden_Login": {

      "de": "Hast du schon einen Account? &lt;a href=&#34;/signin&#34; title=&#34;&#34;&gt;&lt;strong&gt;Einloggen&lt;/strong&gt;&lt;/a&gt;"

    },

    "Einloggen": {

      "de": "&lt;p&gt;Einloggen&lt;/p&gt;"

    },

    "Eigenes_Lebensmittel": {

      "de": "&lt;p&gt;Lebensmittel erstellen&lt;/p&gt;"

    },

    "Sprechstunde": {

      "de": "&lt;p&gt;Online-Sprechstunde&lt;/p&gt;"

    },

    "Intro_Text_Sprechstunde": {

      "de": "&lt;p&gt;&lt;strong&gt;Liebe Patientinnen und Patienten&lt;/strong&gt;,&lt;/p&gt;&lt;p&gt;Terminvereinbarungen mit unserer Praxis können Sie hier auch außerhalb der Öffnungszeiten 24h an allen Tagen der Woche einfach und bequem online durchführen. Profitieren Sie von einer flexiblen Terminwahl und buchen Sie innerhalb weniger Sekunden Ihren gewünschten Termin.&lt;/p&gt;&lt;ol&gt;&lt;li&gt;Versicherungsart angeben&lt;/li&gt;&lt;li&gt;Besuchsgrund wählen&lt;/li&gt;&lt;li&gt;Termin buchen&lt;/li&gt;&lt;/ol&gt;&lt;p&gt;Nach der Reservierung erhalten Sie eine E-Mail als Bestätigung für Ihre Terminvereinbarung und vor dem Termin eine Erinnerung per E-Mail &amp; SMS.&lt;/p&gt;"

    },

    "Chat": {

      "de": "&lt;p&gt;Chat&lt;/p&gt;"

    },

    "Auswertung": {

      "de": "&lt;p&gt;Auswertung&lt;/p&gt;"

    },

    "Headline_Auswertung": {

      "de": "Ergebnisse aus der Anamnese"

    },

    "Intro_Text_Auswertung": {

      "de": "&lt;p&gt;Wir danken Ihnen für Ihr Vertrauen und bitten um etwas Geduld.&lt;br/&gt;In den nächsten 2-3 Werktage werden wir Ihnen eine kurze Einschätzung über Ihre eingegebenen Daten zur Verfügung stellen.&lt;br/&gt;&lt;br/&gt;Herzlichst&lt;br/&gt;&lt;br/&gt;Ihr Nutrick24- Team!&lt;/p&gt;"

    },

    "Leistungen": {

      "de": "&lt;p&gt;Leistungen&lt;/p&gt;"

    },

    "Intro_Text_Services": {

      "de": "&lt;p&gt;Ab hier können Sie sich einen eigenen Account einrichten und direkte ernährungsmedizinische Leistungen durch einen Arzt oder Ernährungswissenschaftler in Anspruch nehmen.&lt;/p&gt;&lt;p&gt;Die Rechnungen der Leistungen können gegebenenfalls bei den Krankenkassen eingereicht oder in der Steuererklärung als außergewöhnliche Kosten geltend gemacht werden.&lt;/p&gt;&lt;p&gt;Folgende Leistungen können in Anspruch genommen werden:&lt;/p&gt;"

    },

    "Intro_Text_Services_bezahlt": {

      "de": "&lt;p&gt;Wir danken Ihnen für Ihr Vertrauen und werden uns in den nächsten 2-3 Werktage per Mail mit Ihnen in Verbindung setzen und uns mit Ihnen zur weiteren Planung  verabreden.&lt;/p&gt;"

    },

    "Button_Services_Start": {
      "de": "Weiter"
    },

    "Headline_Sevice_Detailled_Summary": {
      "de": "detaillierte Auswertung"
    },

    "Text_Sevice_Detailled_Summary": {
      "de": "Ihren Daten mit individualisiertem Ernährungskonzept"
    },

    "Price_Sevice_Detailled_Summary": {
      "de": "&euro; 91,80"
    },

    "Content_Sevice_Detailled_Summary": {
      "de": "&lt;ul&gt;&lt;li&gt;Auswertung Ihres standardisierten&lt;/li&gt;&lt;li&gt;Fragebogens und Ernährungsverhaltens&lt;/li&gt;&lt;li&gt;Freischaltung der Lebensmittelerfassung&lt;/li&gt;&lt;li&gt;Zusendung eines individualisierten Ernährungskonzeptes, inclusive aller erforderlichen Dokumente zur Gewichtserhaltung und Stoffwechselstabilisierung, als Grundvoraussetzung eines zukünfigen, gesunden Gewichts-und Stoffwechselmanagment&lt;/li&gt;&lt;li&gt;Freischaltung des persönlichen Chats mit unserem Nutrick24 Team für 3 Wochen&lt;/li&gt;&lt;li&gt;Abrechnung nach GOÄ € 91,80&lt;/li&gt;&lt;/ul&gt;"
    },

    "Headline_Sevice_Reduction_Plan": {
      "de": "Individuelle Reduktionsphase"
    },

    "Text_Sevice_Reduction_Plan": {
      "de": "online - für 3 Wochen"
    },

    "Content_Sevice_Reduction_Plan": {
      "de": "&lt;ul&gt;&lt;li&gt;Auswertung Ihres standardisierten&lt;/li&gt;&lt;li&gt;Fragebogens und Ernährungsverhaltens&lt;/li&gt;&lt;li&gt;Freischaltung der Lebensmittelerfassung&lt;/li&gt;&lt;li&gt;Zusendung eines individualisierten Ernährungskonzeptes, inclusive aller erforderlichen Dokumente zur Gewichtserhaltung und Stoffwechselstabilisierung, als Grundvoraussetzung eines zukünfigen, gesunden Gewichts-und Stoffwechselmanagment&lt;/li&gt;&lt;li&gt;Freischaltung des persönlichen Chats mit unserem Nutrick24 Team für 3 Wochen&lt;/li&gt;&lt;li&gt;Abrechnung nach GOÄ € 91,80&lt;/li&gt;&lt;/ul&gt;"
    },

    "Gewichtsprotokoll": {

      "de": "&lt;p&gt;Gewichtsprotokoll&lt;/p&gt;"

    },

    "Weight_Headline_Time": {
      "de": "Uhrzeit der Messung"
    },

    "Weight_Headline_kilo": {
      "de": "Gewicht"
    },

    "Symptomprotokoll": {

      "de": "&lt;p&gt;Symptomprotokoll&lt;/p&gt;"

    },

    "Symptoms_Headline_Time": {
      "de": "Uhrzeit der Beschwerden"
    },

    "Symptoms_Headline_Lebensmittel": {
      "de": "Konsumierte Lebensmittel"
    },

    "Symptoms_Headline": {
      "de": "Beschwerden"
    },

    "Blutdruckprotokoll": {

      "de": "&lt;p&gt;Blutdruckprotokoll&lt;/p&gt;"

    },

    "Blutzuckerprotokoll": {

      "de": "&lt;p&gt;Blutzuckerprotokoll&lt;/p&gt;"

    },


    "BloodPressure_Headline_Time": {
      "de": "Uhrzeit der Messung"
    },

    "BloodPressure_Headline_Systole": {
      "de": "Systole"
    },

    "BloodPressure_Headline_Diastole": {
      "de": "Diastole"
    },

    "BloodPressure_Headline_Puls": {
      "de": "Puls"
    },

    "BloodSugar_Headline_Time": {
      "de": "Uhrzeit der Messung"
    },

    "BloodSugar_Headline_Sugar": {
      "de": "Blutzucker"
    },

    "BloodSugar_Headline_Hb1c": {
      "de": "Hb1c-Wert"
    },

    "Sport_Headline_Time": {
      "de": "Uhrzeit der Aktivität"
    },

    "Sport_Headline_Duration": {
      "de": "Dauer"
    },

    "Sport_Headline_Kraftsport": {
      "de": "Kraftsport"
    },

    "Sport_Headline_Ausdauer": {
      "de": "Ausdauersport"
    },

    "Bewegungsprotokoll": {

      "de": "&lt;p&gt;Bewegungsprotokoll&lt;/p&gt;"

    },

    "NutPlan": {

      "de": "&lt;p&gt;Ernährungsplan&lt;/p&gt;"

    },

    "NutPlan_Intro": {

      "de": "Allgemeine Hinweise"

    },

    "NutPlan_Listing": {

      "de": "&lt;ul&gt;&lt;li&gt;Niemals nüchtern aus dem haus gehen und den Tag starten!&lt;/li&gt;&lt;li&gt;Selbst mit dem Hund sollte man nie nüchtern morgens Gassi gehen, wenn ein Gewichtsproblem besteht!&lt;/li&gt;&lt;li&gt;Niemals nüchtern Sport treiben!&lt;/li&gt;&lt;li&gt;Trinken Sie mindestens 1,5-2 Liter&lt;/li&gt;&lt;li&gt;Wasser pro Tag unabhängig von der Trinkmenge an Kaffe/Tee!&lt;/li&gt;&lt;/ul&gt;"

    },

    "Add": {
      "de": "Hinzufügen"
    },


    "dummy": true
  }

}