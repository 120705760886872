import React from 'react';
import styled from 'styled-components';
import { TitleBar } from '../common';

const UvidaMap = (props) => {
  return <CompS>
    <TitleBar id="tb" label="uVida-Standorte" backroute="/uvida" />
    <div id="mw">
      <iframe
        id="ifr"
        title="uVida-Standorte"
        src="https://my.uvida.app/#/location"
        style={{ width: '1px', minWidth: '100%' }}
      />
    </div>
  </CompS>
};

export default UvidaMap;

const CompS = styled.div`
display:flex;
flex:1 1 100%;
flex-direction:column;
height:100vh;
#mw{
  flex:1 1 auto;
  margin:2rem -1rem 1rem -1rem;
}
  #ifr{
    flex:1 0 90%;
    height:100%;
    width:100vw;
    position:relative;
    top:0;
    left:0;
    border:none;
  }
`;