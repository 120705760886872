import React, { useCallback } from "react";
import styled from "styled-components";
import { produce } from "immer";
import { Button } from "semantic-ui-react";

import { COLORS } from "../../constants";

export const CompS = styled.div`
  .mlist {
    background: white;
    color: ${COLORS.BLUE};
    border-radius: 1rem;
    padding: 0.5rem;
    width: 100%;
    table {
      width: 100%;
      th,
      td {
        &.left {
          text-align: left;
        }
        &.right {
          text-align: right;
          width: 5rem;
        }
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
    i {
      font-size: 1.5rem;
      color: ${COLORS.ORANGE};
    }
  }
  .searchresultsel {
    > * {
      width: 100%;
    }
    border-radius: 1rem;
    background: white;
    border: 1px solid ${COLORS.BLUE};
  }
  .typeToggle {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    button {
      width: 50%;
      cursor: pointer;
      padding: 0.5rem 1rem;
      color: white;
      border-radius: 1rem;
      background: ${COLORS.ORANGE};
      border: none;
      &.active {
        background: ${COLORS.BLUE};
      }
    }
  }
  img {
    max-width: 100%;
  }

  .mealbuttons {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-wrap: wrap;
    gap: 1rem;
    button {
      border: none;
      background: transparent;
      cursor: pointer;
      &.inactive {
        display: none;
      }
    }
    > * {
      flex: 1 1 40%;
    }
  }
  .searchresultsel {
    > * {
      width: 100%;
    }
    border-radius: 1rem;
    background: white;
    border: 1px solid ${COLORS.BLUE};
  }
  .reelsel {
    > * {
      width: 100%;
    }
    border-radius: 1rem;
    background: white;
    border: 1px solid ${COLORS.BLUE};
    h4 {
      padding: 0.5rem;
      color: ${COLORS.ORANGE};
      text-align: center;
    }
  }
  .typeToggle {
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    gap: 2rem;

    button {
      width: 50%;
      cursor: pointer;
      padding: 0.5rem 1rem;
      color: white;
      border-radius: 1rem;
      background: ${COLORS.ORANGE};
      border: none;
      &.active {
        background: ${COLORS.BLUE};
      }
    }
  }
`;

export const TitleBar = (props) => {
  const {
    label,
    setCurrentStep,
    stepsHistory,
    setStepsHistory,
    onClose,
    currentStep,
  } = props;

  const goBack = useCallback(() => {
    const last = getSecondLastValue(stepsHistory);
    setStepsHistory &&
      setStepsHistory(
        produce(stepsHistory, (draft) => {
          draft.pop();
        })
      );
    setCurrentStep(last || 0);
  }, [stepsHistory, setStepsHistory, setCurrentStep]);

  return (
    <TitleBarComp>
      <NavButton
        circular
        icon="arrow left"
        onClick={() => {
          !currentStep || currentStep === 0 ? onClose && onClose() : goBack();
        }}
      />
      {label && <h2>{label}</h2>}
      <NavButton
        circular
        icon="remove"
        onClick={() => {
          onClose && onClose();
        }}
      />
    </TitleBarComp>
  );
};

const TitleBarComp = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
  h2 {
    margin: 0;
    font-size: 1.2rem;
    line-height: 1.5rem;
    color: ${COLORS.BLUE};
    text-align: center;
  }
`;
const NavButton = styled(Button)`
  border: 1px solid ${COLORS.BLUE}!important;
  background: transparent !important;
  color: ${COLORS.BLUE}!important;
`;
function getSecondLastValue(array) {
  if (array.length >= 2) {
    return array[array.length - 2];
  } else {
    return null;
  }
}
