import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { API } from "../../constants";
import request from "superagent";
import { get } from "lodash";
import { useSetRecoilState } from "recoil";
import { tokenState } from "../../atoms";

export default function useInBodyLocationFinder(props) {
  const { plz, latitude, longitude, radius } = props;
  const [found, setFound] = useState([]);
  const [loading, setLoading] = useState(false);
  const setToken = useSetRecoilState(tokenState);

  useEffect(() => {
    Auth.currentSession().then((data) => {
      const t = get(data, "idToken.jwtToken", "");
      setToken(t);
      if (!t) {
        return;
      }
      if (plz.length === 0 && (!latitude || !longitude)) {
        setFound([]);
        return;
      }
      if (plz.length > 0 && plz.length < 5) {
        setFound([]);
        return;
      }
      setLoading(true);
      request
        .get(
          `${API}/inbody/locationfinder?postalcode=${plz}&latitude=${latitude}&longitude=${longitude}&radius=${radius}`
        )
        .set("Authorization", `Bearer ${t}`)
        .then((response) => {
          setFound(response.body);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error locationfinder", err);
          setLoading(false);
        });
    });
  }, [plz, latitude, longitude, radius, setToken]);

  return { found, loadingInBody: loading };
}
