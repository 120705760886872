import React, { useState, useEffect, useMemo, useCallback } from "react";
import { produce } from "immer";
import { useRecoilState, useRecoilValue } from "recoil";
import { get, padStart, set } from "lodash";

import {
  FoodListItem,
  Section,
  TimePicker,
  FoodSearch,
  ReelSelector,
} from "../../common";
import { COLORS } from "../../../constants";
import { AppUtils } from "../../../utils";
import { KKButton } from "../../Buttons";
import {
  cognitoUserState,
  caseState,
  searchToggleState,
  newMealDataState,
  mesMinuteState,
  mesHourState,
  activeMealState,
  currentChecklistState,
  foodfavoritesState,
} from "../../../atoms";
import { getHourMinutesFromTxt } from "../../../utils";
import Snack from "../../../images/snack.svg";
import Dinner from "../../../images/dinner.svg";
import Breakfast from "../../../images/breakfast.svg";
import Lunch from "../../../images/lunch.svg";

import { TitleBar, CompS } from "../common";

const MEALS = [
  {
    id: "f",
    label: "Frühstück",
    img: Breakfast,
  },
  {
    id: "m",
    label: "Mittag",
    img: Lunch,
  },
  {
    id: "z2",
    label: "Snack",
    img: Snack,
  },
  {
    id: "a",
    label: "Abend",
    img: Dinner,
  },
];

const Comp = (props) => {
  const { onClose, now } = props;
  const [currentChecklist, setCurrentchecklist] = useRecoilState(
    currentChecklistState
  );
  const [caseN, setCaseN] = useRecoilState(caseState);
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsHistory, setStepsHistory] = useState([]);
  const [activeMeal, setActiveMeal] = useRecoilState(activeMealState);
  const [mesHour, setMesHour] = useRecoilState(mesHourState);
  const [mesMinute, setMesMinute] = useRecoilState(mesMinuteState);
  const [newMealData, setNewMealData] = useRecoilState(newMealDataState);
  const [foundFoods, setFoundFoods] = useState({});
  const [searchToggle, setSearchToggle] = useRecoilState(searchToggleState);
  const [toolToggle, setToolToggle] = useState(0);
  const favorites = useRecoilValue(foodfavoritesState);
  const cognitoUser = useRecoilValue(cognitoUserState);

  const daystartData = useMemo(() => {
    const d = {};
    if (currentChecklist?.daystart) {
      const [h, m] = getHourMinutesFromTxt(currentChecklist?.daystart);
      d.hour = h;
      d.minute = m;
      d.daystart = currentChecklist?.daystart;
    }
    return d;
  }, [currentChecklist]);
  const gotoStep = useCallback(
    (step) => {
      setCurrentStep(step);
      setStepsHistory(
        produce(stepsHistory, (draft) => {
          draft.push(step);
        })
      );
    },
    [stepsHistory, setCurrentStep, setStepsHistory]
  );

  const setNewFoodSelection = useCallback(
    (evt) => {
      const { id, source } = evt;
      if (
        newMealData &&
        newMealData.food &&
        id &&
        newMealData.food._id === id
      ) {
        return;
      }
      switch (source) {
        case "foods":
          AppUtils.getCachedS3Data(`data/kk_food/${id}.json`)
            .then((f) => {
              setNewMealData(
                produce(newMealData || {}, (draft) => {
                  f.id = `${id}--${now}`;
                  draft.food = f;
                })
              );
            })
            .catch((err) => {
              console.log(err);
            });

          break;
        case "favorites":
          const idParts = id.split("--");
          AppUtils.getCachedS3Data(
            `user/${cognitoUser.sub}/foodfavorites/${idParts[0]}.json`
          )
            .then((f) => {
              setNewMealData(
                produce(newMealData || {}, (draft) => {
                  f.id = `${id}--${now}`;
                  draft.food = f;
                })
              );
            })
            .catch((err) => {
              console.log(err);
            });
          break;
        default:
      }
    },
    [newMealData, setNewMealData, now, cognitoUser]
  );

  useEffect(() => {
    if (currentStep === 0 && newMealData.isedit) {
      if (newMealData && newMealData.mtype && newMealData.isedit) {
        setActiveMeal(newMealData.mtype);
      }
      if (
        newMealData &&
        newMealData.isedit &&
        newMealData?.food?.etime &&
        !mesHour
      ) {
        const [h, m] = getHourMinutesFromTxt(newMealData.etime);
        setMesHour(padStart(h, 2, "0"));
        setMesMinute(padStart(m, 2, "0"));
      }
      setCurrentStep(2);
    }
  }, [
    newMealData,
    daystartData,
    currentStep,
    setMesHour,
    setMesMinute,
    mesHour,
    setActiveMeal,
  ]);

  const handleFoodChange = useCallback(() => {
    const newEdit = newMealData.isedit ? "edit" : "new";
    const mealtype = activeMeal.substring(0, 1);
    const oldFoods = currentChecklist[`foods_type_${mealtype}`] || [];
    const food = produce(newMealData.food, (draft) => {
      set(draft, "etime", `${mesHour || "00"}${mesMinute || "00"}`);
      if (newMealData.isnew) {
        set(draft, "id", `${draft.id}--${now}`);
      }
    });
    const cl = produce(currentChecklist, (draft) => {
      switch (newEdit) {
        case "new":
          draft[`foods_type_${mealtype}`] = produce(oldFoods, (draft) => {
            draft.push(food);
          });
          break;
        case "edit":
          draft[`foods_type_${mealtype}`] = oldFoods.map((item) => {
            if (item.id === newMealData.id) {
              return food;
            }
            return item;
          });
          break;
        default:
      }
    });
    setCurrentchecklist(cl);
    const pc = produce(caseN || {}, (draft) => {
      set(draft, `checklists.${cl.id}`, cl);
    });
    setNewMealData({});
    setCaseN(pc);
    AppUtils.putS3Data(`user/${cognitoUser.sub}/case.json`, pc);
  }, [
    setCurrentchecklist,
    setNewMealData,
    caseN,
    cognitoUser,
    now,
    setCaseN,
    newMealData,
    activeMeal,
    currentChecklist,
    mesHour,
    mesMinute,
  ]);

  return (
    <CompS>
      {currentStep === 0 && (
        <>
          <TitleBar
            label="Wann hat Dein Tag begonnen?"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepsHistory={stepsHistory}
            setStepsHistory={setStepsHistory}
            onClose={onClose}
          />
          <Section>
            <TimePicker
              nolabel
              hour={daystartData?.hour}
              minute={daystartData?.minute}
              callback={(h, m) => {
                if (
                  h &&
                  m &&
                  (daystartData?.hour !== h || daystartData?.minute !== m)
                ) {
                  setCurrentchecklist(
                    produce(currentChecklist, (draft) => {
                      draft.daystart = `${padStart(h, 2, "0")}${padStart(
                        m,
                        2,
                        "0"
                      )}`;
                    })
                  );
                }
              }}
            />
          </Section>

          {daystartData?.hour && daystartData?.minute && (
            <Section>
              <KKButton
                color={COLORS.ORANGE}
                onClick={() => {
                  gotoStep(1);
                }}
              >
                Weiter
              </KKButton>
            </Section>
          )}
        </>
      )}
      {currentStep === 1 && (
        <>
          <TitleBar
            label="Mahlzeit auswählen"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepsHistory={stepsHistory}
            setStepsHistory={setStepsHistory}
            onClose={onClose}
          />
          <Section>
            <div className="mealbuttons">
              {MEALS.map((m) => (
                <button
                  className={
                    !activeMeal || activeMeal === m.id ? "active" : "inactive"
                  }
                  key={m.id}
                  onClick={() => {
                    if (activeMeal === m.id) {
                      setActiveMeal(null);
                      return;
                    }
                    setActiveMeal(m.id);
                  }}
                >
                  <img alt={m.label} src={m.img} />
                </button>
              ))}
            </div>
          </Section>

          {activeMeal && (
            <Section>
              <KKButton
                color={COLORS.ORANGE}
                onClick={() => {
                  gotoStep(2);
                }}
              >
                Weiter
              </KKButton>
            </Section>
          )}
        </>
      )}
      {currentStep === 2 && (
        <>
          <TitleBar
            label="Uhrzeit der Mahlzeit"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepsHistory={stepsHistory}
            setStepsHistory={setStepsHistory}
            onClose={onClose}
          />
          <Section>
            <TimePicker
              nolabel
              hour={mesHour}
              minute={mesMinute}
              callback={(h, m) => {
                setMesHour(padStart(h, 2, "0"));
                setMesMinute(padStart(m, 2, "0"));
              }}
            />
          </Section>

          {mesHour && mesMinute && (
            <Section>
              <KKButton
                color={COLORS.ORANGE}
                onClick={() => {
                  gotoStep(3);
                }}
              >
                Weiter
              </KKButton>
            </Section>
          )}
        </>
      )}
      {currentStep === 3 && (
        <>
          <TitleBar
            label="Lebensmittel"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepsHistory={stepsHistory}
            setStepsHistory={setStepsHistory}
            onClose={onClose}
          />
          {newMealData.food && (
            <>
              <Section>
                <FoodListItem
                  onFoodChange={(f) => {
                    setNewMealData(
                      produce(newMealData, (draft) => {
                        draft.food = f;
                      })
                    );
                  }}
                  food={get(newMealData, "food")}
                />
              </Section>
              <Section>
                <KKButton
                  color={COLORS.ORANGE}
                  onClick={() => {
                    handleFoodChange();
                    onClose();
                  }}
                >
                  Speichern
                </KKButton>
              </Section>
            </>
          )}
          <Section className="foodsearch">
            <div className="typeToggle">
              <button
                className={toolToggle === 0 ? "active" : "inactive"}
                onClick={() => {
                  toolToggle !== 0 && setToolToggle(0);
                }}
              >
                Suche
              </button>
              {favorites && favorites.length > 0 && (
                <button
                  className={toolToggle === 1 ? "active" : "inactive"}
                  onClick={() => {
                    toolToggle !== 1 && setToolToggle(1);
                  }}
                >
                  Favoriten
                </button>
              )}
            </div>
            {/* SEARCH */}
            {toolToggle === 0 && (
              <>
                <FoodSearch setFound={setFoundFoods} />
                <div className="typeToggle">
                  {foundFoods.foods && foundFoods.foods.length > 0 && (
                    <button
                      className={searchToggle === 0 ? "active" : "inactive"}
                      onClick={() => {
                        searchToggle === 0
                          ? setSearchToggle(1)
                          : setSearchToggle(0);
                      }}
                    >
                      Unsere Auswahl
                    </button>
                  )}
                  {foundFoods.foodsdb && foundFoods.foodsdb.length > 0 && (
                    <button
                      className={searchToggle === 1 ? "active" : "inactive"}
                      onClick={() => {
                        searchToggle === 1
                          ? setSearchToggle(0)
                          : setSearchToggle(1);
                      }}
                    >
                      Aus dem Handel
                    </button>
                  )}
                </div>
                {foundFoods.foods &&
                  foundFoods.foods.length > 0 &&
                  searchToggle === 0 && (
                    <>
                      <div className="searchresultsel">
                        <ReelSelector
                          visibleItems={5}
                          displayAttribute="title"
                          items={foundFoods.foods}
                          onChange={(evt) => {
                            evt &&
                              setNewFoodSelection({
                                isnew: true,
                                isedit: false,
                                source: "foods",
                                id: evt.id,
                              });
                          }}
                        />
                      </div>
                    </>
                  )}
              </>
            )}
            {/* FAVORITES */}
            {toolToggle === 1 && (
              <>
                <div className="searchresultsel">
                  <ReelSelector
                    visibleItems={5}
                    displayAttribute="i18n.de.definition.title"
                    items={favorites}
                    onChange={(evt) => {
                      evt &&
                        setNewFoodSelection({
                          isnew: true,
                          isedit: false,
                          source: "favorites",
                          id: evt._id,
                        });
                    }}
                  />
                </div>
              </>
            )}
          </Section>
        </>
      )}
      {/*steps.map((step, index) => currentStep === step.id ? step.content : null) */}
    </CompS>
  );
};

export default Comp;
