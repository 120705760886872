import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { COLORS } from '../../constants';

const InfoBox = (props) => {
  const { visible = true, dismiss, mode } = props;
  return <>
    {visible && <InfoBoxS className={`${mode} ${dismiss && 'dismissable'}`} onClick={dismiss && dismiss}>{props.children}{dismiss && <Icon name="remove" />}</InfoBoxS>}
  </>
};

export default InfoBox;

const InfoBoxS = styled.div`
  position:relative;
  margin:1rem 0;
  background:rgba(255,255,255,0.05);
  padding:1rem 1rem;
  border-radius:1.5rem;
  &.dismissable{
    padding-right:1.9rem;
  }
  i{
    font-weight:normal;
    position:absolute;
    top:0.5rem;
    right:0.5rem;
    color:rgba(255,255,255,0.5);
  }
  &.dark{
    background:${COLORS.BLUE};
    color:white;
  }
  &.light{
    background:white;
    color:${COLORS.BLUE};
    i{
      color:${COLORS.BLUE};
    }
  }
`;