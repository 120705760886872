import { get, set } from "lodash";
import { AppUtils, numDateFromTxt } from "../../utils";
import { produce } from "immer";

export const getTodayPlan = (day, caseN, plantype) => {
  let p = {};
  (get(caseN, "plans", []) || []).forEach((pl) => {
    if (
      numDateFromTxt(get(pl, "from", "99999999")) <= numDateFromTxt(day) &&
      numDateFromTxt(get(pl, "to", "00000000")) >= numDateFromTxt(day) &&
      get(pl, "data.definition.type", "") === plantype
    ) {
      p = setI18N(pl);
    }
  });
  return p;
};
const setI18N = (plan) => {
  const lang = AppUtils.getUserLang();
  if (get(plan, `data.i18n.${lang}`, false)) {
    const sectionsI18NDic = {};
    get(plan, `data.i18n.${lang}.definition.sections`, []).forEach(
      (section) => {
        set(sectionsI18NDic, `sec-${get(section, "key")}`, {
          title: get(section, "title"),
          content: get(section, "content"),
        });
        get(section, "subsections", []).forEach((subsection) => {
          set(
            sectionsI18NDic,
            `sec-${get(section, "key")}.sub-${get(subsection, "key")}`,
            subsection
          );
        });
      }
    );
    plan = produce(plan, (draft) => {
      set(
        draft,
        "data.definition.sections",
        get(plan, "data.definition.sections", []).map((section) =>
          produce(section, (sectionDraft) => {
            sectionDraft.title = get(
              sectionsI18NDic,
              `sec-${get(section, "key")}.title`,
              ""
            );
            sectionDraft.content = get(
              sectionsI18NDic,
              `sec-${get(section, "key")}.content`,
              ""
            );
            sectionDraft.subsections = get(section, "subsections", []).map(
              (subsection) =>
                produce(subsection, (subsectionDraft) => {
                  subsectionDraft.title = get(
                    sectionsI18NDic,
                    `sec-${get(section, "key")}.sub-${get(
                      subsection,
                      "key"
                    )}.title`,
                    ""
                  );
                  subsectionDraft.content = get(
                    sectionsI18NDic,
                    `sec-${get(section, "key")}.sub-${get(
                      subsection,
                      "key"
                    )}.content`,
                    ""
                  );
                  subsectionDraft.hasFood = get(subsection, "foodlist", false);
                })
            );
          })
        )
      );
    });
  }
  return plan;
};
