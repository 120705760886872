import { useState, useEffect } from "react";
import { Auth } from "aws-amplify";
import { API } from "../../constants";
import request from "superagent";
import { get, set } from "lodash";
import { useRecoilValue, useRecoilState, useSetRecoilState } from "recoil";
import { accountState, tokenState, cognitoUserState } from "../../atoms";
import { AppUtils } from "../../utils";
import produce from "immer";

export default function useUvidaUserCheck(props) {
  const [userId, setUserId] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [account, setAccount] = useRecoilState(accountState);
  const cognitoUser = useRecoilValue(cognitoUserState);
  const setToken = useSetRecoilState(tokenState);

  useEffect(() => {
    Auth.currentSession().then((data) => {
      const t = get(data, "idToken.jwtToken", "");
      setToken(t);
      if (!t) {
        return;
      }
      let email = get(account, "uvida.email", get(account, "email"));
      if (account && email) {
        setEmail(email);
        setLoading(true);
        request
          .get(`${API}/uvida/user?email=${email}`)
          .set("Authorization", `Bearer ${t}`)
          .then((response) => {
            const acc = produce(account, (draft) => {
              set(draft, "uvida.id", response.body.id);
            });
            AppUtils.putS3Data(`user/${cognitoUser.sub}/account.json`, acc);
            setAccount(acc);
            setUserId(response.body.id);
            setLoading(false);
          })
          .catch((err) => {
            setUserId("");
            setLoading(false);
          });
      }
    });
  }, [account, setToken, cognitoUser, setAccount]);

  return {
    foundUvidaUser: userId,
    loadingUvidaUser: loading,
    emailUvidaUser: email,
  };
}
