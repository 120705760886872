import { LABELS } from "./labels";
import { get } from "lodash";
import { indexOf as _indexOf } from "lodash";
import dateFormat from "date-fns/format";
import { add, parse } from "date-fns";
import localeDe from "date-fns/locale/de";
import request from "superagent";
//--------------import { fromJS, List } from 'immutable';
import { Auth, Storage, Cache } from "aws-amplify";

const labelData = get(LABELS, "data");
const re = /font-[^;]+/g;

export const buttonLabel = (id, lang) => {
  return htmlDecode(get(labelData, `${id}.${lang || "de"}`)).replace(
    /<[^>]+>/g,
    ""
  );
};

export const labelWithHtml = (id, lang) => {
  return htmlDecode(get(labelData, `${id}.${lang || "de"}`));
};

export const htmlDecode = (input) => {
  const doc = new DOMParser().parseFromString(
    (input || "").replaceAll(re, ""),
    "text/html"
  );
  return doc.documentElement.textContent;
};

const weekdays = [
  "Sonntag",
  "Montag",
  "Dienstag",
  "Mittwoch",
  "Donnerstag",
  "Freitag",
  "Samstag",
];
export class AppUtils {
  static getS3Data(path) {
    return Storage.get(path)
      .then((uri) => request.get(uri).set("Accept", "application/json"))
      .then((response) => {
        return response.body;
      });
  }

  static getCachedS3Data(path, ignoreCache) {
    const dataFromCache = ignoreCache ? null : Cache.getItem(path);
    if (dataFromCache) {
      return Promise.resolve(dataFromCache);
    }
    return Storage.get(path)
      .then((uri) => request.get(uri).set("Accept", "application/json"))
      .then((response) => {
        Cache.setItem(path, response.body);
        return response.body;
      });
  }

  static getS3ItemsFromPath(path) {
    return Storage.list(path).then((result) =>
      Promise.all(
        result.results
          .filter((r) => r.key.includes("json"))
          .map((r) => AppUtils.getS3Data(r.key))
      )
    );
  }
  static listS3ItemsWithPrefix(prefix) {
    return Storage.list(prefix).then((result) =>
      Promise.all(
        result.results
      )
    );
  }

  static getS3ItemsFromPathCached(path) {
    return Storage.list(path).then((result) =>
      Promise.all(
        result
          .filter((r) => r.key.includes("json"))
          .map((r) => AppUtils.getCachedS3Data(r.key))
      )
    );
  }

  static putS3Binary(path, content, contentType) {
    return Storage.put(path, content, {
      contentType,
    });
  }
  static putS3Data(path, content, noRefresh) {
    return Storage.put(path, JSON.stringify(content), {
      contentType: "application/json",
    }).then(() => {
      if (!noRefresh) {
        return AppUtils.getCachedS3Data(path, true);
      }
      return AppUtils.getCachedS3Data(path, false);
    });
  }

  static delS3Data(path) {
    return Storage.remove(path);
  }

  static logout() {
    return Auth.signOut();
  }

  static getSession() {
    return new Promise((resolve) => {
      Auth.currentSession()
        .then((session) => {
          if (session.accessToken) {
            Auth.currentAuthenticatedUser()
              .then((user) => {
                resolve({
                  valid: true,
                  token: session.accessToken.jwtToken,
                  username: user.username,
                });
              })
              .catch(() => resolve({ valid: false, token: null }));
          } else {
            resolve({ valid: false, token: null });
          }
        })
        .catch(() => resolve({ valid: false, token: null }));
    });
  }

  static getUserLang() {
    let lang = navigator.language.split("-");
    lang[0] = lang[0] || "de";
    lang = _indexOf(["de", "en"], lang[0]) >= 0 ? lang[0] : "de";
    return lang;
  }

  static formatTime(pTime) {
    const lpadString = "00001234";
    return `${lpadString.substr(-4, 2)}:${lpadString.substr(-2, 2)}`;
  }

  static getToday() {
    return getToday();
  }

  static formatDateFromId(id) {
    return formatDateFromId(id);
  }

  static formatDateFromTxt(id) {
    return formatDateFromTxt(id);
  }

  static getDayMonthFromTxt(id) {
    return getDayMonthFromTxt(id);
  }
}
export const getToday = () => {
  return parseInt(dateFormat(new Date(), "yyyyMMdd"), 10);
};

export const getDay8PlusDays = (d8, d) => {
  return parseInt(
    dateFormat(add(parse(d8, "yyyyMMdd", new Date()), { days: d }), "yyyyMMdd"),
    10
  );
};

export const numDateFromTxt = (input) => {
  return parseInt(input.replace(/-/g, ""), 10);
};

export const getHourMinutesFromTxt = (input) => {
  let h = "00";
  let m = "00";
  if (input.length === 4) {
    h = input.substr(0, 2);
    m = input.substr(2, 2);
  }
  return [h, m];
};

export const formatDateFromId = (id) => {
  const d = id.substr(7, 2);
  const m = id.substr(5, 2);
  const y = id.substr(1, 4);
  const dd = new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10));
  const wd = weekdays[dd.getDay()];
  return `${d}.${m}.${y} (${wd})`;
};

export const formatDateFromTxt = (id) => {
  const d = id.substr(6, 2);
  const m = id.substr(4, 2);
  const y = id.substr(0, 4);
  const dd = new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10));
  const wd = weekdays[dd.getDay()];
  return `${d}.${m}.${y} (${wd})`;
};

export const getDayMonthFromTxt = (id) => {
  const d = id.substr(6, 2);
  const m = id.substr(4, 2);
  const y = id.substr(0, 4);
  const dd = new Date(parseInt(y, 10), parseInt(m, 10) - 1, parseInt(d, 10));
  return {
    day: dateFormat(dd, "dd", { locale: localeDe }),
    month: dateFormat(dd, "MM", { locale: localeDe }),
    year: dateFormat(dd, "yyyy", { locale: localeDe }),
  };
};

export const validToday = (fromString, toString) =>
  numDateFromTxt(fromString) <= getToday() &&
  numDateFromTxt(toString) >= getToday();

/*
export const prepareStaticAppData = (datasets, docType) => {
  const qsps = fromJS(datasets).map(r => ({
    id: r.get('_id'),
    title: r.getIn(['i18n', 'de', 'definition', 'title']),
  }));
  const transL = {};
  qsps.forEach(elem => {
    transL[elem.id] = elem.title;
  });
  let index = new Map();

  if (docType === 'kk_recipes') {
    fromJS(datasets).forEach(doc => {
      index = index.set(
        doc
          .getIn(
            ['i18n', AppUtils.getUserLang(), 'definition', 'title'],
            'kein Titel',
          )
          .substring(0, 1)
          .toLowerCase(),
        new List(),
      );
    });
    fromJS(datasets).forEach(doc => {
      const title = doc.getIn(
        ['i18n', AppUtils.getUserLang(), 'definition', 'title'],
        'kein Titel',
      );
      const a = title.substring(0, 1).toLowerCase();
      index = index.set(
        a,
        index.get(a).push(
          new Map({
            id: doc.get('_id'),
            title: title.length > 0 ? title : 'kein Titel',
          }),
        ),
      );
    });
  }
  if (docType === 'kk_plan') {
    fromJS(datasets)
      .filterNot(d => d.get('status', '') === 'deleted')
      .forEach(doc => {
        index = index.set(
          doc.getIn(['definition', 'type'], 'kein typ'),
          new List(),
        );
      });
    fromJS(datasets)
      .filterNot(d => d.get('status', '') === 'deleted')
      .forEach(doc => {
        const plantype = doc.getIn(['definition', 'type'], 'kein typ');
        index = index.set(
          plantype,
          index.get(plantype).push(
            new Map({
              id: doc.get('_id'),
              title: doc.getIn(['definition', 'name'], 'kein titel'),
            }),
          ),
        );
      });
  }
  const docs = fromJS(datasets);
  return fromJS({
    index,
    transL,
    data: docs,
  });
};
*/
