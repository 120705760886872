import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useRecoilValue, useRecoilState } from "recoil";
import { accountState, cognitoUserState } from "../../atoms";
import { Button, Icon, Input } from "semantic-ui-react";
import { AppUtils } from "../../utils";
import { COLORS } from "../../constants";
import { get, set } from "lodash";
import { produce } from "immer";
import appleicon from "./apple.png";
import googleicon from "./google.png";
import uvidaappicon from "./logo.png";
import useUvidaUserCheck from "./useUvidaUserCheck";

const Comp = (props) => {
  const [emailUvida, setEmailUvida] = useState("");
  const [uvidaState, setUvidaState] = useState(0);
  const [account, setAccount] = useRecoilState(accountState);
  const { foundUvidaUser, loadingUvidaUser, emailUvidaUser } =
    useUvidaUserCheck();
  const cognitoUser = useRecoilValue(cognitoUserState);

  const saveUvidaEmail = useCallback(() => {
    const acc = produce(account, (draft) => {
      set(draft, "uvida.email", emailUvida);
    });
    AppUtils.putS3Data(`user/${cognitoUser.sub}/account.json`, acc);
    setAccount(acc);
  }, [account, cognitoUser, setAccount, emailUvida]);

  useEffect(() => {
    setEmailUvida(get(account, "email", get(account, "uvida.email", "")));
  }, [account]);

  return (
    <CompS>
      <div className="conn">
        <div className="head">
          <a href="uvida:home">
            <img alt="uVida" src={uvidaappicon} />
          </a>
          {foundUvidaUser ? (
            <div className="uvr">
              <div>Verbunden über {emailUvidaUser}</div>
              <Icon name="checkmark" color="##305444" />
            </div>
          ) : (
            <div>
              <button
                className={`uvb ${uvidaState > 0 ? "inactive" : "active"}`}
                onClick={() => {
                  setUvidaState(uvidaState > 0 ? 0 : 1);
                }}
              >
                Verbinden
              </button>
            </div>
          )}
        </div>

        {uvidaState > 0 && (
          <div className="connassist">
            <div id="uvidastateswitch">
              {uvidaState === 1 && (
                <div className="assiststate">
                  <div>Haben Sie bereits die uVida-App installiert?</div>
                  <div className="btns">
                    <button
                      className="uvb"
                      onClick={() => {
                        setUvidaState(3);
                      }}
                    >
                      Ja
                    </button>
                    <button
                      className="uvb"
                      onClick={() => {
                        setUvidaState(2);
                      }}
                    >
                      Nein
                    </button>
                  </div>
                </div>
              )}
              {uvidaState === 2 && (
                <div className="assiststate">
                  <div>
                    Bitte installieren Sie die uVida-App aus dem App-Store
                  </div>
                  <div className="appstores">
                    <a
                      href="https://play.google.com/store/apps/details?id=de.unostics.uvida&gl=DE"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        decoding="async"
                        src={googleicon}
                        alt=""
                        loading="lazy"
                      />
                    </a>
                    <a
                      href="https://apps.apple.com/de/app/uvida/id1658531706"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        decoding="async"
                        src={appleicon}
                        alt=""
                        loading="lazy"
                      />
                    </a>
                  </div>
                  <div className="btns">
                    <button
                      className="uvb"
                      onClick={() => {
                        setUvidaState(3);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              )}
              {uvidaState === 3 && (
                <div className="assiststate">
                  <div>
                    Bitte hinterlegen Sie hier die Emailadresse, mit der Sie bei
                    uVida registriert sind
                  </div>
                  <Input
                    className="einput"
                    value={emailUvida}
                    onChange={(e, d) => {
                      setEmailUvida(d.value);
                    }}
                    action={
                      <Button
                        loading={loadingUvidaUser}
                        basic
                        floating
                        icon="linkify"
                        onClick={() => {
                          saveUvidaEmail();
                        }}
                      />
                    }
                    placeholder="Email Adresse"
                  />
                  <div className="btns">
                    <button
                      className="uvb"
                      onClick={() => {
                        saveUvidaEmail();
                        setUvidaState(4);
                      }}
                    >
                      OK
                    </button>
                  </div>
                </div>
              )}
              {uvidaState === 4 && (
                <div className="assiststate">
                  <div>
                    Sie werden nach Drücken des Buttons weitergeleitet zu uVida,
                    um nutrick24 mit Ihrem uVida-Konto zu verknüpfen
                  </div>
                  <div className="btns">
                    <a
                      className="uvb"
                      target="_blank"
                      href="https://link.uvida.app/?link=https://my.uvida.app/%23/register/d525788c-0a09-4556-9bd4-f8174fb516aa&apn=de.unostics.uvida&afl=https://my.uvida.app/%23/register/d525788c-0a09-4556-9bd4-f8174fb516aa&isi=1658531706&ibi=de.unostics.uvida&ifl=https://my.uvida.app/%23/register/d525788c-0a09-4556-9bd4-f8174fb516aa"
                      rel="noreferrer"
                    >
                      nutrick24 mit uVida verknüpfen
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {uvidaState === 0 && (
          <div
            className={`othercontent ${foundUvidaUser ? "connected" : "not-connected"
              }`}
          >
            {props.children}
          </div>
        )}
      </div>
    </CompS>
  );
};

export default Comp;

const CompS = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  .othercontent.not-connected {
    .not-connected {
      display: block;
    }
    .connected {
      display: none;
    }
  }
  .othercontent.connected {
    .not-connected {
      display: none;
    }
    .connected {
      display: block;
    }
  }
  .uvidaAppButton {
    border: 1px solid white;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    color: white;
    border-radius: 1rem;
  }
  .conn {
    button.uvb,
    a.uvb {
      cursor: pointer;
      border-radius: 1.5rem;
      background: white;
      color: #305444;
      box-shadow: none;
      border: 1px solid #305444;
      padding: 0.5rem 1rem;
      &.inactive {
        background: transparent;
      }
    }
    padding: 1rem;
    border: 1px solid white;
    border-radius: 1.5rem;
    background-color: transparent;
    background-image: linear-gradient(140deg, #305444 0%, #82b5a0 100%);
    width: 100%;
    .head {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
    #uvidastateswitch {
      .assiststate {
        .btns {
          padding: 0.5rem 0;
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          gap: 1rem;
          > * {
            flex: 1 1 auto;
          }
        }
      }
    }
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
    color: white;
    .uvr {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
    }
    img {
      height: 2rem;
      margin: 0;
      border: none;
      padding: 0;
      display: block;
    }
    .ui.button.basic {
      background: white !important;
      border-top-right-radius: 2rem !important;
      border-bottom-right-radius: 2rem !important;
      box-shadow: none !important;
      border-right: 1px solid #305444;
      border-top: 1px solid #305444;
      border-bottom: 1px solid #305444;
    }
  }
  .ui.input {
    input {
      line-height: 1.3rem;
      background: white !important;
      border-top-left-radius: 2rem !important;
      border-bottom-left-radius: 2rem !important;
      box-shadow: none !important;
      border: none;
      width: 40%;
      border-left: 1px solid ${COLORS.BLUE};
      border-top: 1px solid ${COLORS.BLUE};
      border-bottom: 1px solid ${COLORS.BLUE};
    }
  }
  .einput {
    width: 100%;
    margin: 1rem 0;
  }

  .appstores {
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    img {
      height: 3rem;
    }
  }
`;
