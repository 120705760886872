import React, { useState } from "react";
import { Form } from "semantic-ui-react";
import { Auth } from "aws-amplify";
import useNavigate from "../../useNavigate";
import Logo from "../../images/nutrick24_Logo.png";
import Error from "../../components/Error";
import { buttonLabel, labelWithHtml } from "../../utils.js";
import { KKButton } from "../../components/Buttons";
import { COLORS } from "../../constants";
import { useSetRecoilState, useRecoilValue } from "recoil";
import { cognitoUserState, praxisVersionState } from "../../atoms";
import LoginComp from "./LoginComp";
import LoginForm from "./LoginForm";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const setCognitoUser = useSetRecoilState(cognitoUserState);
  const praxisVersion = useRecoilValue(praxisVersionState);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const callSignIn = () => {
    Auth.signIn(email, password)
      .then((u) => {
        setCognitoUser(u.attributes);
        navigate("/");
      })
      .catch((err) => setError(err));
  };

  return (
    <LoginComp>
      <a href="/" title="home">
        <img alt="Nutrick24" className="logo" src={Logo} />
      </a>
      <div className="info">
        Du hast noch kein Konto?{" "}
        <a
          rel="noreferrer"
          href={praxisVersion ? "https://app.nutrick24.de/signup" : "/signup"}
          onClick={() => {
            !praxisVersion && navigate("/signup");
          }}
        >
          Hier geht's zur Registrierung
        </a>
      </div>
      <LoginForm>
        <Form.Input
          name="email"
          label={buttonLabel("EMail_Adresse", "de")}
          placeholder={buttonLabel("EMail_Adresse", "de")}
          onChange={(e, d) => {
            setEmail(d.value.toLowerCase());
          }}
          value={email}
        />
        <Form.Input
          name="pw"
          error={
            error && error.code === "NotAuthorizedException"
              ? {
                content: (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: labelWithHtml(
                        "SignIn_Benutzer_Passwort_falsch",
                        "de"
                      ),
                    }}
                  />
                ),
              }
              : null
          }
          placeholder={buttonLabel("Passwort", "de")}
          label={buttonLabel("Passwort", "de")}
          type="password"
          onChange={(e, d) => {
            setPassword(d.value);
          }}
          value={password}
        />
        {error && (
          <Error>
            {error.code === "UserNotFoundException" ? (
              // eslint-disable-next-line indent
              <div
                dangerouslySetInnerHTML={{
                  __html: labelWithHtml(
                    "SignIn_Benutzer_existiert_nicht",
                    "de"
                  ),
                }}
              />
            ) : null}
          </Error>
        )}
        <KKButton onClick={callSignIn} color={COLORS.BLUE} label="Einloggen" />
      </LoginForm>
      <div className="infom">
        Passwort vergessen? <br />
        <a
          rel="noreferrer"
          href="/lostpassword"
          onClick={() => {
            navigate("/lostpassword");
          }}
        >
          Klicken Sie einfach HIER um ein neues Passwort zu vergeben.
        </a>
      </div>
    </LoginComp >
  );
};
export default Login;
