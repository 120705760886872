import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { Icon, Form } from "semantic-ui-react";
import { get, set } from "lodash";
import { produce } from "immer";
import { useParams } from "react-router-dom";
import { cognitoUserState, caseState } from "../../atoms";
import { useRecoilState, useRecoilValue } from "recoil";

import { arraysAreEqual, checkCondition } from "./utils";
import { AppUtils, htmlDecode, buttonLabel } from "../../utils";
import { COLORS, CONTENTS_BASE_URL } from "../../constants";
import { TitleBar } from "../common";
import { KKButton } from "../Buttons";

const Quiz = (props) => {
  const { id, course } = useParams();
  const [quiz, setQuiz] = useState({});
  const [quizTemplate, setQuizTemplate] = useState({});
  const [quota, setQuota] = useState();
  const cognitoUser = useRecoilValue(cognitoUserState);
  const [currentSectionID, setCurrentSectionID] = useState("A");
  const [pcase, setPcase] = useRecoilState(caseState);
  const [check, setCheck] = useState(false);

  // fetch quiz template
  useEffect(() => {
    AppUtils.getS3Data(`data/${course}_q_${id}.json`).then((response) => {
      console.log(response);
      setQuizTemplate(get(response, "data", {}) || {});
    });
  }, [id, course]);

  const savePcase = useCallback(
    (attr, val) => {
      const pc = produce(pcase, (draft) => {
        set(draft, attr, val);
      });
      setPcase(pc);
      AppUtils.putS3Data(`user/${cognitoUser.sub}/case.json`, pc);
    },
    [cognitoUser, pcase, setPcase]
  );

  // set current section
  const currentSection = useMemo(() => {
    return get(quizTemplate, currentSectionID, {});
  }, [currentSectionID, quizTemplate]);

  const questionKeys = useMemo(
    () => Object.keys(get(currentSection, "questions", {}) || {}) || [],
    [currentSection]
  );
  const sectionKeys = useMemo(
    () => Object.keys(quizTemplate || {}) || [],
    [quizTemplate]
  );

  // handle single value change
  const handleChange = useCallback(
    (k, v) => {
      setQuiz(
        produce(quiz, (draftState) => {
          draftState[k] = v;
        })
      );
    },
    [quiz]
  );
  // handle multivalue (checkbox)
  const handleChangeCheckbox = useCallback(
    (k, v) => {
      setQuiz(
        produce(quiz, (draftState) => {
          if (!draftState[k]) {
            draftState[k] = [];
          }
          if (draftState[k].includes(v)) {
            draftState[k] = draftState[k].filter((dv) => dv !== v);
          } else {
            draftState[k].push(v);
          }
        })
      );
    },
    [quiz]
  );

  // check quota
  const docheck = useCallback(() => {
    let oks = 0;
    questionKeys.forEach((k) => {
      const question = get(currentSection, `questions.${k}`, {});
      const okAnswers = question.o.filter((opt) => opt.ok).map((opt) => opt.id);
      let selectedValues = [];
      if (Array.isArray(get(quiz, k, ""))) {
        selectedValues = [...get(quiz, k, "")];
      } else {
        selectedValues.push(get(quiz, k, ""));
      }
      if (arraysAreEqual(okAnswers, selectedValues)) {
        oks++;
      }
    });
    const q = Math.round((oks / questionKeys.length) * 100);
    if (q >= 80) {
      savePcase(`course.${course}.quiz.${id}.ok`, true);
    }
    setQuota(q);
  }, [quiz, questionKeys, currentSection, course, id, savePcase]);

  const next = useCallback(() => {
    window.scrollTo(0, 0);
    setCurrentSectionID(sectionKeys[sectionKeys.indexOf(currentSectionID) + 1]);
  }, [sectionKeys, currentSectionID]);

  const reset = () => {
    setQuota(0);
    setQuiz({});
    setCheck(false);
  };

  return (
    <div>
      <TitleBar label="Quiz" />
      <Comp>
        {check ? (
          <div className="result">
            <div>Sie haben {quota}% der Fragen richtig beantwortet.</div>
            {quota < 80 && (
              <>
                <div>
                  Um das Quiz erfolgreich abzuschliessen müssen 80% der Fragen
                  korrekt beantwortet sein.
                </div>
                <KKButton color={COLORS.BLUE} onClick={reset}>
                  Nochmal versuchen
                </KKButton>
              </>
            )}
            <div className="resultList">
              <Section>
                {Object.keys(get(currentSection, "questions", {}) || {}).map(
                  (k) => {
                    const question = get(currentSection, `questions.${k}`, {});
                    const okAnswers = question.o
                      .filter((opt) => opt.ok)
                      .map((opt) => opt.id);
                    let selectedValues = [];
                    if (Array.isArray(get(quiz, k, ""))) {
                      selectedValues = [...get(quiz, k, "")];
                    } else {
                      selectedValues.push(get(quiz, k, ""));
                    }
                    const ok = arraysAreEqual(okAnswers, selectedValues);
                    return (
                      <Question key={k}>
                        <h2>
                          <Icon
                            name={ok ? "checkmark" : "remove"}
                            color={ok ? "green" : "red"}
                          />
                          {htmlDecode(get(question, "q", ""))}{" "}
                        </h2>
                      </Question>
                    );
                  }
                )}
              </Section>
            </div>
          </div>
        ) : (
          <>
            <Form>
              <Section>
                {get(currentSection, "img", "") && (
                  <img
                    alt=""
                    style={{ marginBottom: "25px" }}
                    src={`${CONTENTS_BASE_URL}${get(
                      currentSection,
                      "img",
                      ""
                    )}`}
                  />
                )}

                {Object.keys(get(currentSection, "questions", {}) || {}).map(
                  (k) => {
                    const question = get(currentSection, `questions.${k}`, {});
                    const qType = get(question, "t", "");
                    const qImage = get(question, "i", "");
                    const func = get(question, "f", "");
                    const qOptions = get(question, "o", []);
                    const qConditions = get(question, "condition", []);
                    const show = checkCondition(quiz, qConditions);
                    return (
                      <Question key={k} className={show ? "visible" : "hidden"}>
                        <h2>{htmlDecode(get(question, "q", ""))} </h2>
                        {qImage && (
                          <img
                            alt=""
                            style={{ maxWidth: "100%", maxHeight: "100vh" }}
                            src={`${CONTENTS_BASE_URL}${qImage}`}
                          />
                        )}
                        {qType === "radio" && (
                          <div className="info">Bitte eine Antwort wählen</div>
                        )}
                        {qType === "checkbox" && (
                          <div className="info">
                            Bitte alle richtigen Antworten auswählen
                          </div>
                        )}
                        {qType === "radio" && (
                          <RadioGroup>
                            {qOptions
                              .filter((o) =>
                                checkCondition(quiz, get(o, "condition", []))
                              )
                              .map((o) => {
                                const optionId = get(o, "id");
                                return (
                                  <KKRadio
                                    key={optionId}
                                    onClick={() => handleChange(k, optionId)}
                                  >
                                    <Icon
                                      name={
                                        get(quiz, k) === optionId
                                          ? "check circle"
                                          : "circle outline"
                                      }
                                    />
                                    <label>{get(o, "t")}</label>
                                  </KKRadio>
                                );
                              })}
                          </RadioGroup>
                        )}
                        {qType === "checkbox" && (
                          <RadioGroup>
                            {qOptions
                              .filter((o) =>
                                checkCondition(quiz, get(o, "condition", []))
                              )
                              .map((o) => {
                                const optionId = get(o, "id");
                                return (
                                  <KKRadio
                                    key={optionId}
                                    onClick={() =>
                                      handleChangeCheckbox(k, optionId)
                                    }
                                  >
                                    <Icon
                                      name={
                                        (get(quiz, k, []) || []).includes(
                                          optionId
                                        )
                                          ? "check square"
                                          : "square outline"
                                      }
                                    />
                                    <label>{get(o, "t")}</label>
                                  </KKRadio>
                                );
                              })}
                          </RadioGroup>
                        )}
                        {qType === "number" && (
                          <NumberInput
                            type="number"
                            onChange={(e) => {
                              handleChange(k, e.currentTarget.value);
                            }}
                            value={get(quiz, k, "")}
                          />
                        )}
                        {qType === "keyfigure" && (
                          <NumberInput
                            type="number"
                            disabled
                            value={get(quiz, func, "")}
                          />
                        )}
                        {qType === "text" && (
                          <TextInput
                            onChange={(e) =>
                              handleChange(k, e.currentTarget.value)
                            }
                            defaultValue=""
                            value={get(quiz, k, "")}
                          />
                        )}
                        {qType === "textarea" && (
                          <textarea
                            rows="5"
                            onChange={(e) =>
                              handleChange(k, e.currentTarget.value)
                            }
                            defaultValue=""
                            value={get(quiz, k, "")}
                          />
                        )}
                      </Question>
                    );
                  }
                )}
              </Section>
            </Form>
            <div className="buttons">
              {currentSectionID !== sectionKeys[sectionKeys.length - 1] ? (
                <KKButton
                  color={COLORS.BLUE}
                  onClick={() => {
                    next();
                  }}
                >
                  {buttonLabel("Button_Anamnese_Weiter", "de")}
                </KKButton>
              ) : (
                <KKButton
                  color={COLORS.BLUE}
                  onClick={() => {
                    setCheck(true);
                    docheck();
                  }}
                >
                  {buttonLabel("Speichern", "de")}
                </KKButton>
              )}
            </div>
          </>
        )}
      </Comp>
    </div>
  );
};
export default Quiz;

const Comp = styled.div`
  img {
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding-bottom: 100px;
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
  .info {
    font-size: 0.8rem;
    color: ${COLORS.GREY_DARK};
    text-align: left;
  }
  .result {
    padding: 2rem 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 1rem;
    > div {
      width: 100%;
    }
  }
`;
const NumberInputStyled = styled.input`
  &:disabled {
    background: rgba(90, 90, 90, 0.1) !important;
    font-weight: bold;
  }
`;
const NumberInput = (props) => <NumberInputStyled {...props} type="tel" />;

const TextInput = (props) => <NumberInputStyled {...props} type="text" />;
const RadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  > * {
    margin: 0.25rem 1rem 0.25rem 0;
  }
  width: 100%;
  margin-top: 0.5rem;
`;
const KKRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: none;
  > i {
    flex: 0 0 auto;
  }
  label {
    line-height: 1.3em;
  }
`;
const Question = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${COLORS.BLUE_LIGHT};
  h2 {
    span {
      color: ${COLORS.ORANGE};
    }
    margin-bottom: 0;
  }
  &.hidden {
    display: none;
    margin: 0;
    padding: 0;
  }
  .missing {
    color: ${COLORS.ORANGE};
    text-align: left;
    font-size: 0.9em;
    width: 100%;
  }
`;
const Section = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  img {
    z-index: 0;
    max-height: 30vh;
  }
  h1 {
    color: ${COLORS.GREY_DARK}!important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: left !important;
    width: 100%;
  }
  h2 {
    color: ${COLORS.BLUE}!important;
    font-weight: bold;
    text-transform: none;
    font-size: 18px;
    line-height: 1.5rem;
    text-align: left !important;
    width: 100%;
  }
`;
