import styled from 'styled-components';
import { COLORS } from '../../constants';

const LoginComp = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex:1 1 100%;
  .logo{
    max-width:400px;
    width:70vw;
    margin:4rem 0 2rem 0;
  }
  .info{
    text-align:center;
    a{
      cursor:pointer;
      color: ${COLORS.BLUE};
    }
  }
  .infom{
    text-align:center;
    margin:2rem 0;
    a{
      cursor:pointer;
      color: ${COLORS.BLUE};
    }
  }
  a{
      cursor:pointer;
      color: ${COLORS.BLUE};
    }
`;
export default LoginComp;