/* eslint-disable eqeqeq */
import { get } from "lodash";
export const arraysAreEqual = (arr1, arr2) => {
  if (!Array.isArray(arr1) || !Array.isArray(arr2) || arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = arr1.slice().sort();
  const sortedArr2 = arr2.slice().sort();

  for (let i = 0; i < sortedArr1.length; i++) {
    if (sortedArr1[i] !== sortedArr2[i]) {
      return false;
    }
  }

  return true;
}
export const checkCondition = (data, conditions) => {
  let ok = true;
  for (let idx in conditions) {
    ok = false;
    const orCond = conditions[idx];
    if (checkAndConditions(data, orCond)) {
      ok = true;
      return ok;
    }
  }
  return ok;
}
export const checkAndConditions = (data, conditions) => {
  let okCount = 0;
  for (let idx in conditions) {
    let andOk = true;
    let andCond = conditions[idx];
    const { q, v, op, not } = andCond;
    const qv = get(data, q, '');
    switch (op) {
      case '=':
        if (qv == v) {
          andOk = !not
        } else {
          andOk = not
        }
        break;
      case 'contains':
        if (Array.isArray(qv)) {
          if (qv.includes(v)) {
            andOk = !not
          } else {
            andOk = not
          }
        } else {
          if (qv == v) {
            andOk = !not
          } else {
            andOk = not
          }
        }
        break;
      default:
        break;
    }
    if (andOk) { okCount++ }
  }
  return okCount == conditions.length;
}