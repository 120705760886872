import React, { useState } from 'react';
import { Form, Icon } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';
import useNavigate from '../../useNavigate';
import Logo from '../../images/nutrick24_Logo.png';
import Error from '../../components/Error';
import { buttonLabel, labelWithHtml } from '../../utils.js';
import { KKButton } from '../../components/Buttons';
import { COLORS } from '../../constants';
import LoginComp from './LoginComp';
import LoginForm from './LoginForm';

const LostPassword = (props) => {
  const [email, setEmail] = useState('');
  const [counter, setCounter] = useState(0);
  const [error, setError] = useState();
  const navigate = useNavigate();

  const callForgotPassword = () => {
    setCounter(counter + 1);
    Auth.forgotPassword(email)
      .then(() => navigate('/lostpasswordchange'))
      .catch(err => setError(err));
  };

  return <LoginComp>
    <a href="/" title="home">
      <img alt="Nutrick24" className="logo" src={Logo} />
    </a>
    <div className="info">
      <a href="/login" onClick={() => { navigate("/login") }}><Icon name="arrow left" />zurück zur Anmeldung</a>
    </div>
    <div className="infom"
      dangerouslySetInnerHTML={{
        __html: labelWithHtml('Passwort_vergessen_Erlaeuterung', 'de')
      }}
    />
    <LoginForm>
      <Form.Input
        name="email"
        label={buttonLabel('EMail_Adresse', 'de')}
        placeholder={buttonLabel('EMail_Adresse', 'de')}
        onChange={(e, d) => { setEmail(d.value) }}
        value={email}
      />

      {error && <Error>
        {error.code === 'NotAuthorizedException' || error.code === 'UserNotFoundException' ? (
          // eslint-disable-next-line indent
          <div
            dangerouslySetInnerHTML={{
              __html: labelWithHtml('SignIn_Benutzer_existiert_nicht', 'de')
            }}
          />
        ) : null}
      </Error>}
      <KKButton
        onClick={callForgotPassword}
        color={COLORS.BLUE}
        label={counter === 0 ? 'Passwort_vergessen_absenden'
          : 'Nochmal_senden'}
      />
    </LoginForm>
  </LoginComp>
}
export default LostPassword;