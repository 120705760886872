import React, { useEffect } from 'react';
import { TitleBar } from '../common';
import styled from 'styled-components';


const Appointment = (props) => {
  const {inline} = props;
  useExternalScript(`https://www.jameda.de/api/otb-widget?refid=81100194_1&version=1&ts=${Date.now()}`);
  return <div>
    {!inline&&<TitleBar label="Sprechstunde" />}
    <Jameda>
      <div id="jam-ota-info_81100194_1" />
    </Jameda>
  </div>
}

export default Appointment;

const Jameda = styled.div`
  margin:2rem 0 0 0;
`;
const useExternalScript = (url) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = url;
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};