import { useState } from 'react';

function useLocalStorage(key, initialValue) {
    // Zustand initialisieren mit dem Wert aus dem Local Storage oder dem initialen Wert
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = window.localStorage.getItem(key);
            return item ? JSON.parse(item) : initialValue;
        } catch (error) {
            console.log(error);
            return initialValue;
        }
    });

    // Eine Funktion, um den Wert im Local Storage und im Zustand zu aktualisieren
    const setValue = (value) => {
        try {
            // Den Wert in eine Zeichenkette umwandeln und im Local Storage speichern
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            setStoredValue(valueToStore);
            window.localStorage.setItem(key, JSON.stringify(valueToStore));
        } catch (error) {
            console.log(error);
        }
    };

    return [storedValue, setValue];
}

export default useLocalStorage;