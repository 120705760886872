import styled from 'styled-components';
import { COLORS } from '../../constants';
import { buttonLabel } from '../../utils.js';

const KKButton = (props) => {
  const { color, label } = props;
  return <KKStyledButton
    {...props}
    style={{
      color: color && color !== 'transparent' ? '' : COLORS.GREY,
      background: color || COLORS.INPUT_BACK,
    }}
    className={props.className || `hoverButton`}
  >
    {label && <span>{buttonLabel(label, 'de')}</span>}
    {props.children}
  </KKStyledButton>
};

export default KKButton;

const KKStyledButton = styled.button`
  box-shadow:none;
  border:none;
  border-radius: 2rem;
  color: white;
  font-size: 1rem;
  padding: 0.7rem 1rem;
  text-align: center;
  font-weight: bold;
  margin: 0.5rem 0;
  cursor:pointer;
`;