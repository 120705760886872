/* eslint-disable eqeqeq */
import React, { useState, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants';
import { get } from 'lodash';

const ReelSelector = props => {
  const { items: pitems = [], displayAttribute, selectedItem, onChange = () => { }, visibleItems, name: valuename } = props;
  const [currentItem, setCurrentItem] = useState(pitems.indexOf(selectedItem));
  const [initScrolled, setInitScrolled] = useState();
  const h = ((visibleItems || 5) * 35);
  const items = useMemo(() => {
    const it = [];
    const offsetItems = Math.floor((visibleItems || 5) / 2);
    for (let idx = 0; idx < offsetItems; idx++) {
      it.push({
        scrollPos: 0,
        hitFrom: 0,
        hitTo: 0,
        name: '',
        id: `b${idx}`,
      });
    }
    for (let idx = 0; idx < pitems.length; idx++) {
      it.push({
        scrollPos: idx * 35,
        hitFrom: idx * 35 + (h / 2) - 17.5,
        hitTo: idx * 35 + (h / 2) + 17.5,
        name: typeof pitems[idx] === 'object' ? get(pitems[idx], displayAttribute, '') : `${pitems[idx]}`,
        id: idx,
      });
    }
    for (let idx = 0; idx < offsetItems; idx++) {
      it.push({
        scrollPos: 0,
        hitFrom: 0,
        hitTo: 0,
        name: '',
        id: `a${idx}`,
      });
    }
    return it;
  }, [h, visibleItems, pitems, displayAttribute]);

  const wrapperRef = useRef(null);
  const [scrollTimeout, setScrollTimeout] = useState();

  const handleScroll = event => {
    //setScrollPos();
    const st = get(event, 'currentTarget.scrollTop', 0)
    clearTimeout(scrollTimeout);
    setScrollTimeout(setTimeout(() => {
      for (let idx in items) {
        const item = items[idx];
        if (Math.ceil(st + h / 2) >= item.hitFrom && Math.floor(st + h / 2) < item.hitTo) {
          setCurrentItem(item.id);
          wrapperRef.current.scrollTo(0, item.scrollPos, { behavior: "smooth" });
          break
        }
      }
    }, 200));


  };
  useEffect(() => {
    onChange(pitems[currentItem]);
  }, [currentItem, onChange, pitems]);


  if (!initScrolled) {
    setTimeout(() => {
      setInitScrolled(true);
      for (let idx in items) {
        const item = items[idx];
        if (item.id == currentItem) {
          (wrapperRef && wrapperRef.current) && wrapperRef.current.scrollTo({
            top: item.scrollPos,
            left: 0,
            behavior: 'smooth'
          });
          break
        }
      }

    }, 10)
  }

  return (<Comp visibleItems={visibleItems}>
    <BWrap>
      <Wrapper visibleItems={visibleItems} ref={wrapperRef} onScroll={handleScroll}>
        {items.map((item, idx) =>
          item.name ? <div
            key={`${valuename}_${item.id}`}
            className={`${item.id == currentItem ? 'current' : ''}`}
            onClick={() => { setCurrentItem(item.id) }}>
            {item.name}
          </div> : <div key={idx} onClick={() => { setCurrentItem(item.id) }}>
            &nbsp;
          </div>)}

      </Wrapper>
    </BWrap>
    <div id="selbordertop" />
    <div id="selborderbottom" />
  </Comp>);
}
export default ReelSelector;

const Comp = styled.div`
    position:relative;
    #selbordertop{
        width: 100%;
        width:calc(100% - 50px);
        right:25px;
        position:absolute;
        top:${props => (((props.visibleItems || 5) * 35) / 2) - 17.5}px;
        border-top:1px solid ${COLORS.BLUE};
        z-index:1000;
    }
    #selborderbottom{
        width:calc(100% - 50px);
        left:25px;
        position:absolute;
        top:${props => (((props.visibleItems || 5) * 35) / 2) + 17.5}px;
        border-top:1px solid ${COLORS.BLUE};
        z-index:1000;
    }
`;
const Wrapper = styled.div`
background: rgb(255,255,255);
background: linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(54,81,148,0.1) 50%,  rgba(255,255,255,1) 100%);
    margin:1rem 0;
    border-radius:1rem;
    min-width:100px;
    font-size:1.3rem;
    height:${props => ((props.visibleItems || 5) * 35)}px;
    line-height:35px;
    display:flex;
    flex-direction:column;
    align-items:center;
    overflow-y:scroll;
    > *{
        flex:1 1 auto;
        width:95%;
        text-align:center;
        color:${COLORS.BLUE};
        &.current{
            border-radius: 25px 25px 25px 25px;
            background:${COLORS.BLUE};
            color:white;
            font-weight:bold;
            border:1px solid${COLORS.BLUE};
        }
        
    }

    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    &::-webkit-scrollbar {
        display: none; /* for Chrome, Safari, and Opera */
    }
    
`;
const BWrap = styled.div`
background: rgb(255,255,255);
padding:0 0px;

`;