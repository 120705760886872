// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  aws_project_region: 'eu-central-1',
  aws_cognito_region: 'eu-central-1',
  aws_user_pools_id: 'eu-central-1_OLBki4Le4',
  aws_user_pools_web_client_id: '3h7e550an1o7q0r114k9c5gv4a',
  aws_cognito_identity_pool_id:
    'eu-central-1:70fa87f2-052e-4e03-b5a4-329c2aa0bf9b',
  aws_user_files_s3_bucket: 'kk-app-data',
  aws_user_files_s3_bucket_region: 'eu-central-1',
  aws_cloud_logic_custom: [
    {
      name: 'kkapi2019',
      endpoint:
        'https://td33j78cl4.execute-api.eu-central-1.amazonaws.com/prod',
      region: 'eu-central-1',
    },
  ],
};

export default awsmobile;
