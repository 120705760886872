import React from "react";
import { TitleBar } from "../common";
import styled from "styled-components";

const Imprint = (props) => {
  const { inline } = props;
  return (
    <Comp>
      {!inline && <TitleBar label="Impressum" />}
      <div class="contentblock">
        <h2>Anbieter</h2>
        <p>
          Daniela Kielkowski
          <br />
          Leibnizstraße 56
          <br />
          10629 Berlin
        </p>
        {/*
        <p>
          Geschäftsführerin: Daniela Kielkowski <br />
          Sitz: Berlin
          <br />
          Amtsgericht Charlottenburg
          <br />
          Handelsregister: HRB227447 B<br />
          UST. – ID DE34 4343 254
        </p>
        */}
      </div>
      <div class="contentblock">
        <h2>Kontakt</h2>
        <p>
          Tel.: 030 – 88 92 20 – 55
          <br />
          Fax: 030 – 88 92 20 – 65
          <br />
          E-Mail: <a href="mailto:info@nutrick24.de">info@nutrick24.de</a>
          <br />
          <a href="https://kurs-01.daniela-kielkowski.de/">
            https://kurs-01.daniela-kielkowski.de/
          </a>
        </p>
      </div>
      <div class="contentblock">
        <h2>Aufsichtsbehörde</h2>
        <p>
          Landesamt für Gesundheit und Soziales Berlin
          <br />
          Sächsische Straße 28
          <br />
          10707 Berlin
          <br />
          <a href="https://www.berlin.de/lageso/">
            https://www.berlin.de/lageso/
          </a>
        </p>
      </div>
      <div class="contentblock">
        <h2>Redaktionell verantwortlich</h2>
        <p>
          Inhaltlich Verantwortlicher gemäß § 10 Absatz 3 MDStV: Daniela
          Kielkowski – Ärztin
        </p>
      </div>
      <div class="contentblock">
        <h2>EU-Streitschlichtung</h2>
        <p>
          Die Europäische Kommission stellt eine Plattform zur
          Online-Streitbeilegung (OS) bereit:{" "}
          <a href="https://ec.europa.eu/consumers/odr/">
            https://ec.europa.eu/consumers/odr/
          </a>
          .<br />
          Unsere E-Mail-Adresse finden Sie oben im Impressum.
        </p>
      </div>
      <div class="contentblock">
        <h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2>
        <p>
          Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
          vor einer Verbraucherschlichtungsstelle teilzunehmen.
        </p>
      </div>
      <div class="contentblock">
        <h2>Haftungs- und Urheberrechtshinweise</h2>
        <p>
          Haftungsausschluss
          <br />
          Die Inhalte der Nutrick24 Web-App wurden sorgfältig und nach unserem
          aktuellen Kenntnisstand erstellt, dienen jedoch nur der Information
          und entfalten keine rechtlich bindende Wirkung, sofern es sich nicht
          um gesetzlich verpflichtende Informationen hinsichtlich Impressums,
          Datenschutzerklärung, AGB oder Widerrufsbelehrungen für Verbraucher
          handelt. Wir behalten uns vor die Inhalte vollständig oder teilweise
          zu ändern oder zu löschen, soweit vertragliche Verpflichtungen
          unberührt bleiben. Alle Angebote sind freibleibend und unverbindlich.
        </p>
      </div>
    </Comp>
  );
};

export default Imprint;

const Comp = styled.div`
  .contentblock {
    margin: 1rem 0;
    h2 {
      font-size: 1.5rem;
    }
  }
`;
