import React, { useCallback, } from 'react';
import { useRecoilValue, useRecoilState } from 'recoil';
import { accountState, cognitoUserState } from '../../atoms';
import { AppUtils } from '../../utils';
import { produce } from 'immer';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { paypalClientIds } from '../../constants';

const initialOptions = {
  "client-id": paypalClientIds.prod,//window.location.host == 'app.nutrick24.de' || window.location.host == 'app.nutrick24.com' ? paypalClientIds.prod : paypalClientIds.sandbox,
  currency: "EUR",
  intent: "capture"
};

const PaypalPayment = (props) => {
  const { productId, productName, productPrice } = props;
  const [account, setAccount] = useRecoilState(accountState);
  const cognitoUser = useRecoilValue(cognitoUserState);

  const createOrder = useCallback((data, actions) => {
    return actions.order.create({
      purchase_units: [{ amount: { value: productPrice, currency_code: "EUR" } }]
    });
  }, [productPrice]);
  const onApprove = useCallback((data) => {
    const { orderID, payerID } = data;
    const acc = produce(account, draft => {
      if (!draft.payments) {
        draft.payments = [];
      }
      draft.payments.push({
        payer_id: payerID,
        order_id: orderID,
        productId: productId,
        productName: productName,
        productPrice: productPrice,
        createdAt: AppUtils.getToday(),
      });
    });
    AppUtils.putS3Data(`user/${cognitoUser.sub}/account.json`, acc);
    setAccount(acc);
  }, [productId, productName, productPrice, account, cognitoUser, setAccount]);

  const onError = (err) => {
    // Handle errors during the payment process 
    console.error("Payment Error:", err);
  };

  return <PayPalScriptProvider options={initialOptions}>
    {account && <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={onError} />}
  </PayPalScriptProvider>;
};

export default PaypalPayment;