import React from "react";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { COLORS } from "../../constants";
import { useLocalStorage } from "usehooks-ts";

const Comp = (props) => {
  const { sid, title, initialOpen } = props;
  const [showFull, setShowFull] = useLocalStorage(
    `nutrick24_foldable_${sid || ""}`,
    initialOpen || true
  );
  return (
    <FoldableInfo>
      <div
        className="titlebar"
        onClick={() => {
          setShowFull(!showFull);
        }}
      >
        <div>
          <h3>{title || ""}</h3>
        </div>
        <Icon name={showFull ? "chevron up" : "chevron down"} />
      </div>
      <div className={`details ${showFull ? "open" : "closed"}`}>
        {props.children}
      </div>
    </FoldableInfo>
  );
};

export default Comp;

const FoldableInfo = styled.div`
  display: flex;
  flex-direction: column;
  background: ${COLORS.BLUE};
  border-radius: 1rem;
  padding: 0.5rem 1rem;
  color: white;
  .titlebar {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    gap: 1rem;
    h3 {
      font-size: 1.2rem;
      margin: 0;
      font-weight: bold;
    }
  }

  .details {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
    a {
      color: white !important;
      text-decoration: underline;
    }
    h4 {
      font-size: 1rem;
      margin: 0;
    }

    &.open {
      opacity: 1;
      padding: 1rem 0 0 0;
      max-height: 10000px;
      transition: all 0.5s ease-in-out;
    }
    &.closed {
      max-height: 0;
      overflow: hidden;
      opacity: 0;
      padding: 0;
      transition: all 0.5s ease-in-out;
    }
  }
`;
