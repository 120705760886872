import styled from "styled-components";
import { COLORS } from '../../constants';

const Section = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
  margin:1rem 0;
  img {
    z-index: 0;
    max-height: 30vh;
  }
  h1 {
    color: ${COLORS.GREY_DARK}!important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: left !important;
    width: 100%;
  }
  h2 {
    color: ${COLORS.BLUE}!important;
    font-weight: bold;
    text-transform: none;
    font-size: 18px;
    line-height: 1.5rem;
    text-align: left !important;
    width: 100%;
  }
`;
export default Section;