import styled from "styled-components";

const KKRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: none;
  > i {
    flex: 0 0 auto;
  }
`;
export default KKRadio;
