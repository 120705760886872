import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./App.css";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { get } from "lodash";
import p from "../package.json";
import "semantic-ui-css/semantic.min.css";
import { useLocalStorage } from "usehooks-ts";
import request from "superagent";
import { KKButton } from "./components/Buttons";
import { COLORS } from "./constants";
import Imprint from "./components/Imprint";

import {
  errorState,
  cognitoUserState,
  accountState,
  caseState,
  reloadAccountCaseState,
  courseRouteState,
  contentPrivacyState,
  contentLabelsEatState,
  contentLabelsHelpState,
  contentLabelsReceipesState,
  foodfavoritesState,
  tokenState,
  reloadfoodfavoritesState,
  praxisVersionState,
  botIdState,
} from "./atoms";
import { Auth } from "aws-amplify";
import { AppUtils } from "./utils";

import {
  Login,
  SignUp,
  LostPassword,
  LostPasswordChange,
} from "./components/Login";
import Home from "./components/Home";
import Appointment from "./components/Appointment";
//import MyLandbot from "./components/MyLandbot";
import Anamnese from "./components/Anamnese";
import Quiz from "./components/Quiz";
import { Reduction, Maintenance } from "./components/Plan";
import Uvida from "./components/Uvida";
import UvidaMap from "./components/Uvida/map";
import InBody from "./components/InBody";
import Logo from "./images/nutrick24_Logo.png";
import {
  Sport,
  Eat,
  Bloodpressure,
  Bloodsugar,
  Weight,
  Symptoms,
} from "./components/Protocols";

import { Routes, Route, BrowserRouter, Outlet } from "react-router-dom";

const { version } = p;

const AppW = (props) => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const error = useRecoilValue(errorState);
  const [cognitoUser, setCognitoUser] = useRecoilState(cognitoUserState);
  const reloadFoodFavorites = useRecoilValue(reloadfoodfavoritesState);
  const setCourseRoute = useSetRecoilState(courseRouteState);
  const setContentPrivacy = useSetRecoilState(contentPrivacyState);
  const setContentLabelsEat = useSetRecoilState(contentLabelsEatState);
  const setContentLabelsHelp = useSetRecoilState(contentLabelsHelpState);
  const setPraxisVersion = useSetRecoilState(praxisVersionState);
  const botId = useRecoilValue(botIdState);
  const setContentLabelsReceipes = useSetRecoilState(
    contentLabelsReceipesState
  );
  const setAccount = useSetRecoilState(accountState);
  const setFoodfavorites = useSetRecoilState(foodfavoritesState);
  const setCaseN = useSetRecoilState(caseState);
  const reloadAccountCase = useRecoilValue(reloadAccountCaseState);
  const setToken = useSetRecoilState(tokenState);

  useEffect(() => {
    setLoadingUser(true);
    setLoadingData(true);
    if (window.location.hostname.startsWith("kurs-01") || window.location.search.includes("praevention_2024-001")) {
      setCourseRoute("praevention_2024-001");
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        setCognitoUser(user.attributes);
        setLoadingUser(false);
      })
      .catch((e) => {
        setCognitoUser(null);
        setLoadingUser(false);
      });
    // load txt
    Promise.all([
      request.get(
        "https://kielkowskiweb.github.io/appcontents-api/essen-erfassen/index.json"
      ),
      request.get(
        "https://kielkowskiweb.github.io/appcontents-api/hilfe/index.json"
      ),
      request.get(
        "https://kielkowskiweb.github.io/appcontents-api/rezepte/index.json"
      )
    ]).then((d) => {
      setContentLabelsEat(get(d[0], "body.data", {}));
      setContentLabelsHelp(get(d[1], "body.data", {}));
      setContentLabelsReceipes(get(d[2], "body.data", {}));
      setLoadingData(false);
    });
  }, [
    setLoadingData,
    setLoadingUser,
    setCourseRoute,
    setCognitoUser,
    setContentPrivacy,
    setContentLabelsEat,
    setContentLabelsHelp,
    setContentLabelsReceipes,
  ]);

  useEffect(() => {
    if (cognitoUser) {
      Promise.all([
        new Promise((rs) => {
          AppUtils.getS3Data(`user/${cognitoUser.sub}/account.json`)
            .then((result) => {
              setAccount(result);
              rs();
            })
            .catch((e) => {
              if (e.status === 404) {
                setAccount({});
                rs();
              }
              rs();
            });
        }),
        new Promise((rs) => {
          AppUtils.getS3Data(`user/${cognitoUser.sub}/case.json`)
            .then((result) => {
              setCaseN(result || {});
              rs();
            })
            .catch((e) => {
              setCaseN({});
              rs();
            });
        }),
        new Promise((rs) => {
          AppUtils.getS3ItemsFromPath(`user/${cognitoUser.sub}/foodfavorites`)
            .then((result) => {
              setFoodfavorites(result || []);
              rs();
            })
            .catch((e) => {
              rs();
            });
        }),
        new Promise((rs) => {
          Auth.currentSession()
            .then((data) => {
              setToken(get(data, "idToken.jwtToken", ""));
              rs();
            })
            .catch((e) => {
              rs();
            });
        }),
      ]);
    }
  }, [
    cognitoUser,
    reloadAccountCase,
    setAccount,
    setCaseN,
    setToken,
    setFoodfavorites,
  ]);

  useEffect(() => {
    if (cognitoUser) {
      AppUtils.getS3ItemsFromPath(`user/${cognitoUser.sub}/foodfavorites`).then(
        (result) => {
          setFoodfavorites(result || []);
        }
      );
    }
  }, [reloadFoodFavorites, cognitoUser, setFoodfavorites]);

  useEffect(() => {
    if (window.location.hostname === "praxis.nutrick24.de") {
      setPraxisVersion(true);
    }
  }, [setPraxisVersion]);

  return (
    <>
      {loadingData || loadingUser ? (
        <Loading>
          <img alt="" src={Logo} />
        </Loading>
      ) : (
        <BrowserRouter>
          {cognitoUser ? <IntRoutes /> : <ExtRoutes />}
        </BrowserRouter>
      )}
      {error && <ErrorMessage />}
      {/*
      <MyLandbot
        botId={botId}
      />
      
      */}

    </>
  );
};
export default AppW;

const Loading = styled.div`
  background: #f1ead2;
  position: absolute;
  z-index: 99;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  img {
    max-width: 50vw;
  }
`;

const App = () => {
  const [privacyOK, setPrivacyOK] = useLocalStorage(
    `nutrick24_privacy2023`,
    false
  );
  return (
    <AppS>
      <AppDeskW>
        <Screen>
          {!privacyOK ? (
            <div id="privacy">
              {privacyShort}
              <KKButton
                color={COLORS.BLUE}
                onClick={() => {
                  setPrivacyOK(true);
                }}
              >
                Einverstanden
              </KKButton>
              {privacyLong}
            </div>
          ) : (
            <Outlet />
          )}
          <div id="imprlink"><a href="/imprint">Impressum</a></div>
        </Screen>
      </AppDeskW>
      <div className="versioninfo">{version}</div>
    </AppS>
  );
};

const ExtRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/signup" element={<SignUp />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route exact path="/lostpassword" element={<LostPassword />} />
        <Route
          exact
          path="/lostpasswordchange"
          element={<LostPasswordChange />}
        />
        <Route path="/" element={<Login />} />
        <Route path="*" element={<Login />} />
      </Route>
    </Routes>
  );
};
const IntRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        <Route exact path="/login" element={<Login />} />
        <Route path="/imprint" element={<Imprint />} />
        <Route path="/sprechstunde" element={<Appointment />} />
        <Route path="/anamnese" element={<Anamnese />} />
        <Route path="/anamnese/:id" element={<Anamnese />} />
        <Route path="/anamnese/:id/:section" element={<Anamnese />} />
        <Route path="/quiz/:course/:id" element={<Quiz />} />
        <Route path="/protocols/sport/:day" element={<Sport />} />
        <Route path="/protocols/eat/:day" element={<Eat />} />
        <Route
          path="/protocols/bloodpressure/:day"
          element={<Bloodpressure />}
        />
        <Route path="/protocols/weight/:day" element={<Weight />} />

        <Route path="/protocols/bloodsugar/:day" element={<Bloodsugar />} />
        <Route path="/protocols/symptoms/:day" element={<Symptoms />} />

        <Route path="/reduction/:day" element={<Reduction />} />
        <Route path="/maintenance/:day" element={<Maintenance />} />

        <Route path="/uvida" element={<Uvida />} />
        <Route path="/inbody" element={<InBody />} />
        <Route path="/uvidamap" element={<UvidaMap />} />
        <Route path="/" element={<Home />} />
        <Route path="*" element={<Home />} />
      </Route>
    </Routes>
  );
};

export const ErrorMessage = (props) => {
  const [error, setError] = useRecoilState(errorState);
  console.log(error);
  return (
    <div className="error-message" onClick={setError(null)}>
      {error.txt}
    </div>
  );
};
const AppS = styled.div`
  #privacy {
    padding: 1rem;
  }

  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  .versioninfo {
    font-size: 0.6rem;
    position: absolute;
    bottom: 0;
    z-index: 99;
    left: 1rem;
    color: rgba(128, 128, 128, 0.8);
  }
  .ui.form select,
  .ui.form input:not([type]),
  .ui.form input[type="date"],
  .ui.form input[type="datetime-local"],
  .ui.form input[type="email"],
  .ui.form input[type="file"],
  .ui.form input[type="number"],
  .ui.form input[type="password"],
  .ui.form input[type="search"],
  .ui.form input[type="tel"],
  .ui.form input[type="text"],
  .ui.form input[type="time"],
  .ui.form input[type="url"] {
    border-radius: 2rem;
  }

  .ui.form input[type="tel"]:focus {
    border-radius: 30px;
  }

  .ui.form input:focus {
    border-radius: 30px;
  }
`;
const AppDeskW = styled.div`
  min-height: 100vh;
  width: 100%;
  background: #f1ead2;
  flex: 1 1 100%;
  @media only screen and (min-width: 768px) {
    max-width: 600px;
    min-width: 320px;
    min-height: 95vh;
    margin: 2vh 0;
    border: 1px solid rgba(128, 128, 128, 0.5);
    border-radius: 2rem;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.53);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.53);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.53);
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;
const Screen = styled.div`
  min-height: 100vh;
  flex: 1 1 100%;
  padding: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  #imprlink{
    display:flex;
    justify-content:center;
    margin:1rem 0;
  }
`;


const privacyShort = <div><h3>Datenschutz</h3>
  <p>Hiermit erklären Sie sich dazu bereit, dass Ihre personenbezogenen Daten erhoben und verarbeitet werden dürfen. Dazu
    gehören u.a.:<br /></p>
  <ul>
    <li>
      <p>Name, Vorname</p>
    </li>
    <li>
      <p>Anschrift bzw. Adresse</p>
    </li>
    <li>
      <p>E-Mail-Adresse</p>
    </li>
    <li>
      <p>Zeitpunkt, Menge und Art der konsumierten Lebensmittel</p>
    </li>
    <li>
      <p>Gesundheitsdaten z.B. Körpergröße, Gewicht, Alter, Geschlecht, Grundumsatz, körperliche Aktivität</p>
    </li>
    <li>
      <p>Zahlungsart</p>
    </li>
  </ul>
  <p></p>
  <p>Diese Daten werden auf dem Server von Daniela Kielkowski gespeichert und können nur von berechtigten Personen
    eingesehen werden. Wir versichern hiermit, dass die von uns durchgeführte EDV auf der Grundlage geltender Gesetze
    erfolgt und für das Zustandekommen des Vertragsverhältnisses notwendig ist.</p>
  <p>Die vollständige Datenschutzerklärung finden Sie untenstehend.</p></div>;
const privacyLong = <div><h1>Datenschutzerklärung und Einwilligungserklärung</h1>
  <p>Wir freuen uns sehr über Ihr Interesse an unserem Unternehmen. Datenschutz hat einen besonders hohen Stellenwert für
    die Geschäftsleitung der Praxis Körperkonzepte, Daniela Kielkowski.</p>
  <p>Die Verarbeitung personenbezogener Daten, beispielsweise des Namens, der Anschrift, E-Mail-Adresse oder Telefonnummer
    einer betroffenen Person, erfolgt stets im Einklang mit der Datenschutz-Grundverordnung und in Übereinstimmung mit
    den für die Praxis Körperkonzepte, Daniela Kielkowski geltenden landesspezifischen Datenschutzbestimmungen. Mittels
    dieser Datenschutzerklärung möchte unser Unternehmen die Öffentlichkeit über Art, Umfang und Zweck der von uns
    erhobenen, genutzten und verarbeiteten personenbezogenen Daten informieren.</p>
  <p>Ferner werden betroffene Personen mittels dieser Datenschutzerklärung über die ihnen zustehenden Rechte aufgeklärt.
  </p>
  <p>Die Praxis Körperkonzepte, Daniela Kielkowski hat als für die Verarbeitung Verantwortlicher zahlreiche technische und
    organisatorische Maßnahmen umgesetzt, um einen möglichst lückenlosen Schutz der über diese Internetseite
    verarbeiteten personenbezogenen Daten sicherzustellen. Dennoch können Internetbasierte Datenübertragungen
    grundsätzlich Sicherheitslücken aufweisen, sodass ein absoluter Schutz nicht gewährleistet werden kann. Aus diesem
    Grund steht es jeder betroffenen Person frei, personenbezogene Daten auch auf alternativen Wegen, beispielsweise
    telefonisch, an uns zu übermitteln.</p>
  <h2>1. Begriffsbestimmungen</h2>
  <p>Die Datenschutzerklärung der Praxis Körperkonzepte, Daniela Kielkowski beruht auf den Begrifflichkeiten, die durch
    den Europäischen Richtlinien- und Verordnungsgeber beim Erlass der Datenschutz-Grundverordnung &#40;DS-GVO&#41; verwendet
    wurden. Unsere Datenschutzerklärung soll sowohl für die Öffentlichkeit als auch für unsere Kunden und
    Geschäftspartner einfach lesbar und verständlich sein. Um dies zu gewährleisten, möchten wir vorab die verwendeten
    Begrifflichkeiten erläutern.</p>
  <p>Wir verwenden in dieser Datenschutzerklärung unter anderem die folgenden Begriffe:</p>
  <h3>a&#41; personenbezogene Daten</h3>
  <p>Personenbezogene Daten sind alle Informationen, die sich auf eine identifizierte oder identifizierbare natürliche
    Person &#40;im Folgenden „betroffene Person“&#41; beziehen. Als identifizierbar wird eine natürliche Person angesehen, die
    direkt oder indirekt, insbesonderemittels Zuordnung zu einer Kennung wie einem Namen, zu einer Kennnummer, zu
    Standortdaten, zu einer Online-Kennung oder zu einem oder mehreren besonderen Merkmalen, die Ausdruck der
    physischen, physiologischen, genetischen, psychischen, wirtschaftlichen, kulturellen oder sozialen Identität dieser
    natürlichen Person sind, identifiziert werden kann.</p>
  <h3>b&#41; betroffene Person</h3>
  <p>Betroffene Person ist jede identifizierte oder identifizierbare natürliche Person, deren personenbezogene Daten von
    dem für die Verarbeitung Verantwortlichen verarbeitet werden.</p>
  <h3>c&#41; Verarbeitung</h3>
  <p>Verarbeitung ist jeder mit oder ohne Hilfe automatisierter Verfahren ausgeführte Vorgang oder jede solche
    Vorgangsreihe im Zusammenhang mit personenbezogenen Daten wie das Erheben, das Erfassen, die Organisation, das
    Ordnen, die Speicherung, die Anpassung oder Veränderung, das Auslesen, das Abfragen, die Verwendung, die Offenlegung
    durch Übermittlung, Verbreitung oder eine andere Form der Bereitstellung, den Abgleich oder die Verknüpfung, die
    Einschränkung, das Löschen oder die Vernichtung.</p>
  <h3>d&#41; Einschränkung der Verarbeitung</h3>
  <p>Einschränkung der Verarbeitung ist die Markierung gespeicherter personenbezogener Daten mit dem Ziel, ihre künftige
    Verarbeitung einzuschränken.</p>
  <h3>e&#41; Profiling</h3>
  <p>Profiling ist jede Art der automatisierten Verarbeitung personenbezogener Daten, die darin besteht, dass diese
    personenbezogenen Daten verwendet werden, um bestimmte persönliche Aspekte, die sich auf eine natürliche Person
    beziehen, zu bewerten, insbesondere, um Aspekte bezüglich Arbeitsleistung, wirtschaftlicher Lage, Gesundheit,
    persönlicher Vorlieben, Interessen, Zuverlässigkeit, Verhalten, Aufenthaltsort oder Ortswechsel dieser natürlichen
    Person zu analysieren oder vorherzusagen.</p>
  <h3>f&#41; Pseudonymisierung</h3>
  <p>Pseudonymisierung ist die Verarbeitung personenbezogener Daten in einer Weise, auf welche die personenbezogenen Daten
    ohne Hinzuziehung zusätzlicher Informationen nicht mehr einer spezifischen betroffenen Person zugeordnet werden
    können, sofern diese zusätzlichen Informationen gesondert aufbewahrt werden und technischen und organisatorischen
    Maßnahmen unterliegen, die gewährleisten, dass die personenbezogenen Daten nicht einer identifizierten oder
    identifizierbaren natürlichen Person zugewiesen werden.</p>
  <h3>g&#41; Verantwortlicher oder für die Verarbeitung Verantwortlicher</h3>
  <p>Verantwortlicher oder für die Verarbeitung Verantwortlicher ist die natürliche oder juristische Person, Behörde,
    Einrichtung oder andere Stelle, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung
    von personenbezogenen Daten entscheidet. Sind die Zwecke und Mittel dieser Verarbeitung durch das Unionsrecht oder
    das Recht der Mitgliedstaaten vorgegeben, so kann der Verantwortliche beziehungsweise können die bestimmten
    Kriterien seiner Benennung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten vorgesehen werden.</p>
  <h3>h&#41; Auftragsverarbeiter</h3>
  <p>Auftragsverarbeiter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, die
    personenbezogene Daten im Auftrag des Verantwortlichen verarbeitet.</p>
  <h3>i&#41; Empfänger</h3>
  <p>Empfänger ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle, der personenbezogene
    Daten offengelegt werden, unabhängig davon, ob es sich bei ihr um einen Dritten handelt oder nicht. Behörden, die im
    Rahmen eines bestimmten Untersuchungsauftrags nach dem Unionsrecht oder dem Recht der Mitgliedstaatenmöglicherweise
    personenbezogene Daten erhalten, gelten jedoch nicht als Empfänger.</p>
  <h3>j&#41; Dritter</h3>
  <p>Dritter ist eine natürliche oder juristische Person, Behörde, Einrichtung oder andere Stelle außer der betroffenen
    Person, dem Verantwortlichen, dem Auftragsverarbeiter und den Personen, die unter der unmittelbaren Verantwortung
    des Verantwortlichen oder des Auftragsverarbeiters befugt sind, die personenbezogenen Daten zu verarbeiten.</p>
  <h3>k&#41; Einwilligung</h3>
  <p>Einwilligung ist jede von der betroffenen Person freiwillig für den bestimmten Fall in informierter Weise und
    unmissverständlich abgegebene Willensbekundung in Form einer Erklärung oder einer sonstigen eindeutigen
    bestätigenden Handlung, mit der die betroffene Person zu verstehen gibt, dass sie mit der Verarbeitung der sie
    betreffenden personenbezogenen Daten einverstanden ist.</p>
  <h2>2. Name und Anschrift des für die Verarbeitung Verantwortlichen</h2>
  <p>Verantwortlicher im Sinne der Datenschutz-Grundverordnung, sonstiger in den Mitgliedstaaten der Europäischen Union
    geltenden Datenschutzgesetze und anderer Bestimmungen mit datenschutzrechtlichem Charakter ist die:</p>
  <p>Praxis Körperkonzepte, Daniela Kielkowski<br />Leibnizstraße 56<br />10629 Berlin<br />Deutschland</p>
  <p>Tel.: 030 889220-55<br />E-Mail: info@koerperkonzepte.com<br />Website: app.eat-and-move.com</p>
  <h2>3. Erfassung von allgemeinen Daten und Informationen</h2>
  <p>Die Internetseite der Praxis Körperkonzepte, Daniela Kielkowski erfasst mit jedem Aufruf der Internetseite durch eine
    betroffene Person oder ein automatisiertes System eine Reihe von allgemeinen Daten und Informationen. Diese
    allgemeinen Daten und Informationen werden in den Logfiles des Servers gespeichert. Erfasst werden können die &#40;1&#41;
    verwendeten Browsertypen und Versionen, &#40;2&#41; das vom zugreifenden System verwendete Betriebssystem, &#40;3&#41; die
    Internetseite, von welcher ein zugreifendes System auf unsere Internetseite gelangt &#40;sogenannte Referrer&#41;, &#40;4&#41; die
    Unterwebseiten, welche über ein zugreifendes System auf unserer Internetseite angesteuert werden, &#40;5&#41; das Datum und
    die Uhrzeit eines Zugriffs auf die Internetseite, &#40;6&#41; eine Internet-Protokoll-Adresse &#40;IP-Adresse&#41;, &#40;7&#41; der
    Internet-Service-Provider des zugreifenden Systems und&#40;8&#41; sonstige ähnliche Daten und Informationen, die der
    Gefahrenabwehr im Falle von Angriffen auf unsere informationstechnologischen Systeme dienen.</p>
  <p>Bei der Nutzung dieser allgemeinen Daten und Informationen zieht die Praxis Körperkonzepte, Daniela Kielkowski keine
    Rückschlüsse auf die betroffene Person. Diese Informationen werden vielmehr benötigt, um &#40;1&#41; die Inhalte unserer
    Internetseite korrekt auszuliefern, &#40;2&#41; die Inhalte unserer Internetseite sowie die Werbung für diese zu optimieren,
    &#40;3&#41; die dauerhafte Funktionsfähigkeit unserer informationstechnologischen Systeme und der Technik unserer
    Internetseite zu gewährleisten sowie &#40;4&#41; um Strafverfolgungsbehörden im Falle eines Cyberangriffes die zur
    Strafverfolgung notwendigen Informationen bereitzustellen. Diese anonym erhobenen Daten und Informationen werden
    durch die Praxis Körperkonzepte, Daniela Kielkowski daher einerseits statistisch und ferner mit dem Ziel
    ausgewertet, den Datenschutz und die Datensicherheit in unserem Unternehmen zu erhöhen, um letztlich ein optimales
    Schutzniveau für die von uns verarbeiteten personenbezogenen Daten sicherzustellen. Die anonymen Daten der
    Server-Logfiles werden getrennt von allen durch eine betroffene Person angegebenen personenbezogenen Daten
    gespeichert.</p>
  <h2>4. Nutzung der App</h2>
  <p>Die betroffene Person hat die Möglichkeit, personenbezogene Daten innerhalb der App zu erfassen. Welche
    personenbezogenen Daten dabei an den für die Verarbeitung Verantwortlichen übermittelt werden, ergibt sich aus der
    jeweiligen Eingabemaske. Die von der betroffenen Person eingegebenen personenbezogenen Daten werden ausschließlich
    für die interne Verwendung bei dem für die Verarbeitung Verantwortlichen und für eigene Zwecke erhoben und
    gespeichert. Der für die Verarbeitung Verantwortliche kann die Weitergabe an einen oder mehrere Auftragsverarbeiter
    veranlassen, der die personenbezogenen Daten ebenfalls ausschließlich für eine interne Verwendung, die dem für die
    Verarbeitung Verantwortlichen zuzurechnen ist, nutzt.</p>
  <p>Durch eine Nutzung der App wird ferner die vom Internet-Service-Provider &#40;ISP&#41; der betroffenen Person vergebene
    IP-Adresse, das Datum sowie die Uhrzeit gespeichert. Die Speicherung dieser Daten erfolgt vor dem Hintergrund, dass
    nur so der Missbrauch unserer Dienste verhindert werden kann, und diese Daten im Bedarfsfall ermöglichen, begangene
    Straftaten aufzuklären. Insofern ist die Speicherung dieser Daten zur Absicherung des für die Verarbeitung
    Verantwortlichen erforderlich. Eine Weitergabe dieser Daten an Dritte erfolgt grundsätzlich nicht, sofern keine
    gesetzliche Pflicht zur Weitergabe besteht oder die Weitergabe der Strafverfolgung dient.</p>
  <p>Die Angabe personenbezogener Daten dient dem für die Verarbeitung Verantwortlichen dazu, der betroffenen Person
    Inhalte oder Leistungen anzubieten. Registrierten Personen steht die Möglichkeit frei, die angegebenen
    personenbezogenen Daten jederzeit abzuändern oder vollständig aus dem Datenbestand des für die Verarbeitung
    Verantwortlichen löschen zu lassen.</p>
  <p>Der für die Verarbeitung Verantwortliche erteilt jeder betroffenen Person jederzeit auf Anfrage Auskunft darüber,
    welche personenbezogenen Daten über die betroffene Person gespeichert sind. Ferner berichtigt oder löscht der für
    die Verarbeitung Verantwortliche personenbezogene Daten auf Wunsch oder Hinweis der betroffenen Person, soweit dem
    keine gesetzlichen Aufbewahrungspflichten entgegenstehen. Die Gesamtheit der Mitarbeiter des für die Verarbeitung
    Verantwortlichen stehen der betroffenen Person in diesem Zusammenhang als Ansprechpartner zur Verfügung.</p>
  <h2>5. Routinemäßige Löschung und Sperrung von personenbezogenen Daten</h2>
  <p>Der für die Verarbeitung Verantwortliche verarbeitet und speichert personenbezogene Daten der betroffenen Person nur
    für den Zeitraum, der zur Erreichung des Speicherungszwecks erforderlich ist oder sofern dies durch den Europäischen
    Richtlinien- und Verordnungsgeber oder einen anderen Gesetzgeber in Gesetzen oder Vorschriften, welchen der für die
    Verarbeitung Verantwortliche unterliegt, vorgesehen wurde.</p>
  <p>Entfällt der Speicherungszweck oder läuft eine vom Europäischen Richtlinien- und Verordnungsgeber oder einem anderen
    zuständigen Gesetzgeber vorgeschriebene Speicherfrist ab, werden die personenbezogenen Daten routinemäßig und
    entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.</p>
  <h2>6. Rechte der betroffenen Person</h2>
  <h4>a&#41; Recht auf Bestätigung</h4>
  <p>Jede betroffene Person hat das vom Europäischen Richtlinien- und Verordnungsgeber eingeräumte Recht, von dem für die
    Verarbeitung Verantwortlichen eine Bestätigung darüber zu verlangen, ob sie betreffende personenbezogene Daten
    verarbeitet werden. Möchte eine betroffene Person dieses Bestätigungsrecht in Anspruch nehmen, kann sie sich hierzu
    jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.</p>
  <h4>b&#41; Recht auf Auskunft</h4>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
    Verordnungsgeber gewährte Recht, jederzeit von dem für die Verarbeitung Verantwortlichen unentgeltliche Auskunft
    über die zu seiner Person gespeicherten personenbezogenen Daten und eine Kopie dieser Auskunft zu erhalten.</p>
  <p>Ferner hat der Europäische Richtlinien- und Verordnungsgeber der betroffenen Person Auskunft über folgende
    Informationen zugestanden: die Verarbeitungszwecke die Kategorien personenbezogener Daten, die verarbeitet werden
    die Empfänger oder Kategorien von Empfängern, gegenüber denen die personenbezogenen Daten offengelegt worden sind
    oder noch offengelegt werden, insbesondere bei Empfängern in Drittländern oder bei internationalen Organisationen
    falls möglich die geplante Dauer, für die die personenbezogenen Daten gespeichert werden, oder, falls dies nicht
    möglich ist, die Kriterien für die Festlegung dieser Dauer das Bestehen eines Rechts auf Berichtigung oder Löschung
    der sie betreffenden personenbezogenen Daten oder auf Einschränkung der Verarbeitung durch den Verantwortlichen oder
    eines Widerspruchsrechts gegen diese Verarbeitung das Bestehen eines Beschwerderechts bei einer Aufsichtsbehörde
    wenn die personenbezogenen Daten nicht bei der betroffenen Person erhoben werden: Alle verfügbaren Informationen
    über die Herkunft der Daten das Bestehen einer automatisierten Entscheidungsfindung einschließlich Profiling gemäß
    Artikel 22 Abs.1 und 4 DS-GVO und — zumindest in diesen Fällen — aussagekräftige Informationen über die involvierte
    Logik sowie die Tragweite und die angestrebten Auswirkungen einer derartigen Verarbeitung für die betroffene Person.
    Ferner steht der betroffenen Person ein Auskunftsrecht darüber zu, ob personenbezogene Daten an ein Drittland oder
    an eine internationale Organisation übermittelt wurden. Sofern dies der Fall ist, so steht der betroffenen Person im
    Übrigen das Recht zu, Auskunft über die geeigneten Garantien im Zusammenhang mit der Übermittlung zu erhalten.</p>
  <p>Möchte eine betroffene Person dieses Auskunftsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen
    Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.</p>
  <h4>c&#41; Recht auf Berichtigung</h4>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
    Verordnungsgeber gewährte Recht, die unverzügliche Berichtigung sie betreffender unrichtiger personenbezogener Daten
    zu verlangen. Ferner steht der betroffenen Person das Recht zu, unter Berücksichtigung der Zwecke der Verarbeitung,
    die Vervollständigung unvollständiger personenbezogener Daten — auch mittels einer ergänzenden Erklärung — zu
    verlangen.</p>
  <p>Möchte eine betroffene Person dieses Berichtigungsrecht in Anspruch nehmen, kann sie sich hierzu jederzeit an einen
    Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.</p>
  <h4>d&#41; Recht auf Löschung &#40;Recht auf Vergessen werden&#41;</h4>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
    Verordnungsgeber gewährte Recht, von dem Verantwortlichen zu verlangen, dass die sie betreffenden personenbezogenen
    Daten unverzüglich gelöscht werden, sofern einer der folgenden Gründe zutrifft und soweit die Verarbeitung nicht
    erforderlich ist:</p>
  <p>Die personenbezogenen Daten wurden für solche Zwecke erhoben oder auf sonstige Weise verarbeitet, für welche sie
    nicht mehr notwendig sind. Die betroffene Person widerruft ihre Einwilligung, auf die sich die Verarbeitung gemäß
    Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO stützte, und es fehlt an einer anderweitigen
    Rechtsgrundlage für die Verarbeitung.</p>
  <p>Die betroffene Person legt gemäß Art. 21 Abs. 1 DS-GVO Widerspruch gegen die Verarbeitung ein, und es liegen keine
    vorrangigen berechtigten Gründe für die Verarbeitung vor, oder die betroffene Person legt gemäß Art. 21 Abs. 2 DSGVO
    Widerspruch gegen die Verarbeitung ein.</p>
  <p>Die personenbezogenen Daten wurden unrechtmäßig verarbeitet. Die Löschung der personenbezogenen Daten ist zur
    Erfüllung einer rechtlichen Verpflichtung nach dem Unionsrecht oder dem Recht der Mitgliedstaaten erforderlich, dem
    der Verantwortliche unterliegt. Die personenbezogenen Daten wurden in Bezug auf angebotene Dienste der
    Informationsgesellschaft gemäß Art. 8 Abs. 1 DS-GVO erhoben.</p>
  <p>Sofern einer der oben genannten Gründe zutrifft und eine betroffene Person die Löschung von personenbezogenen Daten,
    die bei der Praxis Körperkonzepte, Daniela Kielkowski gespeichert sind, veranlassen möchte, kann sie sich hierzu
    jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der Mitarbeiter der Praxis
    Körperkonzepte, Daniela Kielkowski wird veranlassen, dass dem Löschverlangen unverzüglich nachgekommen wird.</p>
  <p>Wurden die personenbezogenen Daten von der Praxis Körperkonzepte, Daniela Kielkowski öffentlich gemacht und ist unser
    Unternehmen als Verantwortlicher gemäß Art. 17 Abs. 1 DSGVO zur Löschung der personenbezogenen Daten verpflichtet,
    so trifft die Praxis Körperkonzepte, Daniela Kielkowski unter Berücksichtigung der verfügbaren Technologie und der
    Implementierungskosten angemessene Maßnahmen, auch technischer Art, um andere für die Datenverarbeitung
    Verantwortliche, welche die veröffentlichten personenbezogenen Daten verarbeiten, darüber in Kenntnis zu setzen,
    dass die betroffene Person von diesen anderen für die Datenverarbeitung Verantwortlichen die Löschung sämtlicher
    Links zu diesen personenbezogenen Daten oder von Kopien oder Replikationen dieser personenbezogenen Daten verlangt
    hat, soweit die Verarbeitung nicht erforderlich ist.</p>
  <p>Der Mitarbeiter der Praxis Körperkonzepte, Daniela Kielkowski wird im Einzelfall das Notwendige veranlassen.</p>
  <h4>e&#41; Recht auf Einschränkung der Verarbeitung</h4>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
    Verordnungsgeber gewährte Recht, von dem Verantwortlichen die Einschränkung der Verarbeitung zu verlangen, wenn eine
    der folgenden Voraussetzungen gegeben ist:</p>
  <ul>
    <li>
      <p>Die Richtigkeit der personenbezogenen Daten wird von der betroffenen Person bestritten, und zwar für eine
        Dauer, die es dem Verantwortlichen ermöglicht, die Richtigkeit der personenbezogenen Daten zu überprüfen.
      </p>
    </li>
    <li>
      <p>Die Verarbeitung ist unrechtmäßig, die betroffene Person lehnt die Löschung der personenbezogenen Daten ab
        und verlangt stattdessen die Einschränkung der Nutzung der personenbezogenen Daten.</p>
    </li>
    <li>
      <p>Der Verantwortliche benötigt die personenbezogenen Daten für die Zwecke der Verarbeitung nicht länger, die
        betroffene Person benötigt sie jedoch zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen.
      </p>
    </li>
    <li>
      <p>Die betroffene Person hat Widerspruch gegen die Verarbeitung gem. Art. 21 Abs. 1 DS-GVO eingelegt und es
        steht noch nicht fest, ob die berechtigten Gründe des Verantwortlichen gegenüber denen der betroffenen
        Person überwiegen.</p>
    </li>
  </ul>
  <p>Sofern eine der oben genannten Voraussetzungen gegeben ist und eine betroffene Person die Einschränkung von
    personenbezogenen Daten, die bei der Praxis Körperkonzepte, Daniela Kielkowski gespeichert sind, verlangen möchte,
    kann sie sich hierzu jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden. Der
    Mitarbeiter der Praxis Körperkonzepte, Daniela Kielkowski wird die Einschränkung der Verarbeitung veranlassen.</p>
  <h4>f&#41; Recht auf Datenübertragbarkeit</h4>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
    Verordnungsgeber gewährte Recht, die sie betreffenden personenbezogenen Daten, welche durch die betroffene Person
    einem Verantwortlichen bereitgestellt wurden, in einem strukturierten, gängigen und maschinenlesbaren Format zu
    erhalten. Sie hat außerdem das Recht, diese Daten einem anderen Verantwortlichen ohne Behinderung durch den
    Verantwortlichen, dem die personenbezogenen Daten bereitgestellt wurden, zu übermitteln, sofern die Verarbeitung auf
    der Einwilligung gemäß Art. 6 Abs. 1 Buchstabe a DS-GVO oder Art. 9 Abs. 2 Buchstabe a DS-GVO oder auf einem Vertrag
    gemäß Art. 6 Abs. 1 Buchstabe b DS-GVO beruht und die Verarbeitung mithilfe automatisierter Verfahren erfolgt,
    sofern die Verarbeitung nicht für die Wahrnehmung einer Aufgabe erforderlich ist, die im öffentlichen Interesse
    liegt oder in Ausübung öffentlicher Gewalt erfolgt, welche dem Verantwortlichen übertragen wurde.</p>
  <p>Ferner hat die betroffene Person bei der Ausübung ihres Rechts auf Datenübertragbarkeit gemäß Art. 20 Abs. 1 DS-GVO
    das Recht, zu erwirken, dass die personenbezogenen Daten direkt von einem Verantwortlichen an einen anderen
    Verantwortlichen übermittelt werden, soweit dies technisch machbar ist und sofern hiervon nicht die Rechte und
    Freiheiten anderer Personen beeinträchtigt werden.</p>
  <p>Zur Geltendmachung des Rechts auf Datenübertragbarkeit kann sich die betroffene Person jederzeit an einen Mitarbeiter
    der Praxis Körperkonzepte, Daniela Kielkowski wenden.</p>
  <h4>g&#41; Recht auf Widerspruch</h4>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
    Verordnungsgeber gewährte Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, jederzeit gegen die
    Verarbeitung sie betreffenderpersonenbezogener Daten, die aufgrund von Art. 6 Abs. 1 Buchstaben e oder f DS-GVO
    erfolgt, Widerspruch einzulegen. Dies gilt auch für ein auf diese Bestimmungen gestütztes Profiling.</p>
  <p>Die Praxis Körperkonzepte, Daniela Kielkowski verarbeitet die personenbezogenen Daten im Falle des Widerspruchs nicht
    mehr, es sei denn, wir können zwingende schutzwürdige Gründe für die Verarbeitung nachweisen, die den Interessen,
    Rechten und Freiheiten der betroffenen Person überwiegen, oder die Verarbeitung dient der Geltendmachung, Ausübung
    oder Verteidigung von Rechtsansprüchen.</p>
  <p>Verarbeitet die Praxis Körperkonzepte, Daniela Kielkowski personenbezogene Daten, um Direktwerbung zu betreiben, so
    hat die betroffene Person das Recht, jederzeit Widerspruch gegen die Verarbeitung der personenbezogenen Daten zum
    Zwecke derartiger Werbung einzulegen. Dies gilt auch für das Profiling, soweit es mit solcher Direktwerbung in
    Verbindung steht. Widerspricht die betroffene Person gegenüber der Praxis Körperkonzepte, Daniela Kielkowski der
    Verarbeitung für Zwecke der Direktwerbung, so wird die Praxis Körperkonzepte, Daniela Kielkowski die
    personenbezogenen Daten nicht mehr für diese Zwecke verarbeiten.</p>
  <p>Zudem hat die betroffene Person das Recht, aus Gründen, die sich aus ihrer besonderen Situation ergeben, gegen die
    sie betreffende Verarbeitung personenbezogener Daten, die bei der Praxis Körperkonzepte, Daniela Kielkowski zu
    wissenschaftlichen oder historischen Forschungszwecken oder zu statistischen Zwecken gemäß Art. 89 Abs. 1 DS-GVO
    erfolgen, Widerspruch einzulegen, es sei denn, eine solche Verarbeitung ist zur Erfüllung einer im öffentlichen
    Interesse liegenden Aufgabe erforderlich.</p>
  <p>Zur Ausübung des Rechts auf Widerspruch kann sich die betroffene Person direkt jeden Mitarbeiter der Praxis
    Körperkonzepte, Daniela Kielkowski oder einen anderen Mitarbeiter wenden. Der betroffenen Person steht es ferner
    frei, im Zusammenhang mit der Nutzung von Diensten der Informationsgesellschaft, ungeachtet der Richtlinie
    2002/58/EG, ihr Widerspruchsrecht mittels automatisierter Verfahren auszuüben, bei denen technische Spezifikationen
    verwendet werden.</p>
  <h4>h&#41; Automatisierte Entscheidungen im Einzelfall einschließlich Profiling</h4>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
    Verordnungsgeber gewährte Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung — einschließlich
    Profiling — beruhenden Entscheidung unterworfen zu werden, die ihr gegenüber rechtliche Wirkung entfaltet oder sie
    in ähnlicher Weise erheblich beeinträchtigt, sofern die Entscheidung &#40;1&#41; nicht für den Abschluss oder die Erfüllung
    eines Vertrags zwischen der betroffenen Person und dem Verantwortlichen erforderlich ist, oder &#40;2&#41; aufgrund von
    Rechtsvorschriften der Union oder der Mitgliedstaaten, denen der Verantwortliche unterliegt, zulässig ist und diese
    Rechtsvorschriften angemessene Maßnahmen zur Wahrung der Rechte und Freiheiten sowie der berechtigten Interessen der
    betroffenen Person enthalten oder &#40;3&#41; mit ausdrücklicher Einwilligung der betroffenen Person erfolgt.</p>
  <p>Ist die Entscheidung &#40;1&#41; für den Abschluss oder die Erfüllung eines Vertrags zwischen der betroffenen Person und dem
    Verantwortlichen erforderlich oder &#40;2&#41; erfolgt sie mit ausdrücklicher Einwilligung der betroffenen Person, trifft
    die Praxis Körperkonzepte, Daniela Kielkowski angemessene Maßnahmen, um die Rechte und Freiheiten sowie die
    berechtigten Interessen der betroffenen Person zu wahren, wozu mindestens das Recht auf Erwirkung des Eingreifens
    einer Person seitens des Verantwortlichen, auf Darlegung des eigenen Standpunkts und auf Anfechtung der Entscheidung
    gehört.</p>
  <p>Möchte die betroffene Person Rechte mit Bezug auf automatisierte Entscheidungen geltend machen, kann sie sich hierzu
    jederzeit an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.</p>
  <h4>i&#41; Recht auf Widerruf einer datenschutzrechtlichen Einwilligung</h4>
  <p>Jede von der Verarbeitung personenbezogener Daten betroffene Person hat das vom Europäischen Richtlinien- und
    Verordnungsgeber gewährte Recht, eine Einwilligung zur Verarbeitung personenbezogener Daten jederzeit zu widerrufen.
  </p>
  <p>Möchte die betroffene Person ihr Recht auf Widerruf einer Einwilligung geltend machen, kann sie sich hierzu jederzeit
    an einen Mitarbeiter des für die Verarbeitung Verantwortlichen wenden.</p>
  <h2>7. Rechtsgrundlage der Verarbeitung</h2>
  <p>Art. 6 I lit. a DS-GVO dient unserem Unternehmen als Rechtsgrundlage für Verarbeitungsvorgänge, bei denen wir eine
    Einwilligung für einen bestimmten Verarbeitungszweck einholen. Ist die Verarbeitung personenbezogener Daten zur
    Erfüllung eines Vertrags, dessen Vertragspartei die betroffene Person ist, erforderlich, wie dies beispielsweise bei
    Verarbeitungsvorgängen der Fall ist, die für eine Lieferung von Waren oder die Erbringung einer sonstigen Leistung
    oder Gegenleistung notwendig sind, so beruht die Verarbeitung auf Art. 6 I lit. b DS-GVO. Gleiches gilt für solche
    Verarbeitungsvorgänge die zur Durchführung vorvertraglicher Maßnahmen erforderlich sind, etwa in Fällen von Anfragen
    zur unseren Produkten oder Leistungen. Unterliegt unser Unternehmen einer rechtlichen Verpflichtung durch welche
    eine Verarbeitung von personenbezogenen Daten erforderlich wird, wie beispielsweise zur Erfüllung steuerlicher
    Pflichten, so basiert die Verarbeitung auf Art. 6 I lit. c DS-GVO. In seltenen Fällen könnte die Verarbeitung von
    personenbezogenen Daten erforderlich werden, um lebenswichtige Interessen der betroffenen Person oder einer anderen
    natürlichen Person zu schützen. Dies wäre beispielsweise der Fall, wenn ein Besucher in unserem Betrieb verletzt
    werden würde und daraufhin sein Name, sein Alter, seine Krankenkassendaten oder sonstige lebenswichtige
    Informationen an einen Arzt, ein Krankenhaus oder sonstige Dritte weitergegeben werden müssten. Dann würde die
    Verarbeitung auf Art. 6 I lit. d DS-GVO beruhen. Letztlich könnten Verarbeitungsvorgänge auf Art. 6 I lit. f DS-GVO
    beruhen. Auf dieser Rechtsgrundlage basieren Verarbeitungsvorgänge, die von keiner der vorgenannten Rechtsgrundlagen
    erfasst werden, wenn die Verarbeitung zur Wahrung eines berechtigten Interesses unseres Unternehmens oder eines
    Dritten erforderlich ist, sofern die Interessen, Grundrechte und Grundfreiheiten des Betroffenen nicht überwiegen.
    Solche Verarbeitungsvorgänge sind uns insbesondere deshalb gestattet, weil sie durch den Europäischen Gesetzgeber
    besonders erwähnt wurden. Er vertrat insoweit die Auffassung, dass ein berechtigtes Interesse anzunehmen sein
    könnte, wenn die betroffene Person ein Kunde des Verantwortlichen ist &#40;Erwägungsgrund 47 Satz 2 DS-GVO&#41;.</p>
  <h2>8. Berechtigte Interessen an der Verarbeitung</h2>
  <p>die von dem Verantwortlichen oder einem Dritten verfolgt werden. Basiert die Verarbeitung personenbezogener Daten auf
    Artikel 6 I lit. f DS-GVO ist unser berechtigtes Interesse die Durchführung unserer Geschäftstätigkeit zugunsten des
    Wohlergehens all unserer Mitarbeiter und unserer Anteilseigner.</p>
  <h2>9. Dauer, für die die personenbezogenen Daten gespeichert werden</h2>
  <p>Das Kriterium für die Dauer der Speicherung von personenbezogenen Daten ist die jeweilige gesetzliche
    Aufbewahrungsfrist. Nach Ablauf der Frist werden die entsprechenden Daten routinemäßig gelöscht, sofern sie nicht
    mehr zur Vertragserfüllung oder Vertragsanbahnung erforderlich sind.</p>
  <h2>10. Gesetzliche oder vertragliche Vorschriften zur Bereitstellung der personenbezogenen Daten</h2>
  <p>Erforderlichkeit für den Vertragsabschluss; Verpflichtung der betroffenen Person, die personenbezogenen Daten
    bereitzustellen; mögliche Folgen der Nichtbereitstellung. Wir klären Sie darüber auf, dass die Bereitstellung
    personenbezogener Daten zum Teil gesetzlich vorgeschrieben ist &#40;z.B. Steuervorschriften&#41; oder sich auch aus
    vertraglichen Regelungen &#40;z.B. Angaben zum Vertragspartner&#41; ergeben kann. Mitunter kann es zu einem Vertragsschluss
    erforderlich sein, dass eine betroffene Person uns personenbezogene Daten zur Verfügung stellt, die in der Folge
    durch uns verarbeitet werden müssen. Die betroffene Person ist beispielsweise verpflichtet uns personenbezogene
    Daten bereitzustellen, wenn unser Unternehmen mit ihr einen Vertrag abschließt. Eine Nichtbereitstellung der
    personenbezogenen Daten hätte zur Folge, dass der Vertrag mit dem Betroffenen nicht geschlossen werden könnte. Vor
    einer Bereitstellung personenbezogener Daten durch den Betroffenen muss sich der Betroffene an einen unserer
    Mitarbeiter wenden. Unser Mitarbeiter klärt den Betroffenen einzelfallbezogen darüber auf, ob die Bereitstellung der
    personenbezogenen Daten gesetzlich oder vertraglich vorgeschrieben oder für den Vertragsabschluss erforderlich ist,
    ob eine Verpflichtung besteht, die personenbezogenen Daten bereitzustellen, und welche Folgen die
    Nichtbereitstellung der personenbezogenen Daten hätte.</p>
  <h2>Einverständniserklärung in die Erhebung und Verarbeitung von Daten durch die Praxis</h2>
  <p>Körperkonzepte, Daniela Kielkowski</p>
  <p>Für unseren Dienst erfolgt die Erhebung und Verarbeitung folgender personenbezogener Daten:</p>
  <ul>
    <li>
      <p>Name, Vorname</p>
    </li>
    <li>
      <p>Anschrift bzw. Adresse</p>
    </li>
    <li>
      <p>E-Mail-Adresse</p>
    </li>
    <li>
      <p>Zeitpunkt, Menge und Art der konsumierten Lebensmittel</p>
    </li>
    <li>
      <p>Gesundheitsdaten z.B. Körpergröße, Gewicht, Alter, Geschlecht, Grundumsatz, körperliche Aktivität</p>
    </li>
    <li>
      <p>Zahlungsart</p>
    </li>
  </ul>
  <p>Diese Daten werden auf dem Server von Daniela Kielkowski gespeichert und können nur von berechtigten Personen
    eingesehen werden. Wir versichern hiermit, dass die von uns durchgeführte EDV auf der Grundlage geltender Gesetze
    erfolgt und für das Zustandekommen des Vertragsverhältnisses notwendig ist. Darüber hinaus benötigt es für jede
    weitere Datenerhebung die Zustimmung des Nutzers. Eine automatische Löschung erfolgt nach 12 Monaten, insofern
    entsprechende Daten nicht weiter benötigt werden.</p>
  <p>Nutzerrechte</p>
  <p>Der Unterzeichnende hat das Recht, diese Einwilligung jederzeit ohne Angabe einer Begründung zu widerrufen. Weiterhin
    können erhobene Daten bei Bedarf korrigiert, gelöscht oder deren</p>
  <p>Erhebung eingeschränkt werden. Auf Anfrage können Sie unter der untenstehenden Adresse eine detaillierte Auskunft
    über den Umfang der von uns vorgenommenen Datenerhebung verlangen.</p>
  <p>Auch kann eine Datenübertragung angefordert werden, sollte der Unterzeichnende eine Übertragung seiner Daten an eine
    dritte Stelle wünschen.</p>
  <p>Folgen des Nicht-Unterzeichnens</p>
  <p>Der Unterzeichnende hat das Recht, dieser Einwilligungserklärung nicht zuzustimmen – da unser Dienst jedoch auf die
    Erhebung und Verarbeitung genannter Daten angewiesen sind, würde eine Nichtunterzeichnung eine Inanspruchnahme des
    Dienstes ausschließen.</p>
  <p>Kontakt</p>
  <p>Praxis Körperkonzepte, Daniela Kielkowski<br />Leibnizstraße 56<br />10629 Berlin<br />Deutschland</p>
  <p>Tel.: 030 889220-55<br />E-Mail: info@nutrick24.de</p></div>;