
import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Auth } from 'aws-amplify';
import useNavigate from '../../useNavigate';
import Logo from '../../images/nutrick24_Logo.png';
import Error from '../../components/Error';
import { buttonLabel, labelWithHtml } from '../../utils.js';
import { KKButton } from '../../components/Buttons';
import { COLORS } from '../../constants';
import { produce } from 'immer';
import LoginComp from './LoginComp';
import LoginForm from './LoginForm';

import { checks, reLower, reNum, reUpper, SignUpChecklistItem, validateEmail, CheckList } from './index';

const LostPasswordChange = (props) => {
  const [code, setCode] = useState('');
  const [email, setEmail] = useState('');
  const [pw1, setPw1] = useState('');
  const [pw2, setPw2] = useState('');
  const [checkStatus, setCheckStatus] = useState({});
  const [error, setError] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    setCheckStatus(produce(checkStatus, (draft) => {
      draft[checks[0]] = reLower.test(pw1);
      draft[checks[0]] = validateEmail(email);
      draft[checks[1]] = pw1.length >= 8;
      draft[checks[2]] = reNum.test(String(pw1));
      draft[checks[3]] = reLower.test(String(pw1));
      draft[checks[4]] = reUpper.test(String(pw1));
      draft[checks[5]] = pw1.length > 0 && pw1 === pw2;
      draft[checks[6]] = code.length > 0;
    }))
  }, [email, pw1, pw2, code, checkStatus])

  const callPWChange = () => {
    Auth.forgotPasswordSubmit(email, code, pw1)
      .then(() => {
        navigate('/login');
      })
      .catch(err => setError(err));
  };

  console.log(error)

  return <LoginComp>
    <a href="/" title="home">
      <img alt="Nutrick24" className="logo" src={Logo} />
    </a>
    <div className="info">
      Du hast bereits ein Konto? <a href="/login" onClick={() => { navigate("/login") }}>Hier geht's zur Anmeldung</a>
    </div>
    <LoginForm>
      <Form.Input
        name="code"
        label={buttonLabel('Codenummer', 'de')}
        placeholder={buttonLabel('Codenummer', 'de')}
        onChange={(e, d) => { setCode(d.value) }}
        value={code}
      />
      <Form.Input
        name="email"
        label={buttonLabel('EMail_Adresse', 'de')}
        placeholder={buttonLabel('EMail_Adresse', 'de')}
        onChange={(e, d) => { setEmail(d.value) }}
        value={email}
      />
      <Form.Input
        name="pw1"
        placeholder={buttonLabel('Passwort', 'de')}
        label={buttonLabel('Passwort', 'de')}
        type="password"
        onChange={(e, d) => { setPw1(d.value) }}
        value={pw1}
      />
      <Form.Input
        name="pw2"
        placeholder={buttonLabel('Passwort_bestaetigen', 'de')}
        label={buttonLabel('Passwort_bestaetigen', 'de')}
        type="password"
        onChange={(e, d) => { setPw2(d.value) }}
        value={pw2}
      />
      <CheckList>
        {checks.map(c => (
          <SignUpChecklistItem
            key={c}
            label={buttonLabel(c, 'de')}
            ok={checkStatus[c]}
          />
        ))}
      </CheckList>
      {error && <Error>
        <div
          dangerouslySetInnerHTML={{
            __html: labelWithHtml('Fehlerhafte_Angaben', 'de')
          }}
        />
      </Error>}
      <KKButton
        onClick={callPWChange}
        color={COLORS.BLUE}
        label="Speichern"
      />
    </LoginForm>
  </LoginComp>
};
export default LostPasswordChange;