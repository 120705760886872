import React, { useState, useCallback, useMemo, useEffect } from "react";
import { Auth } from "aws-amplify";
import { TitleBar, UploadPhoto } from "../common";
import styled from "styled-components";
import { Icon } from "semantic-ui-react";
import { get } from "lodash";
import useNavigate from "../../useNavigate";
import { useLocalStorage } from "usehooks-ts";
import { InfoSection } from "./praevention_2024-001";
import {
  useRecoilValue,
  useResetRecoilState,
  useRecoilState,
  useSetRecoilState,
} from "recoil";
import {
  accountState,
  courseRouteState,
  cognitoUserState,
  caseState,
  praxisVersionState,
  botIdState,
} from "../../atoms";
import { COLORS } from "../../constants";
import { AppUtils, validToday } from "../../utils";
import HomeSection from "./HomeSection";

import Prevention2024001 from "./praevention_2024-001";
import Reduction from "./Reduction";
import NutritionalConsultation from "./NutritionalConsultation";

import nutrickLogo from "../../images/nutrick24_Logo.png";
import praxisLogo from "../../images/praxis.svg";

const tabcolors = [
  "#365194",
  "#21325C",
  "#4F77DB",
  "#547EE8",
  "#4669C2",
  "#365194",
  "#21325C",
  "#4F77DB",
  "#547EE8",
  "#4669C2",
  "#365194",
  "#21325C",
  "#4F77DB",
  "#547EE8",
  "#4669C2",
];

const Home = () => {
  const accountdata = useRecoilValue(accountState);
  const course = useRecoilValue(courseRouteState);
  const resetCase = useResetRecoilState(caseState);
  const resetCognito = useResetRecoilState(cognitoUserState);
  const resetAccount = useResetRecoilState(accountState);
  const pcase = useRecoilValue(caseState);
  const [anamneseResult, setAnamneseResult] = useState(null);
  const [praxisVersion, setPraxisVersion] = useRecoilState(praxisVersionState);
  const setBotId = useSetRecoilState(botIdState);

  const goto = useNavigate();

  const logOut = useCallback(() => {
    Auth.signOut()
      .then(() => {
        resetAccount();
        resetCognito();
        resetCase();

        goto("/login");
      })
      .catch((err) => console.log(err));
  }, [goto, resetAccount, resetCase, resetCognito]);
  const linktoday = useMemo(() => AppUtils.getToday(), []);

  const hasPraevention = useMemo(
    () =>
      (get(accountdata, "payments", []) || []).findIndex(
        (payment) => payment.productId === "praevention_2024-001"
      ) >= 0 || course,
    [accountdata, course]
  );

  const hasReductionCurrent = useMemo(() => {
    let ok = false;
    (get(pcase, "plans", []) || []).forEach((pl) => {
      if (
        validToday(get(pl, "from", "99999999"), get(pl, "to", "00000000")) &&
        get(pl, "data.definition.type", "") === "reduction"
      ) {
        ok = true;
      }
    });
    return ok;
  }, [pcase]);

  const hasMaintenanceCurrent = useMemo(() => {
    let ok = false;
    (get(pcase, "plans", []) || []).forEach((pl) => {
      if (
        validToday(get(pl, "from", "99999999"), get(pl, "to", "00000000")) &&
        get(pl, "data.definition.type", "") === "maintenance"
      ) {
        ok = true;
      }
    });
    return ok;
  }, [pcase]);

  useEffect(() => {
    (get(pcase, "plans", []) || []).forEach((pl) => {
      if (
        validToday(get(pl, "from", "99999999"), get(pl, "to", "00000000")) &&
        get(pl, "data.definition.type", "") === "summary"
      ) {
        setAnamneseResult(pl);
      }
    });
  }, [pcase, setAnamneseResult]);

  const hasPayments = useMemo(
    () =>
      (get(accountdata, "payments", []) || []).filter(
        (p) => get(p, "productId", "") !== "productId"
      ).length > 0 ||
      hasMaintenanceCurrent ||
      hasReductionCurrent,
    [accountdata, hasMaintenanceCurrent, hasReductionCurrent]
  );
  useEffect(() => {
    if (hasPayments) {
      setBotId(
        "https://storage.googleapis.com/landbot.site/v3/H-1272997-X0S5VF82IM731T6Y/index.json"
      );
    }
  }, [hasPayments, setBotId]);

  const sections = useMemo(
    () => [
      anamneseResult && {
        id: "anamnesesummary",
        name: "Auswertung Anamnese",
        content: (
          <SimpleContent
            className="anamnesesummary"
            dangerouslySetInnerHTML={{
              __html: get(anamneseResult, "data.i18n.de.definition.intro"),
            }}
          />
        ),
      },
      !hasPraevention && {
        id: "initcons",
        name: "Ernährungsmedizinisches Erstgespräch",
        subtitle: "",
        content: <NutritionalConsultation />,
      },
      hasReductionCurrent
        ? {
            id: "reduktion",
            name: "Gewichtsreduktion",
            subtitle: "",
            link: `/reduction/${linktoday}`,
          }
        : !hasPraevention && {
            id: "reduktion",
            name: "Gewichtsreduktion",
            subtitle: "",
            content: <Reduction />,
          },
      hasMaintenanceCurrent && {
        id: "maint",
        name: "Gewichtserhaltung",
        subtitle: "",
        link: `/maintenance/${linktoday}`,
      },
      {
        id: "praevention_2024-001",
        name: "Onlinekurs",
        subtitle:
          "Mit Leichtigkeit die Schwere los - Online-Ernährungskurs zur Vermeidung und Reduktion von Übergewicht",
        content: <Prevention2024001 />,
      },
      !hasPraevention && {
        name: "Blog",
        content: (
          <SimpleContent>
            Aktuelle Themen und wissenswerte Details können Sie in unserem{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.nutrick24.de/blog/"
            >
              Blog
            </a>{" "}
            nachlesen.{" "}
            <a
              rel="noreferrer"
              target="_blank"
              href="https://www.nutrick24.de/blog/"
            >
              Bitte hier klicken
            </a>{" "}
          </SimpleContent>
        ),
      },
      hasPayments &&
        !hasPraevention && {
          name: "Gesundheitsprotokolle",
          content: (
            <div>
              <ul>
                <li
                  className={get(accountdata, "eat") ? "active" : "inactive"}
                  onClick={() => {
                    get(accountdata, "eat") &&
                      goto(`/protocols/eat/${linktoday}`);
                  }}
                >
                  Ernährungstagebuch
                  {!get(accountdata, "eat") && <Icon name="lock" />}
                </li>
                <li
                  className={
                    get(accountdata, "blutdruck") ? "active" : "inactive"
                  }
                  onClick={() => {
                    get(accountdata, "blutdruck") &&
                      goto(`/protocols/bloodpressure/${linktoday}`);
                  }}
                >
                  Blutdruck
                  {!get(accountdata, "blutdruck") && <Icon name="lock" />}
                </li>
                <li
                  className={
                    get(accountdata, "blutzucker") ? "active" : "inactive"
                  }
                  onClick={() => {
                    get(accountdata, "blutzucker") &&
                      goto(`/protocols/bloodsugar/${linktoday}`);
                  }}
                >
                  Blutzucker
                  {!get(accountdata, "blutzucker") && <Icon name="lock" />}
                </li>
                <li
                  className={
                    get(accountdata, "gewicht") ? "active" : "inactive"
                  }
                  onClick={() => {
                    get(accountdata, "gewicht") &&
                      goto(`/protocols/weight/${linktoday}`);
                  }}
                >
                  Gewicht{!get(accountdata, "gewicht") && <Icon name="lock" />}
                </li>
                <li
                  className={
                    get(accountdata, "symptomtagebuch") ? "active" : "inactive"
                  }
                  onClick={() => {
                    get(accountdata, "symptomtagebuch") &&
                      goto(`/protocols/symptoms/${linktoday}`);
                  }}
                >
                  Symptomtagebuch
                  {!get(accountdata, "symptomtagebuch") && <Icon name="lock" />}
                </li>
                <li
                  className={
                    get(accountdata, "bewegungstagebuch")
                      ? "active"
                      : "inactive"
                  }
                  onClick={() => {
                    get(accountdata, "bewegungstagebuch") &&
                      goto(`/protocols/sport/${linktoday}`);
                  }}
                >
                  Bewegungstagebuch
                  {!get(accountdata, "bewegungstagebuch") && (
                    <Icon name="lock" />
                  )}
                </li>
              </ul>
            </div>
          ),
        },
      !hasPraevention && {
        name: "Stoffwechselanalyse",
        content: (
          <div>
            <ul>
              <HomeSection title="uVida Atemgasanalyse">
                <p>
                  Unsere Atemgasanalyse zeigt Ihnen, wie effektiv Ihr Körper
                  Fett verbrennt und wie leistungsfähig Ihr Stoffwechsel ist.
                </p>
                <li
                  onClick={() => {
                    goto("/uvida");
                  }}
                >
                  Zur uVida-Vermessung
                  <Icon name="chevron right" />
                </li>
                <UploadPhoto prefix="proto_sw_" />
              </HomeSection>
              <HomeSection title="InBody Bio-Impedanz-Analyse">
                <p>
                  Unsere Bio-Impedanz-Analyse teilt Ihr Körpergewicht auf und
                  zeigt, wieviel davon Muskeln, Fett und Wasser sind, wo sich
                  Ihr Körperfett befindet, wieviel Bauchfett Sie haben und was
                  Ihre idealen Werte sein sollten.
                </p>
                <li
                  onClick={() => {
                    goto("/inbody");
                  }}
                >
                  Zur InBody-Vermessung
                  <Icon name="chevron right" />
                </li>
                <UploadPhoto prefix="proto_bio_" />
              </HomeSection>
            </ul>
          </div>
        ),
      },
      {
        name: "Ansprechpartner",
        alwaysActive: true,
        content: (
          <div>
            <ul>
              <InfoSection icon="info circle" title="Fachliche Betreuung">
                <p>Leibnizstraße 56, 10629 Berlin</p>
                <div className="files">
                  <div>
                    <Icon name="phone" />
                    <a rel="noreferrer" href="tel:03023635224">
                      030 236 35 224
                    </a>
                  </div>
                  <div>
                    <Icon name="mail" />
                    <a rel="noreferrer" href="mailto:info@nutrick24.de">
                      info@nutrick24.de
                    </a>
                  </div>
                  <div>
                    <Icon name="globe" />
                    <a
                      rel="noreferrer"
                      href="https://www.daniela-kielkowski.de"
                      target="_blank"
                    >
                      https://www.daniela-kielkowski.de
                    </a>{" "}
                  </div>
                </div>
              </InfoSection>
              <InfoSection icon="info circle" title="Technische Betreuung">
                <p>Leibnizstraße 56, 10629 Berlin</p>
                <div className="files">
                  <div>
                    <Icon name="phone" />
                    <a rel="noreferrer" href="tel:03023635224">
                      030 236 35 224
                    </a>
                  </div>
                  <div>
                    <Icon name="mail" />
                    <a rel="noreferrer" href="mailto:info@nutrick24.de">
                      info@nutrick24.de
                    </a>
                  </div>
                  <div>
                    <Icon name="globe" />
                    <a
                      rel="noreferrer"
                      href="https://www.daniela-kielkowski.de"
                      target="_blank"
                    >
                      https://www.daniela-kielkowski.de
                    </a>{" "}
                  </div>
                </div>
              </InfoSection>
            </ul>
          </div>
        ),
      },
      {
        name: "Abmelden",
        alwaysActive: true,
        content: (
          <div>
            <ul>
              <li
                onClick={() => {
                  logOut();
                }}
              >
                Abmelden
              </li>
              {process.env.NODE_ENV === "development" && (
                <li
                  onClick={() => {
                    setPraxisVersion(!praxisVersion);
                  }}
                >
                  Umschalten zu Version {praxisVersion ? "nutrick24" : "Praxis"}
                </li>
              )}
            </ul>
          </div>
        ),
      },
    ],
    [
      accountdata,
      goto,
      linktoday,
      logOut,
      hasReductionCurrent,
      anamneseResult,
      hasMaintenanceCurrent,
      hasPraevention,
      praxisVersion,
      setPraxisVersion,
      hasPayments,
      course,
    ]
  );
  const initIndex = useMemo(() => {
    let index = sections
      .filter((s) => s)
      .findIndex((item) => item.id === (course || "no"));
    return index >= 0 ? index : sections.length;
  }, [course, sections]);
  const [currentSectionIndex, setCurrentSectionIndex] = useLocalStorage(
    "nutrick24_home_navstat",
    initIndex
  );

  return (
    <div>
      <TitleBar label="Übersicht" />
      <StyledMenu>
        {praxisVersion ? (
          <a
            href="https://www.daniela-kielkowski.de"
            target="_blank"
            rel="noreferrer"
          >
            <div className="logos">
              <img alt="" src={praxisLogo} />
            </div>
          </a>
        ) : (
          <a href="https://nutrick24.de" target="_blank" rel="noreferrer">
            <div className="logos">
              <img alt="" src={nutrickLogo} />
            </div>
          </a>
        )}
        {sections
          .filter((s) => s)
          .map((section, index) => (
            <MenuItem
              key={section.name}
              active={`${
                index === currentSectionIndex
                  ? "active"
                  : section.alwaysActive
                  ? "active"
                  : "inactive"
              } `}
              layer={index}
              setCurrentSectionIndex={setCurrentSectionIndex}
              index={index}
              currentSectionIndex={currentSectionIndex}
              background={tabcolors[index]}
              goto={goto}
              link={section.link}
              name={section.name}
              subtitle={section.subtitle}
            >
              {section.content}
            </MenuItem>
          ))}
      </StyledMenu>
    </div>
  );
};

export default Home;

const MenuItem = (props) => {
  const {
    link,
    name,
    subtitle,
    goto,
    active,
    index,
    currentSectionIndex,
    setCurrentSectionIndex,
  } = props;
  return (
    <MenuItemS {...props} className={active}>
      <div
        className="header"
        onClick={() => {
          link
            ? goto(link)
            : setCurrentSectionIndex(
                index === currentSectionIndex ? null : index
              );
        }}
      >
        <div>{name}</div>
        {subtitle && <div className="sub">{subtitle}</div>}
      </div>
      <div className={`content ${active}`}>{props.children}</div>
    </MenuItemS>
  );
};

const SimpleContent = styled.div`
  padding: 1rem;
  &.anamnesesummary {
    * {
      background: transparent !important;
      color: white !important;
    }
    ul {
      margin: 0 !important;
      padding: 0 !important;
      li {
        padding-left: 1rem;
        border: none !important;
        list-style-position: inline;
        list-style-type: circle !important;
      }
    }
  }
`;

const MenuItemS = styled.div`
  padding: 0 1rem 3rem 1rem;
  background-color: ${(props) => props.background};
  transition: padding 0.5s ease-in-out;
  color: white;
  border-top-left-radius: 3rem;
  border-top-right-radius: 3rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px -5px 19px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0px -5px 19px -2px rgba(0, 0, 0, 0.2);
  z-index: ${(props) => props.layer};
  margin-top: -4rem;
  width: 100%;
  .header {
    padding: 1rem;
    margin: 0;
    color: white;
    font-size: 1.5rem;
    .sub {
      font-size: 1rem;
    }
    cursor: pointer;
  }
  &:first-child {
    margin-top: 2rem;
  }
  &:last-child {
    min-height: 100vh;
  }
  //&.inactive{
  //  height: 8rem;
  //}
  &.active {
    padding: 0 1rem 4rem 1rem;
    transition: padding 0.5s linear;
  }
  h4 {
    margin-bottom: 1rem;
  }
  .content {
    &.inactive {
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: all 0.5s linear;
    }
    &.active {
      max-height: auto;
      opacity: 1;
      overflow: hidden;
      transition: all 0.5s linear;
      padding: 0 1rem;
      margin: 0 -1rem;
    }
    ul {
      margin: 1rem 0;
      padding: 0;
      li {
        list-style-type: none;
        padding: 0.75rem 1rem;
        color: white;
        border: 1px solid white;
        border-radius: 1.5rem;
        cursor: pointer;
        &.inactive {
          cursor: default;
          color: rgba(255, 255, 255, 0.5);
          border-color: rgba(255, 255, 255, 0.5);
        }
        margin-bottom: 1rem;
        display: flex;
        justify-content: space-between;
        i:first-child {
          margin-right: 1rem;
        }
      }
    }
    a {
      color: white !important;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`;
const StyledMenu = styled.div`
  img {
    margin: 0rem 0 4rem 0;
  }
  .logos {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      margin: 0 auto 0 auto;
      max-width: 50%;
    }
    margin: 0 0 4rem 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 1rem 0;
  gap: 1rem;
  .menu-item {
    flex: 1 1 auto;
    cursor: pointer;
    border: 1px solid ${COLORS.BLUE};
    color: ${COLORS.BLUE};
    font-size: 1.4rem;
    line-height: 1.3em;
    background: white;
    border-radius: 1.5rem;
    padding: 0.5rem 0.5rem;
    width: 100%;
    text-align: center;
  }
`;
