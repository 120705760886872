import React, { useState } from "react";
import styled from 'styled-components';
import DatePicker from 'react-calendar';
import { Icon } from 'semantic-ui-react';
import useNavigate from '../../useNavigate';
import { COLORS } from '../../constants';
import { AppUtils } from '../../utils';
import moment from 'moment';

const Comp = (props) => {
  const { day, baseroute } = props;
  const [datepickerDayOpen, setDatepickerDayOpen] = useState(false);
  const goto = useNavigate();

  return <><CalWrapper>
    <button onClick={() => { goto(`${baseroute}/${moment(day).subtract(1, "days").format('yyyyMMDD')}`) }}>
      <Icon name="angle left" />
    </button>
    <div className="day" onClick={() => { setDatepickerDayOpen(!datepickerDayOpen) }}>{AppUtils.getDayMonthFromTxt(day).day}.{AppUtils.getDayMonthFromTxt(day).month}.{AppUtils.getDayMonthFromTxt(day).year}</div>
    <button onClick={() => { goto(`${baseroute}/${moment(day).add(1, "days").format('yyyyMMDD')}`) }}>
      <Icon name="angle right" />
    </button>
  </CalWrapper>
    {datepickerDayOpen &&
      <DatePickerWrapper>
        <DatePicker
          locale="de"
          value={moment(day)}
          onChange={(d) => { setDatepickerDayOpen(false); goto(`${baseroute}/${moment(d).format('yyyyMMDD')}`) }}
          tileContent={({ date, view }) =>
            view === 'month' ? (
              <div
                style={{
                  width: '1.7rem',
                  height: '1.7rem',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginLeft: '-0.85rem',
                  marginTop: '-0.85rem',
                }}
              />
            ) : null
          }
        />
      </DatePickerWrapper>
    }
  </>
}
export default Comp;

const DatePickerWrapper = styled.div`
  background:white;
  border-radius:1rem;
  padding:1rem;
  margin:1rem 0;
  button{
    border:none;
    background:none;
  }
  .react-calendar__navigation{
    display:flex;
    justify-content:center;
    .react-calendar__navigation__arrow{
      font-size:2rem;
      color:${COLORS.ORANGE}
    }
    .react-calendar__navigation__label__labelText{
      font-weight:bold;
    }
  }
  .react-calendar__month-view__weekdays__weekday{
    text-align:center;
    text-decoration:none;
    cursor:pointer;
  }
  .react-calendar__tile{
    font-size:1.1rem;
    padding:0.5rem;
    cursor:pointer;
  }
`;

const CalWrapper = styled.div`
  display:flex;
  justify-content:center;
  align-items: center;
  flex-wrap:none;
  button{
    cursor:pointer;
  }
  i{
    font-size:2rem;
    color:${COLORS.BLUE};
  }
  button{
    background:none;
    border:none;
  }
  .day{
    cursor:pointer;
    background:${COLORS.ORANGE};
    padding:0.5rem 4rem;
    border-radius:2rem;
    color:white;
    font-weight:bold;
    margin:0 1rem;
  }
`;