
import React, { useState, useEffect } from 'react';
import { Form } from 'semantic-ui-react';
import { Auth, API } from 'aws-amplify';
import useNavigate from '../../useNavigate';
import Logo from '../../images/nutrick24_Logo.png';
import Error from '../../components/Error';
import { buttonLabel, AppUtils } from '../../utils.js';
import { KKButton } from '../../components/Buttons';
import { COLORS } from '../../constants';
import { useSetRecoilState } from 'recoil';
import { cognitoUserState } from '../../atoms';
import { produce } from 'immer';
import LoginComp from './LoginComp';
import LoginForm from './LoginForm';

import { checks, reLower, reNum, reUpper, SignUpChecklistItem, validateEmail, CheckList } from './index';

const SignUp = (props) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [pw1, setPw1] = useState('');
  const [pw2, setPw2] = useState('');
  const [checkStatus, setCheckStatus] = useState({});
  const [error, setError] = useState();
  const navigate = useNavigate();
  const setCognitoUser = useSetRecoilState(cognitoUserState);

  useEffect(() => {
    setCheckStatus(produce(checkStatus, (draft) => {
      draft[checks[0]] = reLower.test(pw1);
      draft[checks[0]] = validateEmail(email);
      draft[checks[1]] = pw1.length >= 8;
      draft[checks[2]] = reNum.test(String(pw1));
      draft[checks[3]] = reLower.test(String(pw1));
      draft[checks[4]] = reUpper.test(String(pw1));
      draft[checks[5]] = pw1.length > 0 && pw1 === pw2;
      draft[checks[6]] = name.length > 0;
    }))
  }, [email, pw1, pw2, name, checkStatus])

  const callSignup = () => {
    setLoading(true);
    Auth.signUp({
      username: email,
      password: pw1,
    })
      .then(user =>
        Promise.all([
          Auth.signIn(email, pw1).then(() =>
            Auth.currentAuthenticatedUser().then(u => {
              const user = u.attributes;
              const account = u.attributes.sub;
              AppUtils.putS3Data(`user/${account}/account.json`, {
                benutzer: name,
                name: account,
                email: email,
                createdAt: Date.now()
              })
                .then(() => {
                  API.post('kkapi2019', '/mailonregister', {
                    body: {
                      username: name,
                      email: email,
                    },
                  });
                  setCognitoUser(user);
                  navigate("/");
                })
                .catch(e => {
                  // eslint-disable-next-line no-console
                  console.log(e);
                });
            }),
          ),
        ]).then(() => { setLoading(false) }).catch(err => {
          setLoading(false);
          setError(err)
        })
      )
      .catch(err => {
        setLoading(false);
        setError(err)
      });
  };

  return <LoginComp>
    <a href="/" title="home">
      <img alt="Nutrick24" className="logo" src={Logo} />
    </a>
    <div className="info">
      Du hast bereits ein Konto? <a href="/login" onClick={() => { navigate("/login") }}>Hier geht's zur Anmeldung</a>
    </div>
    <LoginForm>
      <Form.Input
        name="name"
        label={buttonLabel('Name', 'de')}
        placeholder={buttonLabel('Name', 'de')}
        onChange={(e, d) => { setName(d.value) }}
        value={name}
      />
      <Form.Input
        name="email"
        label={buttonLabel('EMail_Adresse', 'de')}
        placeholder={buttonLabel('EMail_Adresse', 'de')}
        onChange={(e, d) => { setEmail(d.value) }}
        value={email}
      />
      <Form.Input
        name="pw1"
        placeholder={buttonLabel('Passwort', 'de')}
        label={buttonLabel('Passwort', 'de')}
        type="password"
        onChange={(e, d) => { setPw1(d.value) }}
        value={pw1}
      />
      <Form.Input
        name="pw2"
        placeholder={buttonLabel('Passwort_bestaetigen', 'de')}
        label={buttonLabel('Passwort_bestaetigen', 'de')}
        type="password"
        onChange={(e, d) => { setPw2(d.value) }}
        value={pw2}
      />
      <CheckList>
        {checks.map(c => (
          <SignUpChecklistItem
            key={c}
            label={buttonLabel(c, 'de')}
            ok={checkStatus[c]}
          />
        ))}
      </CheckList>
      {error && <Error>
        {error.code === 'UsernameExistsException' ? (
          <div>
            Es existiert bereits ein Konto mit dieser Mail-Adresse. <a href="/login" onClick={() => { navigate("/login") }}>Hier geht's zur Anmeldung</a>
          </div>
        ) : null}
      </Error>}
      <KKButton
        onClick={callSignup}
        color={COLORS.BLUE}
        label={loading ? 'Account wird angelegt...' : buttonLabel('Registrieren', 'de')}
      />
    </LoginForm>
  </LoginComp>
};
export default SignUp;