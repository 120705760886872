import React, { useEffect } from 'react';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { useRecoilValue } from "recoil";
import { Icon } from 'semantic-ui-react';
import moment from 'moment';
import { AppUtils } from "../../utils";
import { Auth } from "aws-amplify";
import { API } from "../../constants";
import { get } from "lodash";
import request from 'superagent';
import {
    cognitoUserState,
    accountState
} from "../../atoms";

export function usePhoto(cognitoUser, account, prefix, cb) {
    const takePhoto = async () => {
        console.log(account);
        const photo = await Camera.getPhoto({
            resultType: CameraResultType.Base64,
            quality: 100,
            width: 4000,
            source: CameraSource.Photos,
            promptLabelCancel: 'Bestehendes Foto',
            promptLabelPicture: 'Neues Foto',
            promptLabelSelect: 'Datei auswählen',
            promptLabelHeader: 'Foto auswählen oder erstellen'
        });
        const blob = base64ToBlob(photo.base64String, `image/${photo.format}`)
        AppUtils.putS3Binary(`user/${cognitoUser.sub}/uploads/${prefix}_${Date.now()}.${photo.format}`, blob, `image/${photo.format}`)
            .then(() => {
                cb && cb();
                Auth.currentSession().then((data) => {
                    const t = get(data, "idToken.jwtToken", "");
                    request
                        .put(
                            `${API}/events`
                        ).set("Authorization", `Bearer ${t}`).send({
                            "eventType": "userFileUpload",
                            "user": {
                                "id": get(account, "name", ''),
                                "email": get(account, "email", ''),
                                "benutzer": get(account, "benutzer", '')
                            },
                            "fileupload": {
                                "prefix": prefix === "proto_sw_" ? "uVida" : "InBody"
                            }
                        })
                        .catch((err) => {
                            console.log("error event fileuploads", err);
                        });
                });
            });
    };
    return {
        takePhoto,
    };
}
export default function UploadPhoto({ prefix }) {
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState();
    const cognitoUser = useRecoilValue(cognitoUserState);
    const accountdata = useRecoilValue(accountState);
    const { takePhoto } = usePhoto(cognitoUser, accountdata, prefix, () => { setReload(Date.now()) });
    const [files, setFiles] = React.useState([]);
    const startPhoto = async () => {
        setLoading(true);
        takePhoto();
        setReload(Date.now())
        setLoading(false)
    };

    useEffect(() => {
        if (cognitoUser) {
            AppUtils.listS3ItemsWithPrefix(`user/${cognitoUser.sub}/uploads/${prefix}`).then((data) => {
                setFiles(data || [])
            })
        }
    }, [prefix, cognitoUser, reload]);

    const deleteFile = (key) => {
        if (key.includes(prefix) && key.includes(cognitoUser.sub)) {
            AppUtils.delS3Data(key).then(() => { setReload(Date.now()) })
        }
    }

    return <>
        {files.map((file, i) => {
            const t = moment.utc(parseInt(file.key.split('__').pop().split('.')[0])).local().format('DD.MM.YYYY HH:mm:ss');
            return (<li key={file.key}><div>{t}</div><Icon name="remove" color="red" onClick={() => deleteFile(file.key)} /></li>);
        })}
        <li onClick={() => startPhoto()}><div><Icon loading={loading} name="photo" />Hinzufügen</div></li>
    </>;
}
function base64ToBlob(base64String, type) {
    const sliceSize = 1024;
    const byteCharacters = atob(base64String);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);

        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type });
}