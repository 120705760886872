export const valuesWeight = [
  "15",
  "15,1",
  "15,2",
  "15,3",
  "15,4",
  "15,5",
  "15,6",
  "15,7",
  "15,8",
  "15,9",
  "16",
  "16,1",
  "16,2",
  "16,3",
  "16,4",
  "16,5",
  "16,6",
  "16,7",
  "16,8",
  "16,9",
  "17",
  "17,1",
  "17,2",
  "17,3",
  "17,4",
  "17,5",
  "17,6",
  "17,7",
  "17,8",
  "17,9",
  "18",
  "18,1",
  "18,2",
  "18,3",
  "18,4",
  "18,5",
  "18,6",
  "18,7",
  "18,8",
  "18,9",
  "19",
  "19,1",
  "19,2",
  "19,3",
  "19,4",
  "19,5",
  "19,6",
  "19,7",
  "19,8",
  "19,9",
  "20",
  "20,1",
  "20,2",
  "20,3",
  "20,4",
  "20,5",
  "20,6",
  "20,7",
  "20,8",
  "20,9",
  "21",
  "21,1",
  "21,2",
  "21,3",
  "21,4",
  "21,5",
  "21,6",
  "21,7",
  "21,8",
  "21,9",
  "22",
  "22,1",
  "22,2",
  "22,3",
  "22,4",
  "22,5",
  "22,6",
  "22,7",
  "22,8",
  "22,9",
  "23",
  "23,1",
  "23,2",
  "23,3",
  "23,4",
  "23,5",
  "23,6",
  "23,7",
  "23,8",
  "23,9",
  "24",
  "24,1",
  "24,2",
  "24,3",
  "24,4",
  "24,5",
  "24,6",
  "24,7",
  "24,8",
  "24,9",
  "25",
  "25,1",
  "25,2",
  "25,3",
  "25,4",
  "25,5",
  "25,6",
  "25,7",
  "25,8",
  "25,9",
  "26",
  "26,1",
  "26,2",
  "26,3",
  "26,4",
  "26,5",
  "26,6",
  "26,7",
  "26,8",
  "26,9",
  "27",
  "27,1",
  "27,2",
  "27,3",
  "27,4",
  "27,5",
  "27,6",
  "27,7",
  "27,8",
  "27,9",
  "28",
  "28,1",
  "28,2",
  "28,3",
  "28,4",
  "28,5",
  "28,6",
  "28,7",
  "28,8",
  "28,9",
  "29",
  "29,1",
  "29,2",
  "29,3",
  "29,4",
  "29,5",
  "29,6",
  "29,7",
  "29,8",
  "29,9",
  "30",
  "30,1",
  "30,2",
  "30,3",
  "30,4",
  "30,5",
  "30,6",
  "30,7",
  "30,8",
  "30,9",
  "31",
  "31,1",
  "31,2",
  "31,3",
  "31,4",
  "31,5",
  "31,6",
  "31,7",
  "31,8",
  "31,9",
  "32",
  "32,1",
  "32,2",
  "32,3",
  "32,4",
  "32,5",
  "32,6",
  "32,7",
  "32,8",
  "32,9",
  "33",
  "33,1",
  "33,2",
  "33,3",
  "33,4",
  "33,5",
  "33,6",
  "33,7",
  "33,8",
  "33,9",
  "34",
  "34,1",
  "34,2",
  "34,3",
  "34,4",
  "34,5",
  "34,6",
  "34,7",
  "34,8",
  "34,9",
  "35",
  "35,1",
  "35,2",
  "35,3",
  "35,4",
  "35,5",
  "35,6",
  "35,7",
  "35,8",
  "35,9",
  "36",
  "36,1",
  "36,2",
  "36,3",
  "36,4",
  "36,5",
  "36,6",
  "36,7",
  "36,8",
  "36,9",
  "37",
  "37,1",
  "37,2",
  "37,3",
  "37,4",
  "37,5",
  "37,6",
  "37,7",
  "37,8",
  "37,9",
  "38",
  "38,1",
  "38,2",
  "38,3",
  "38,4",
  "38,5",
  "38,6",
  "38,7",
  "38,8",
  "38,9",
  "39",
  "39,1",
  "39,2",
  "39,3",
  "39,4",
  "39,5",
  "39,6",
  "39,7",
  "39,8",
  "39,9",
  "40",
  "40,1",
  "40,2",
  "40,3",
  "40,4",
  "40,5",
  "40,6",
  "40,7",
  "40,8",
  "40,9",
  "41",
  "41,1",
  "41,2",
  "41,3",
  "41,4",
  "41,5",
  "41,6",
  "41,7",
  "41,8",
  "41,9",
  "42",
  "42,1",
  "42,2",
  "42,3",
  "42,4",
  "42,5",
  "42,6",
  "42,7",
  "42,8",
  "42,9",
  "43",
  "43,1",
  "43,2",
  "43,3",
  "43,4",
  "43,5",
  "43,6",
  "43,7",
  "43,8",
  "43,9",
  "44",
  "44,1",
  "44,2",
  "44,3",
  "44,4",
  "44,5",
  "44,6",
  "44,7",
  "44,8",
  "44,9",
  "45",
  "45,1",
  "45,2",
  "45,3",
  "45,4",
  "45,5",
  "45,6",
  "45,7",
  "45,8",
  "45,9",
  "46",
  "46,1",
  "46,2",
  "46,3",
  "46,4",
  "46,5",
  "46,6",
  "46,7",
  "46,8",
  "46,9",
  "47",
  "47,1",
  "47,2",
  "47,3",
  "47,4",
  "47,5",
  "47,6",
  "47,7",
  "47,8",
  "47,9",
  "48",
  "48,1",
  "48,2",
  "48,3",
  "48,4",
  "48,5",
  "48,6",
  "48,7",
  "48,8",
  "48,9",
  "49",
  "49,1",
  "49,2",
  "49,3",
  "49,4",
  "49,5",
  "49,6",
  "49,7",
  "49,8",
  "49,9",
  "50",
  "50,1",
  "50,2",
  "50,3",
  "50,4",
  "50,5",
  "50,6",
  "50,7",
  "50,8",
  "50,9",
  "51",
  "51,1",
  "51,2",
  "51,3",
  "51,4",
  "51,5",
  "51,6",
  "51,7",
  "51,8",
  "51,9",
  "52",
  "52,1",
  "52,2",
  "52,3",
  "52,4",
  "52,5",
  "52,6",
  "52,7",
  "52,8",
  "52,9",
  "53",
  "53,1",
  "53,2",
  "53,3",
  "53,4",
  "53,5",
  "53,6",
  "53,7",
  "53,8",
  "53,9",
  "54",
  "54,1",
  "54,2",
  "54,3",
  "54,4",
  "54,5",
  "54,6",
  "54,7",
  "54,8",
  "54,9",
  "55",
  "55,1",
  "55,2",
  "55,3",
  "55,4",
  "55,5",
  "55,6",
  "55,7",
  "55,8",
  "55,9",
  "56",
  "56,1",
  "56,2",
  "56,3",
  "56,4",
  "56,5",
  "56,6",
  "56,7",
  "56,8",
  "56,9",
  "57",
  "57,1",
  "57,2",
  "57,3",
  "57,4",
  "57,5",
  "57,6",
  "57,7",
  "57,8",
  "57,9",
  "58",
  "58,1",
  "58,2",
  "58,3",
  "58,4",
  "58,5",
  "58,6",
  "58,7",
  "58,8",
  "58,9",
  "59",
  "59,1",
  "59,2",
  "59,3",
  "59,4",
  "59,5",
  "59,6",
  "59,7",
  "59,8",
  "59,9",
  "60",
  "60,1",
  "60,2",
  "60,3",
  "60,4",
  "60,5",
  "60,6",
  "60,7",
  "60,8",
  "60,9",
  "61",
  "61,1",
  "61,2",
  "61,3",
  "61,4",
  "61,5",
  "61,6",
  "61,7",
  "61,8",
  "61,9",
  "62",
  "62,1",
  "62,2",
  "62,3",
  "62,4",
  "62,5",
  "62,6",
  "62,7",
  "62,8",
  "62,9",
  "63",
  "63,1",
  "63,2",
  "63,3",
  "63,4",
  "63,5",
  "63,6",
  "63,7",
  "63,8",
  "63,9",
  "64",
  "64,1",
  "64,2",
  "64,3",
  "64,4",
  "64,5",
  "64,6",
  "64,7",
  "64,8",
  "64,9",
  "65",
  "65,1",
  "65,2",
  "65,3",
  "65,4",
  "65,5",
  "65,6",
  "65,7",
  "65,8",
  "65,9",
  "66",
  "66,1",
  "66,2",
  "66,3",
  "66,4",
  "66,5",
  "66,6",
  "66,7",
  "66,8",
  "66,9",
  "67",
  "67,1",
  "67,2",
  "67,3",
  "67,4",
  "67,5",
  "67,6",
  "67,7",
  "67,8",
  "67,9",
  "68",
  "68,1",
  "68,2",
  "68,3",
  "68,4",
  "68,5",
  "68,6",
  "68,7",
  "68,8",
  "68,9",
  "69",
  "69,1",
  "69,2",
  "69,3",
  "69,4",
  "69,5",
  "69,6",
  "69,7",
  "69,8",
  "69,9",
  "70",
  "70,1",
  "70,2",
  "70,3",
  "70,4",
  "70,5",
  "70,6",
  "70,7",
  "70,8",
  "70,9",
  "71",
  "71,1",
  "71,2",
  "71,3",
  "71,4",
  "71,5",
  "71,6",
  "71,7",
  "71,8",
  "71,9",
  "72",
  "72,1",
  "72,2",
  "72,3",
  "72,4",
  "72,5",
  "72,6",
  "72,7",
  "72,8",
  "72,9",
  "73",
  "73,1",
  "73,2",
  "73,3",
  "73,4",
  "73,5",
  "73,6",
  "73,7",
  "73,8",
  "73,9",
  "74",
  "74,1",
  "74,2",
  "74,3",
  "74,4",
  "74,5",
  "74,6",
  "74,7",
  "74,8",
  "74,9",
  "75",
  "75,1",
  "75,2",
  "75,3",
  "75,4",
  "75,5",
  "75,6",
  "75,7",
  "75,8",
  "75,9",
  "76",
  "76,1",
  "76,2",
  "76,3",
  "76,4",
  "76,5",
  "76,6",
  "76,7",
  "76,8",
  "76,9",
  "77",
  "77,1",
  "77,2",
  "77,3",
  "77,4",
  "77,5",
  "77,6",
  "77,7",
  "77,8",
  "77,9",
  "78",
  "78,1",
  "78,2",
  "78,3",
  "78,4",
  "78,5",
  "78,6",
  "78,7",
  "78,8",
  "78,9",
  "79",
  "79,1",
  "79,2",
  "79,3",
  "79,4",
  "79,5",
  "79,6",
  "79,7",
  "79,8",
  "79,9",
  "80",
  "80,1",
  "80,2",
  "80,3",
  "80,4",
  "80,5",
  "80,6",
  "80,7",
  "80,8",
  "80,9",
  "81",
  "81,1",
  "81,2",
  "81,3",
  "81,4",
  "81,5",
  "81,6",
  "81,7",
  "81,8",
  "81,9",
  "82",
  "82,1",
  "82,2",
  "82,3",
  "82,4",
  "82,5",
  "82,6",
  "82,7",
  "82,8",
  "82,9",
  "83",
  "83,1",
  "83,2",
  "83,3",
  "83,4",
  "83,5",
  "83,6",
  "83,7",
  "83,8",
  "83,9",
  "84",
  "84,1",
  "84,2",
  "84,3",
  "84,4",
  "84,5",
  "84,6",
  "84,7",
  "84,8",
  "84,9",
  "85",
  "85,1",
  "85,2",
  "85,3",
  "85,4",
  "85,5",
  "85,6",
  "85,7",
  "85,8",
  "85,9",
  "86",
  "86,1",
  "86,2",
  "86,3",
  "86,4",
  "86,5",
  "86,6",
  "86,7",
  "86,8",
  "86,9",
  "87",
  "87,1",
  "87,2",
  "87,3",
  "87,4",
  "87,5",
  "87,6",
  "87,7",
  "87,8",
  "87,9",
  "88",
  "88,1",
  "88,2",
  "88,3",
  "88,4",
  "88,5",
  "88,6",
  "88,7",
  "88,8",
  "88,9",
  "89",
  "89,1",
  "89,2",
  "89,3",
  "89,4",
  "89,5",
  "89,6",
  "89,7",
  "89,8",
  "89,9",
  "90",
  "90,1",
  "90,2",
  "90,3",
  "90,4",
  "90,5",
  "90,6",
  "90,7",
  "90,8",
  "90,9",
  "91",
  "91,1",
  "91,2",
  "91,3",
  "91,4",
  "91,5",
  "91,6",
  "91,7",
  "91,8",
  "91,9",
  "92",
  "92,1",
  "92,2",
  "92,3",
  "92,4",
  "92,5",
  "92,6",
  "92,7",
  "92,8",
  "92,9",
  "93",
  "93,1",
  "93,2",
  "93,3",
  "93,4",
  "93,5",
  "93,6",
  "93,7",
  "93,8",
  "93,9",
  "94",
  "94,1",
  "94,2",
  "94,3",
  "94,4",
  "94,5",
  "94,6",
  "94,7",
  "94,8",
  "94,9",
  "95",
  "95,1",
  "95,2",
  "95,3",
  "95,4",
  "95,5",
  "95,6",
  "95,7",
  "95,8",
  "95,9",
  "96",
  "96,1",
  "96,2",
  "96,3",
  "96,4",
  "96,5",
  "96,6",
  "96,7",
  "96,8",
  "96,9",
  "97",
  "97,1",
  "97,2",
  "97,3",
  "97,4",
  "97,5",
  "97,6",
  "97,7",
  "97,8",
  "97,9",
  "98",
  "98,1",
  "98,2",
  "98,3",
  "98,4",
  "98,5",
  "98,6",
  "98,7",
  "98,8",
  "98,9",
  "99",
  "99,1",
  "99,2",
  "99,3",
  "99,4",
  "99,5",
  "99,6",
  "99,7",
  "99,8",
  "99,9",
  "100",
  "100,1",
  "100,2",
  "100,3",
  "100,4",
  "100,5",
  "100,6",
  "100,7",
  "100,8",
  "100,9",
  "101",
  "101,1",
  "101,2",
  "101,3",
  "101,4",
  "101,5",
  "101,6",
  "101,7",
  "101,8",
  "101,9",
  "102",
  "102,1",
  "102,2",
  "102,3",
  "102,4",
  "102,5",
  "102,6",
  "102,7",
  "102,8",
  "102,9",
  "103",
  "103,1",
  "103,2",
  "103,3",
  "103,4",
  "103,5",
  "103,6",
  "103,7",
  "103,8",
  "103,9",
  "104",
  "104,1",
  "104,2",
  "104,3",
  "104,4",
  "104,5",
  "104,6",
  "104,7",
  "104,8",
  "104,9",
  "105",
  "105,1",
  "105,2",
  "105,3",
  "105,4",
  "105,5",
  "105,6",
  "105,7",
  "105,8",
  "105,9",
  "106",
  "106,1",
  "106,2",
  "106,3",
  "106,4",
  "106,5",
  "106,6",
  "106,7",
  "106,8",
  "106,9",
  "107",
  "107,1",
  "107,2",
  "107,3",
  "107,4",
  "107,5",
  "107,6",
  "107,7",
  "107,8",
  "107,9",
  "108",
  "108,1",
  "108,2",
  "108,3",
  "108,4",
  "108,5",
  "108,6",
  "108,7",
  "108,8",
  "108,9",
  "109",
  "109,1",
  "109,2",
  "109,3",
  "109,4",
  "109,5",
  "109,6",
  "109,7",
  "109,8",
  "109,9",
  "110",
  "110,1",
  "110,2",
  "110,3",
  "110,4",
  "110,5",
  "110,6",
  "110,7",
  "110,8",
  "110,9",
  "111",
  "111,1",
  "111,2",
  "111,3",
  "111,4",
  "111,5",
  "111,6",
  "111,7",
  "111,8",
  "111,9",
  "112",
  "112,1",
  "112,2",
  "112,3",
  "112,4",
  "112,5",
  "112,6",
  "112,7",
  "112,8",
  "112,9",
  "113",
  "113,1",
  "113,2",
  "113,3",
  "113,4",
  "113,5",
  "113,6",
  "113,7",
  "113,8",
  "113,9",
  "114",
  "114,1",
  "114,2",
  "114,3",
  "114,4",
  "114,5",
  "114,6",
  "114,7",
  "114,8",
  "114,9",
  "115",
  "115,1",
  "115,2",
  "115,3",
  "115,4",
  "115,5",
  "115,6",
  "115,7",
  "115,8",
  "115,9",
  "116",
  "116,1",
  "116,2",
  "116,3",
  "116,4",
  "116,5",
  "116,6",
  "116,7",
  "116,8",
  "116,9",
  "117",
  "117,1",
  "117,2",
  "117,3",
  "117,4",
  "117,5",
  "117,6",
  "117,7",
  "117,8",
  "117,9",
  "118",
  "118,1",
  "118,2",
  "118,3",
  "118,4",
  "118,5",
  "118,6",
  "118,7",
  "118,8",
  "118,9",
  "119",
  "119,1",
  "119,2",
  "119,3",
  "119,4",
  "119,5",
  "119,6",
  "119,7",
  "119,8",
  "119,9",
  "120",
  "120,1",
  "120,2",
  "120,3",
  "120,4",
  "120,5",
  "120,6",
  "120,7",
  "120,8",
  "120,9",
  "121",
  "121,1",
  "121,2",
  "121,3",
  "121,4",
  "121,5",
  "121,6",
  "121,7",
  "121,8",
  "121,9",
  "122",
  "122,1",
  "122,2",
  "122,3",
  "122,4",
  "122,5",
  "122,6",
  "122,7",
  "122,8",
  "122,9",
  "123",
  "123,1",
  "123,2",
  "123,3",
  "123,4",
  "123,5",
  "123,6",
  "123,7",
  "123,8",
  "123,9",
  "124",
  "124,1",
  "124,2",
  "124,3",
  "124,4",
  "124,5",
  "124,6",
  "124,7",
  "124,8",
  "124,9",
  "125",
  "125,1",
  "125,2",
  "125,3",
  "125,4",
  "125,5",
  "125,6",
  "125,7",
  "125,8",
  "125,9",
  "126",
  "126,1",
  "126,2",
  "126,3",
  "126,4",
  "126,5",
  "126,6",
  "126,7",
  "126,8",
  "126,9",
  "127",
  "127,1",
  "127,2",
  "127,3",
  "127,4",
  "127,5",
  "127,6",
  "127,7",
  "127,8",
  "127,9",
  "128",
  "128,1",
  "128,2",
  "128,3",
  "128,4",
  "128,5",
  "128,6",
  "128,7",
  "128,8",
  "128,9",
  "129",
  "129,1",
  "129,2",
  "129,3",
  "129,4",
  "129,5",
  "129,6",
  "129,7",
  "129,8",
  "129,9",
  "130",
  "130,1",
  "130,2",
  "130,3",
  "130,4",
  "130,5",
  "130,6",
  "130,7",
  "130,8",
  "130,9",
  "131",
  "131,1",
  "131,2",
  "131,3",
  "131,4",
  "131,5",
  "131,6",
  "131,7",
  "131,8",
  "131,9",
  "132",
  "132,1",
  "132,2",
  "132,3",
  "132,4",
  "132,5",
  "132,6",
  "132,7",
  "132,8",
  "132,9",
  "133",
  "133,1",
  "133,2",
  "133,3",
  "133,4",
  "133,5",
  "133,6",
  "133,7",
  "133,8",
  "133,9",
  "134",
  "134,1",
  "134,2",
  "134,3",
  "134,4",
  "134,5",
  "134,6",
  "134,7",
  "134,8",
  "134,9",
  "135",
  "135,1",
  "135,2",
  "135,3",
  "135,4",
  "135,5",
  "135,6",
  "135,7",
  "135,8",
  "135,9",
  "136",
  "136,1",
  "136,2",
  "136,3",
  "136,4",
  "136,5",
  "136,6",
  "136,7",
  "136,8",
  "136,9",
  "137",
  "137,1",
  "137,2",
  "137,3",
  "137,4",
  "137,5",
  "137,6",
  "137,7",
  "137,8",
  "137,9",
  "138",
  "138,1",
  "138,2",
  "138,3",
  "138,4",
  "138,5",
  "138,6",
  "138,7",
  "138,8",
  "138,9",
  "139",
  "139,1",
  "139,2",
  "139,3",
  "139,4",
  "139,5",
  "139,6",
  "139,7",
  "139,8",
  "139,9",
  "140",
  "140,1",
  "140,2",
  "140,3",
  "140,4",
  "140,5",
  "140,6",
  "140,7",
  "140,8",
  "140,9",
  "141",
  "141,1",
  "141,2",
  "141,3",
  "141,4",
  "141,5",
  "141,6",
  "141,7",
  "141,8",
  "141,9",
  "142",
  "142,1",
  "142,2",
  "142,3",
  "142,4",
  "142,5",
  "142,6",
  "142,7",
  "142,8",
  "142,9",
  "143",
  "143,1",
  "143,2",
  "143,3",
  "143,4",
  "143,5",
  "143,6",
  "143,7",
  "143,8",
  "143,9",
  "144",
  "144,1",
  "144,2",
  "144,3",
  "144,4",
  "144,5",
  "144,6",
  "144,7",
  "144,8",
  "144,9",
  "145",
  "145,1",
  "145,2",
  "145,3",
  "145,4",
  "145,5",
  "145,6",
  "145,7",
  "145,8",
  "145,9",
  "146",
  "146,1",
  "146,2",
  "146,3",
  "146,4",
  "146,5",
  "146,6",
  "146,7",
  "146,8",
  "146,9",
  "147",
  "147,1",
  "147,2",
  "147,3",
  "147,4",
  "147,5",
  "147,6",
  "147,7",
  "147,8",
  "147,9",
  "148",
  "148,1",
  "148,2",
  "148,3",
  "148,4",
  "148,5",
  "148,6",
  "148,7",
  "148,8",
  "148,9",
  "149",
  "149,1",
  "149,2",
  "149,3",
  "149,4",
  "149,5",
  "149,6",
  "149,7",
  "149,8",
  "149,9",
  "150",
  "150,1",
  "150,2",
  "150,3",
  "150,4",
  "150,5",
  "150,6",
  "150,7",
  "150,8",
  "150,9",
  "151",
  "151,1",
  "151,2",
  "151,3",
  "151,4",
  "151,5",
  "151,6",
  "151,7",
  "151,8",
  "151,9",
  "152",
  "152,1",
  "152,2",
  "152,3",
  "152,4",
  "152,5",
  "152,6",
  "152,7",
  "152,8",
  "152,9",
  "153",
  "153,1",
  "153,2",
  "153,3",
  "153,4",
  "153,5",
  "153,6",
  "153,7",
  "153,8",
  "153,9",
  "154",
  "154,1",
  "154,2",
  "154,3",
  "154,4",
  "154,5",
  "154,6",
  "154,7",
  "154,8",
  "154,9",
  "155",
  "155,1",
  "155,2",
  "155,3",
  "155,4",
  "155,5",
  "155,6",
  "155,7",
  "155,8",
  "155,9",
  "156",
  "156,1",
  "156,2",
  "156,3",
  "156,4",
  "156,5",
  "156,6",
  "156,7",
  "156,8",
  "156,9",
  "157",
  "157,1",
  "157,2",
  "157,3",
  "157,4",
  "157,5",
  "157,6",
  "157,7",
  "157,8",
  "157,9",
  "158",
  "158,1",
  "158,2",
  "158,3",
  "158,4",
  "158,5",
  "158,6",
  "158,7",
  "158,8",
  "158,9",
  "159",
  "159,1",
  "159,2",
  "159,3",
  "159,4",
  "159,5",
  "159,6",
  "159,7",
  "159,8",
  "159,9",
  "160",
  "160,1",
  "160,2",
  "160,3",
  "160,4",
  "160,5",
  "160,6",
  "160,7",
  "160,8",
  "160,9",
  "161",
  "161,1",
  "161,2",
  "161,3",
  "161,4",
  "161,5",
  "161,6",
  "161,7",
  "161,8",
  "161,9",
  "162",
  "162,1",
  "162,2",
  "162,3",
  "162,4",
  "162,5",
  "162,6",
  "162,7",
  "162,8",
  "162,9",
  "163",
  "163,1",
  "163,2",
  "163,3",
  "163,4",
  "163,5",
  "163,6",
  "163,7",
  "163,8",
  "163,9",
  "164",
  "164,1",
  "164,2",
  "164,3",
  "164,4",
  "164,5",
  "164,6",
  "164,7",
  "164,8",
  "164,9",
  "165",
  "165,1",
  "165,2",
  "165,3",
  "165,4",
  "165,5",
  "165,6",
  "165,7",
  "165,8",
  "165,9",
  "166",
  "166,1",
  "166,2",
  "166,3",
  "166,4",
  "166,5",
  "166,6",
  "166,7",
  "166,8",
  "166,9",
  "167",
  "167,1",
  "167,2",
  "167,3",
  "167,4",
  "167,5",
  "167,6",
  "167,7",
  "167,8",
  "167,9",
  "168",
  "168,1",
  "168,2",
  "168,3",
  "168,4",
  "168,5",
  "168,6",
  "168,7",
  "168,8",
  "168,9",
  "169",
  "169,1",
  "169,2",
  "169,3",
  "169,4",
  "169,5",
  "169,6",
  "169,7",
  "169,8",
  "169,9",
  "170",
  "170,1",
  "170,2",
  "170,3",
  "170,4",
  "170,5",
  "170,6",
  "170,7",
  "170,8",
  "170,9",
  "171",
  "171,1",
  "171,2",
  "171,3",
  "171,4",
  "171,5",
  "171,6",
  "171,7",
  "171,8",
  "171,9",
  "172",
  "172,1",
  "172,2",
  "172,3",
  "172,4",
  "172,5",
  "172,6",
  "172,7",
  "172,8",
  "172,9",
  "173",
  "173,1",
  "173,2",
  "173,3",
  "173,4",
  "173,5",
  "173,6",
  "173,7",
  "173,8",
  "173,9",
  "174",
  "174,1",
  "174,2",
  "174,3",
  "174,4",
  "174,5",
  "174,6",
  "174,7",
  "174,8",
  "174,9",
  "175",
  "175,1",
  "175,2",
  "175,3",
  "175,4",
  "175,5",
  "175,6",
  "175,7",
  "175,8",
  "175,9",
  "176",
  "176,1",
  "176,2",
  "176,3",
  "176,4",
  "176,5",
  "176,6",
  "176,7",
  "176,8",
  "176,9",
  "177",
  "177,1",
  "177,2",
  "177,3",
  "177,4",
  "177,5",
  "177,6",
  "177,7",
  "177,8",
  "177,9",
  "178",
  "178,1",
  "178,2",
  "178,3",
  "178,4",
  "178,5",
  "178,6",
  "178,7",
  "178,8",
  "178,9",
  "179",
  "179,1",
  "179,2",
  "179,3",
  "179,4",
  "179,5",
  "179,6",
  "179,7",
  "179,8",
  "179,9",
  "180",
];
export const defaultWeight = "70";
