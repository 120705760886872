import React, { useState, useEffect, useCallback } from "react";
import { produce } from "immer";
import { useRecoilState, useRecoilValue } from "recoil";
import { padStart, set } from "lodash";
import { Form } from "semantic-ui-react";

import { TitleBar, CompS } from "../common";

import { Section, TimePicker } from "../../common";
import { COLORS } from "../../../constants";
import { AppUtils } from "../../../utils";
import { KKButton } from "../../Buttons";
import {
  cognitoUserState,
  newSymptomDataState,
  mesMinuteState,
  mesHourState,
  currentSymptomDataState,
  currentChecklistState,
} from "../../../atoms";
import { getHourMinutesFromTxt } from "../../../utils";

const Comp = (props) => {
  const { onClose } = props;
  const [currentChecklist, setCurrentchecklist] = useRecoilState(
    currentChecklistState
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [stepsHistory, setStepsHistory] = useState([]);
  const [mesHour, setMesHour] = useRecoilState(mesHourState);
  const [mesMinute, setMesMinute] = useRecoilState(mesMinuteState);
  const [newData, setNewData] = useRecoilState(newSymptomDataState);
  const cognitoUser = useRecoilValue(cognitoUserState);
  const [currentData, setCurrentData] = useRecoilState(currentSymptomDataState);

  const [mesSymptom, setMesSymptom] = useState("");
  const [mesFood, setMesFood] = useState("");

  const gotoStep = useCallback(
    (step) => {
      setCurrentStep(step);
      setStepsHistory(
        produce(stepsHistory, (draft) => {
          draft.push(step);
        })
      );
    },
    [stepsHistory, setCurrentStep, setStepsHistory]
  );

  useEffect(() => {
    if (currentStep === 0 && newData.isedit) {
      if (newData && newData.isedit && newData?.etime && !mesHour) {
        const [h, m] = getHourMinutesFromTxt(newData.etime);
        setMesHour(padStart(h, 2, "0"));
        setMesMinute(padStart(m, 2, "0"));
      }
      if (newData && newData.symptom) {
        setMesSymptom(newData.symptom);
      }
      if (newData && newData.food) {
        setMesFood(newData.food);
      }
    }
  }, [newData, currentStep, setMesHour, setMesMinute, mesHour]);

  const handleChange = () => {
    const newEdit = newData.isedit ? "edit" : "new";
    const oldItems = currentChecklist[`measurements`] || [];
    const item = produce(newData, (draft) => {
      set(draft, "etime", `${mesHour || "00"}${mesMinute || "00"}`);
      set(draft, "symptom", mesSymptom);
      set(draft, "food", mesFood);
      if (newData.isnew) {
        set(draft, "id", `${currentChecklist.id}--${Date.now()}`);
      }
    });
    const cl = produce(currentChecklist, (draft) => {
      switch (newEdit) {
        case "new":
          draft[`measurements`] = produce(oldItems, (draft) => {
            draft.push(item);
          });
          break;
        case "edit":
          draft[`measurements`] = oldItems.map((old) => {
            if (old.id === item.id) {
              return item;
            }
            return old;
          });
          break;
        default:
      }
    });
    setCurrentchecklist(cl);
    const pc = produce(currentData || {}, (draft) => {
      set(draft, `checklists.${cl.id}`, cl);
    });
    setNewData({});
    setCurrentData(pc);
    AppUtils.putS3Data(`user/${cognitoUser.sub}/symptoms.json`, pc);
  };

  return (
    <CompS>
      {currentStep === 0 && (
        <>
          <TitleBar
            label="Uhrzeit"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepsHistory={stepsHistory}
            setStepsHistory={setStepsHistory}
            onClose={onClose}
          />
          <Section>
            <TimePicker
              nolabel
              hour={mesHour}
              minute={mesMinute}
              callback={(h, m) => {
                setMesHour(padStart(h, 2, "0"));
                setMesMinute(padStart(m, 2, "0"));
              }}
            />
          </Section>

          {mesHour && mesMinute && (
            <Section>
              <KKButton
                color={COLORS.ORANGE}
                onClick={() => {
                  gotoStep(1);
                }}
              >
                Weiter
              </KKButton>
            </Section>
          )}
        </>
      )}
      {currentStep === 1 && (
        <>
          <TitleBar
            label="Symptome"
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            stepsHistory={stepsHistory}
            setStepsHistory={setStepsHistory}
            onClose={onClose}
          />
          <Section>
            <h4>Symptom</h4>
            <Form>
              <Form.Input
                name="symptom"
                type="text"
                onChange={(e, d) => {
                  setMesSymptom(d.value);
                }}
                value={mesSymptom}
              />
              <h4>Lebensmittel</h4>
              <Form.Input
                name="symptom"
                type="text"
                onChange={(e, d) => {
                  setMesFood(d.value);
                }}
                value={mesFood}
              />
            </Form>
          </Section>
          <Section>
            <KKButton
              color={COLORS.ORANGE}
              onClick={() => {
                handleChange();
                onClose && onClose();
              }}
            >
              Speichern
            </KKButton>
          </Section>
        </>
      )}
    </CompS>
  );
};

export default Comp;
