/* eslint-disable no-useless-escape */
import React from 'react';
import styled from 'styled-components';
import { COLORS } from '../../constants';
import { Icon } from 'semantic-ui-react';

export { default as Login } from './Login';
export { default as SignUp } from './SignUp';
export { default as LostPassword } from './LostPassword';
export { default as LostPasswordChange } from './LostPasswordChange';



// COMMON

export const checks = [
  'SignUp_Check_Email',
  'SignUp_Check_PW_LEN',
  'SignUp_Check_PW_NUM',
  'SignUp_Check_PW_KB',
  'SignUp_Check_PW_GB',
  'SignUp_Check_PW_ident',
  'SignUp_Check_Name',
];

export const reLower = /(?=.*[a-z])/;
export const reNum = /(?=.*[0-9])/;
export const reUpper = /(?=.*[A-Z])/;

export const validateEmail = email => {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const SignUpChecklistItem = props => (
  <li className={props.ok ? 'ok' : 'nok'}>
    <Icon name={props.ok ? 'check circle outline' : 'circle outline'} />
    {props.label}
  </li>
);
export const CheckList = styled.ul`
  padding: 0;
  margin: 0;
  list-style-type: none;
  i {
    position: absolute;
    left: 0;
    top: 0;
  }
  li {
    padding-left: 1.8rem;
    position: relative;
    &.ok {
      opacity: 0;
      max-height: 0;
      transition: max-height 800ms linear 600ms, opacity 1000ms ease-in-out,
        color 300ms;
      color: ${COLORS.GREEN};
    }
    &.nok {
      opacity: 1;
      max-height: 100px;
      transition: max-height 1s linear, opacity 600ms ease-in-out;
    }
  }
  .circle.outline {
    color: ${COLORS.ORANGE};
  }
  .check.circle.outline {
    color: ${COLORS.GREEN}!important;
  }
`;