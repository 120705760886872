import React, { useState, useEffect, useMemo, useCallback } from "react";
import styled from "styled-components";
import { Icon, Form } from "semantic-ui-react";
import { get, set } from "lodash";
import { produce } from "immer";
import { useParams } from "react-router-dom";
import useNavigate from "../../useNavigate";
import { cognitoUserState, caseState } from "../../atoms";
import { useRecoilState, useRecoilValue } from "recoil";

import { checkCondition } from "./utils";
import { AppUtils, htmlDecode, buttonLabel } from "../../utils";
import { COLORS, CONTENTS_BASE_URL } from "../../constants";
import { TitleBar } from "../common";
import { KKButton } from "../Buttons";

const anamneseVariants = {
  initcons: "anamnese22",
  app22: "anamnese22",
  "praevention_2023-001": "anamnese_praevention_2023-001",
  "praevention_2024-001": "anamnese_praevention_2024-001",
  "reduktion_2023-001": "anamnese22",
};

const anamneseSaveName = (id) => {
  let anamneseid = get(anamneseVariants, id, "anamnese22");
  if (anamneseid === "anamnese22") {
    return "anamnese";
  }
  return anamneseid;
};

const Anamnese = (props) => {
  const { id, section } = useParams();
  const [anamnese, setAnamnese] = useState({ version: "22.0", for: id });
  const [anamneseTemplate, setAnamneseTemplate] = useState({});
  const cognitoUser = useRecoilValue(cognitoUserState);
  const currentSectionID = section || "A";
  const [pcase, setPcase] = useRecoilState(caseState);
  const goto = useNavigate();

  // fetch anamnese template
  useEffect(() => {
    let anamneseid = get(anamneseVariants, id, "anamnese22");
    AppUtils.getS3Data(`data/${anamneseid}.json`)
      .then((response) => {
        console.log("resp", response);
        setAnamneseTemplate(get(response, "data", {}) || {});
      })
      .catch((e) => {
        console.log(e);
      });
  }, [id]);

  // set current section
  const currentSection = useMemo(() => {
    return get(anamneseTemplate, currentSectionID, {});
  }, [currentSectionID, anamneseTemplate]);

  const questionKeys = useMemo(
    () => Object.keys(get(currentSection, "questions", {}) || {}) || [],
    [currentSection]
  );
  const sectionKeys = useMemo(
    () => Object.keys(anamneseTemplate || {}) || [],
    [anamneseTemplate]
  );

  // handle single value change
  const handleChange = useCallback(
    (k, v) => {
      setAnamnese(
        produce(anamnese, (draftState) => {
          draftState[k] = v;
        })
      );
    },
    [anamnese]
  );
  const handleChanges = useCallback(
    (changes) => {
      setAnamnese(
        produce(anamnese, (draftState) => {
          for (let idx in changes) {
            const { k, v } = changes[idx];
            draftState[k] = v;
          }
        })
      );
    },
    [anamnese]
  );
  // handle multivalue (checkbox)
  const handleChangeCheckbox = useCallback(
    (k, v) => {
      setAnamnese(
        produce(anamnese, (draftState) => {
          if (!draftState[k]) {
            draftState[k] = [];
          }
          if (draftState[k].includes(v)) {
            draftState[k] = draftState[k].filter((dv) => dv !== v);
          } else {
            draftState[k].push(v);
          }
        })
      );
    },
    [anamnese]
  );

  // calc bmi gu
  const size = useMemo(() => get(anamnese, "4A", false), [anamnese]);
  const weight = useMemo(() => get(anamnese, "5A", false), [anamnese]);
  const age = useMemo(() => get(anamnese, "2A", false), [anamnese]);
  useEffect(() => {
    const changes = [];
    if (size && weight && age) {
      changes.push({
        k: "gu",
        v: Math.round(
          655.1 +
            9.6 * parseInt(weight) +
            1.8 * parseInt(size) -
            4.7 * parseInt(age)
        ),
      });
    }
    if (size && weight) {
      changes.push({
        k: "bmi",
        v: Math.round(
          parseInt(weight) / ((parseInt(size) / 100) * (parseInt(size) / 100))
        ),
      });
    }
    handleChanges(changes);
  }, [size, weight, age, handleChanges]);

  // check mok
  let mok = Object.keys(anamnese).length > 0;
  questionKeys.forEach((k) => {
    const question = get(currentSection, `questions.${k}`, {});
    const qMust = get(question, "m", false);
    const qConditions = get(question, "condition", []);
    const show = checkCondition(anamnese, qConditions);
    if (qMust && get(anamnese, k, "").length === 0 && show) {
      mok = false;
    }
  });

  const next = (data) => {
    window.scrollTo(0, 0);
    AppUtils.putS3Data(
      `user/${cognitoUser.sub}/${anamneseSaveName(id)}.json`,
      data
    )
      .then(() => {
        goto(
          `/anamnese/${id}/${
            sectionKeys[sectionKeys.indexOf(currentSectionID) + 1]
          }`
        );
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  };

  const save = useCallback(() => {
    AppUtils.putS3Data(
      `user/${cognitoUser.sub}/${anamneseSaveName(id)}.json`,
      anamnese
    )
      .then(() => {
        if (id !== "app22") {
          const pc = produce(pcase || {}, (draft) => {
            set(draft, `course.${id}.anamnese.complete`, true);
          });
          setPcase(pc);
          AppUtils.putS3Data(`user/${cognitoUser.sub}/case.json`, pc).then(
            () => {
              window.scrollTo(0, 0);
              goto(`/?course=${id}`);
            }
          );
        } else {
          const pc = produce(pcase || {}, (draft) => {
            set(draft, `anamnese.complete`, true);
          });
          setPcase(pc);
          AppUtils.putS3Data(`user/${cognitoUser.sub}/case.json`, pc).then(
            () => {
              window.scrollTo(0, 0);
              goto("/");
            }
          );
        }
      })
      .catch((e) => {
        // eslint-disable-next-line no-console
        console.log(e);
      });
  }, [anamnese, cognitoUser, goto, id, pcase, setPcase]);

  return (
    <div>
      <TitleBar label="Anamnese" />
      <Comp>
        <Section>
          Sie können hier grundlegende Informationen zu Ihrer Person und Ihrem
          Gesundheitszustand angeben. Es handelt sich um eine freiwillige
          Angabe, die uns hilft, Sie besser zu betreuen. Ihre Daten werden
          vertraulich behandelt und nicht an Dritte weitergegeben.
        </Section>
        <Form>
          <Section>
            {get(currentSection, "img", "") && (
              <img
                alt=""
                style={{ marginBottom: "25px" }}
                src={`${CONTENTS_BASE_URL}${get(currentSection, "img", "")}`}
              />
            )}

            {Object.keys(get(currentSection, "questions", {}) || {}).map(
              (k) => {
                const question = get(currentSection, `questions.${k}`, {});
                const qType = get(question, "t", "");
                const qImage = get(question, "i", "");
                const func = get(question, "f", "");
                const qMust = get(question, "m", false);
                const qOptions = get(question, "o", []);
                const qConditions = get(question, "condition", []);
                const show = checkCondition(anamnese, qConditions);
                const valExists = get(anamnese, k, "").length > 0;
                return (
                  <Question key={k} className={show ? "visible" : "hidden"}>
                    <h2>
                      {htmlDecode(get(question, "q", ""))}{" "}
                      {qMust ? <span>&nbsp;*</span> : null}
                    </h2>
                    {qMust && !valExists && (
                      <div className="missing">
                        Pflichtfeld, Bitte ausfüllen.
                      </div>
                    )}
                    {qImage && (
                      <img
                        alt=""
                        style={{ maxWidth: "100%", maxHeight: "100vh" }}
                        src={`${CONTENTS_BASE_URL}${qImage}`}
                      />
                    )}
                    {qType === "radio" && (
                      <RadioGroup>
                        {qOptions
                          .filter((o) =>
                            checkCondition(anamnese, get(o, "condition", []))
                          )
                          .map((o) => {
                            const optionId = get(o, "id");
                            return (
                              <KKRadio
                                key={optionId}
                                onClick={() => handleChange(k, optionId)}
                              >
                                <Icon
                                  name={
                                    get(anamnese, k) === optionId
                                      ? "check circle"
                                      : "circle outline"
                                  }
                                />
                                <label>{get(o, "t")}</label>
                              </KKRadio>
                            );
                          })}
                      </RadioGroup>
                    )}
                    {qType === "checkbox" && (
                      <RadioGroup>
                        {qOptions
                          .filter((o) =>
                            checkCondition(anamnese, get(o, "condition", []))
                          )
                          .map((o) => {
                            const optionId = get(o, "id");
                            return (
                              <KKRadio
                                key={optionId}
                                onClick={() =>
                                  handleChangeCheckbox(k, optionId)
                                }
                              >
                                <Icon
                                  name={
                                    (get(anamnese, k, []) || []).includes(
                                      optionId
                                    )
                                      ? "check square"
                                      : "square outline"
                                  }
                                />
                                <label>{get(o, "t")}</label>
                              </KKRadio>
                            );
                          })}
                      </RadioGroup>
                    )}
                    {qType === "number" && (
                      <NumberInput
                        type="number"
                        onChange={(e) => {
                          handleChange(k, e.currentTarget.value);
                        }}
                        value={get(anamnese, k, "")}
                      />
                    )}
                    {qType === "keyfigure" && (
                      <NumberInput
                        type="number"
                        disabled
                        value={get(anamnese, func, "")}
                      />
                    )}
                    {qType === "text" && (
                      <TextInput
                        onChange={(e) => handleChange(k, e.currentTarget.value)}
                        defaultValue=""
                        value={get(anamnese, k, "")}
                      />
                    )}
                    {qType === "textarea" && (
                      <textarea
                        rows="5"
                        onChange={(e) => handleChange(k, e.currentTarget.value)}
                        defaultValue=""
                        value={get(anamnese, k, "")}
                      />
                    )}
                  </Question>
                );
              }
            )}
          </Section>
        </Form>
        {mok ? (
          <div className="buttons">
            {currentSectionID !== sectionKeys[sectionKeys.length - 1] ? (
              <KKButton
                color={COLORS.BLUE}
                onClick={() => {
                  next(anamnese);
                }}
              >
                {buttonLabel("Button_Anamnese_Weiter", "de")}
              </KKButton>
            ) : (
              <KKButton color={COLORS.BLUE} onClick={save}>
                {buttonLabel("Button_Anamnese_Fertig", "de")}
              </KKButton>
            )}
          </div>
        ) : (
          <Error>
            <span>* &nbsp;</span>
            {buttonLabel("Hinweis_Pflichtfelder", "de")}
          </Error>
        )}
      </Comp>
    </div>
  );
};
export default Anamnese;

const Comp = styled.div`
  img {
    max-width: 100%;
  }
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding-bottom: 100px;
  .buttons {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
  }
`;
const Error = styled.div`
  color: ${COLORS.ORANGE};
  font-weight: bold;
`;
const NumberInputStyled = styled.input`
  &:disabled {
    background: rgba(90, 90, 90, 0.1) !important;
    font-weight: bold;
  }
`;
const NumberInput = (props) => <NumberInputStyled {...props} type="tel" />;

const TextInput = (props) => <NumberInputStyled {...props} type="text" />;
const RadioGroup = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  > * {
    margin: 0.25rem 1rem 0.25rem 0;
  }
  width: 100%;
  margin-top: 0.5rem;
`;
const KKRadio = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: none;
  > i {
    flex: 0 0 auto;
  }
`;
const Question = styled.div`
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 1rem;
  border-bottom: 1px solid ${COLORS.BLUE_LIGHT};
  h2 {
    span {
      color: ${COLORS.ORANGE};
    }
    margin-bottom: 0;
  }
  &.hidden {
    display: none;
    margin: 0;
    padding: 0;
  }
  .missing {
    color: ${COLORS.ORANGE};
    text-align: left;
    font-size: 0.9em;
    width: 100%;
  }
`;
const Section = styled.div`
  width: 100%;
  padding: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  img {
    z-index: 0;
    max-height: 30vh;
  }
  h1 {
    color: ${COLORS.GREY_DARK}!important;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.5rem;
    text-align: left !important;
    width: 100%;
  }
  h2 {
    color: ${COLORS.BLUE}!important;
    font-weight: bold;
    text-transform: none;
    font-size: 18px;
    line-height: 1.5rem;
    text-align: left !important;
    width: 100%;
  }
`;
